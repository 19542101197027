import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    fetchServiceProviders,
    updateServiceProviderFilters,
    clearServiceProviderFilters,
} from 'actions/serviceprovider';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Table from 'components/elements/Table';
import Loading from 'components/higher-order-components/Loading';
import NoResultsFound from 'components/higher-order-components/NoResultsFound';
import Reload from 'components/elements/Reload';
import Paginator from 'components/elements/Paginator';
import ItemCount from 'components/elements/ItemCount';
import JWT from 'services/JWT';
import FiltersService from 'services/Filters';
import Filters from 'components/elements/Filters';
import { activeOptions } from 'constants/active';
import { holdOptions } from 'constants/hold';
import lodash from 'lodash';

const mapStateToProps = (state) => {
    return {
        filters: state.serviceProvider.filters,
        filterData: state.serviceProvider.filterData,
        isLoading: state.serviceProvider.isRequesting,
        serviceProviderCollectionResponse: state.serviceProvider.collection,
    };
};

const mapDispatchToProps = {
    fetchServiceProviders: fetchServiceProviders,
    updateServiceProviderFilters: updateServiceProviderFilters,
    clearServiceProviderFilters: clearServiceProviderFilters,
};

class ServiceProvidersList extends Component
{
    static propTypes = {
        serviceProviderCollectionResponse: PropTypes.shape({
            count: PropTypes.number.isRequired,
            serviceproviders: PropTypes.array,
            limit: PropTypes.number.isRequired,
            page: PropTypes.number.isRequired,
            pages: PropTypes.number.isRequired,
        }).isRequired,
        filters: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        serviceProviderCollectionResponse: {
            count: 0,
            serviceproviders: undefined,
            limit: 10,
            page: 1,
            pages: 0,
        },
        filters: {},
        isLoading: false,
    };

    constructor(props) {
        super(props);

        this.filterData = FiltersService.updateFilterStateFromUrl(
            this.props.location.search.slice(1),
            this.props.filterData,
            this.props.updateServiceProviderFilters,
        );

        this.changePage = this.changePage.bind(this);
        this.load = this.load.bind(this);
    }

    render() {
        const { match, history } = this.props;
        const columns = [
            {header: 'Name', width: 10, visible: true, key: 'name', },
            {header: '', width: 2, visible: true, type: 'button', link: 'self', text: 'View', toState: this.generateToState, fixedRight: true, },
        ];
        const collection = this.props.serviceProviderCollectionResponse || {};

        return (
            <div className="ServiceProvidersList">
                <h1 className="clearfix">
                    Service Providers
                    <ItemCount count={collection.count} />

                    <Reload load={this.load} />

                    { JWT.hasRole('ROLE_ADMINISTRATOR') === false ? null :
                        <Button className="float-right"
                            size="sm"
                            onClick={() => history.push(`${match.path}/add`)}>
                            Add a New Service Provider
                        </Button>
                    }
                </h1>

                <Filters onFilter={this.props.updateServiceProviderFilters}
                    onClear={this.props.clearServiceProviderFilters}
                    filterData={this.filterData}>
                    <Filters.Text label="Name"
                        filterKey="name" />
                    <Filters.SimpleSelect label="Is Active"
                        filterKey="isActive"
                        options={activeOptions}
                        multiple={true} />
                    <Filters.SimpleSelect label="On Hold"
                        filterKey="isOnHold"
                        options={holdOptions}
                        multiple={true} />
                </Filters>

                <Loading isLoading={this.props.isLoading} size="md">
                    <NoResultsFound count={collection.count}>
                        <Table
                            data={collection.serviceProviders || []}
                            columns={columns} />

                        <Paginator page={collection.page}
                            count={collection.count}
                            limit={collection.limit}
                            onPage={this.changePage}/>
                    </NoResultsFound>
                </Loading>
            </div>
        );
    }

    componentDidMount() {
        if (typeof this.props.serviceProviderCollectionResponse !== 'object' ||
            typeof this.props.serviceProviderCollectionResponse.serviceProviders === 'undefined') {
            this.load();
        }
    }

    componentDidUpdate(prevProps) {
        this.filterData = this.props.filterData;

        if (!lodash.isEqual(prevProps.filters, this.props.filters)) {
            this.load();
        }
    }

    generateToState(serviceProvider) {
        return '/serviceproviders/' + serviceProvider.id;
    }

    changePage(page) {
        Object.assign(this.props.filters, {page: page});

        this.load();
    }

    load() {
        this.props.fetchServiceProviders(this.props.filters);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProvidersList);
