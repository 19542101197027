import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchNotes, create } from 'services/data/Note';
import Data from 'services/Data';
import Response from 'services/Response';
import NoResultsFound from 'components/higher-order-components/NoResultsFound';
import Paginator from 'components/elements/Paginator';
import ItemCount from 'components/elements/ItemCount';
import Loading from 'components/higher-order-components/Loading';
import Reload from 'components/elements/Reload';
import { Button, FormGroup, FormControl } from 'react-bootstrap';
import { DateTime } from 'luxon';

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    };
};

class NoteFeed extends Component
{
    static propTypes = {
        resource: PropTypes.string.isRequired,
        createResource: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            newNote: '',
            notesCollection: Data.generateCollectionResponse('notes'),
            page: 1,
        };
    }

    render() {
        const notes = this.state.notesCollection;

        const userBadge = this.renderBadge(
            this.props.user.forename,
            this.props.user.surname,
            this.props.user.highlightColour
        );

        return (
            <div className="NoteFeed">
                <h1>
                    Notes
                    <ItemCount count={notes.count} />
                    <Reload load={this.load} />
                </h1>

                <Loading isLoading={this.state.loading}>
                    <div className="Note" key="CreateNote">
                        {userBadge}
                        <div className="NoteDetail">
                            <div className="header">
                                <div className="name">Write a note...</div>
                            </div>
                            <div className="body">
                                <form ref="form"
                                    onSubmit={this.handleCreate}>
                                    <FormGroup>
                                        <FormControl as="textarea"
                                            rows="4"
                                            maxLength="3000"
                                            value={this.state.newNote}
                                            onChange={e => {this.setState({'newNote': e.target.value})}} />
                                    </FormGroup>

                                    <Button size="sm"
                                        type="submit"
                                        variant="primary"
                                        disabled={!this.state.newNote}>
                                        Submit
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>

                    <NoResultsFound count={notes.count} label="No notes found">
                        {notes.notes.map(this.renderNote)}

                        {notes.pages === 1 ? <div></div> : (
                            <Paginator page={notes.page}
                                count={notes.count}
                                limit={notes.limit}
                                onPage={this.changePage} />
                        )}
                    </NoResultsFound>
                </Loading>
            </div>
        );
    }

    renderBadge(forename, surname, highlightColour) {
        const badge = `${forename.charAt(0)}${surname.charAt(0)}`;
        const style = {
            backgroundColor: highlightColour,
        };

        return <div className="NoteBadge" style={style}>{badge}</div>;
    }

    renderNote = (note) => {
        const name = `${Response.getLinkAttribute(note, 'user', 'forename')} ${Response.getLinkAttribute(note, 'user', 'surname')}`;

        const badge = this.renderBadge(
            Response.getLinkAttribute(note, 'user', 'forename'),
            Response.getLinkAttribute(note, 'user', 'surname'),
            Response.getLinkAttribute(note, 'user', 'highlightColour')
        );

        return (
            <div className="Note" key={note.id}>
                {badge}
                <div className="NoteDetail">
                    <div className="header">
                        <div className="name"><strong>{name}</strong> wrote a note</div>
                        <div className="datetime">
                            {DateTime.fromISO(
                                note.createdAt
                            ).toUTC().toLocaleString(
                                DateTime.DATETIME_SHORT_WITH_SECONDS
                            )}
                        </div>
                    </div>
                    <div className="body">
                        {note.note}
                    </div>
                </div>
            </div>
        );
    };

    handleCreate = (event) => {
        event.preventDefault();

        const data = {
            note: this.state.newNote,
        };

        create(this.props.createResource, data).then((response) => {
            if (response) {
                this.setState({newNote: '', page: 1}, () => {
                    this.load();
                });
            }
        });
    };

    changePage = (page) => {
        this.setState({page: page}, () => {
            this.load();
        });
    };

    componentDidMount() {
        this.load();
    }

    load = () => {
        if (this.props.resource) {
            this.setState({loading: true});

            fetchNotes(this.props.resource, {page: this.state.page}).then((response) => {
                this.setState({
                    notesCollection: response,
                    loading: false,
                });
            });
        }
    };

}

export default connect(mapStateToProps, null)(NoteFeed);
