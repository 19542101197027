import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Users from 'components/users/Users';
import ServiceProviders from 'components/serviceproviders/ServiceProviders';
import Clients from 'components/clients/Clients';
import Properties from 'components/properties/Properties';
import Jobs from 'components/jobs/Jobs';
import Errors from 'components/jobs/Errors';
import Dashboard from 'components/dashboard/Dashboard';
import ElogbooksProperties from 'components/elogbooks-properties/ElogbooksProperties';
import PurchaseInvoices from 'components/purchase-invoices/PurchaseInvoices';
import SalesInvoices from 'components/sales-invoices/SalesInvoices';
import ProtectedRoute from 'components/higher-order-components/ProtectedRoute';
import PendingJobs from 'components/pending-jobs/PendingJobs';

export default class Main extends Component
{
    render() {
        return (
            <div className="Main">
                <Switch>

                    <Route path="/dashboard" component={Dashboard} exact />
                    <Route path="/users" component={Users} />
                    <Route path="/serviceproviders" component={ServiceProviders} />
                    <Route path="/clients" component={Clients} />
                    <Route path="/properties" component={Properties} />
                    <ProtectedRoute requireRole="ROLE_ADMINISTRATOR"
                        path="/elogbooks-properties"
                        component={ElogbooksProperties} />
                    <Route path="/jobs" component={Jobs} />
                    <Route path="/job-errors" component={Errors} />
                    <Route path="/pending-jobs" component={PendingJobs} />
                    <ProtectedRoute requireRole="ROLE_ADMINISTRATOR"
                        path="/purchase-invoices"
                        component={PurchaseInvoices} />
                    <ProtectedRoute requireRole="ROLE_ADMINISTRATOR"
                        path="/sales-invoices"
                        component={SalesInvoices} />

                    <Redirect from="/" to="/dashboard" />
                    <Redirect from="/login" to="/dashboard" />
                    <Redirect from="/forgotten-password" to="/dashboard" />

                    <Route component={() => <div>Error</div> } />
                </Switch>
            </div>
        );
    }
}
