import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import App from 'App';
import PreAuthContainer from 'PreAuthContainer';

import JWT from 'services/JWT';
import 'FontAwesomeIcons';

import { connect } from 'react-redux';
import { checkToken } from 'actions/auth';

import './AppContainer.css';

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
    };
};
const mapDispatchToProps = {
    checkToken: checkToken,
};

class AppContainer extends Component
{
    componentWillMount() {
        this.props.checkToken(this.props.token);
    }

    render() {
        let component = <PreAuthContainer />;
        if (JWT.isValid(this.props.token)) {
            component = <App />;
            JWT.setToken(this.props.token);
        }

        return (
            <div className="AppContainer">
                {component}
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
