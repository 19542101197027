import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from 'components/elements/Table';
import Loading from 'components/higher-order-components/Loading';
import NoResultsFound from 'components/higher-order-components/NoResultsFound';
import Reload from 'components/elements/Reload';
import Paginator from 'components/elements/Paginator';
import ItemCount from 'components/elements/ItemCount';
import Filters from 'components/elements/Filters';
import lodash from 'lodash';
import FiltersService from 'services/Filters';
import { fetchSalesInvoices, updateSalesInvoiceFilters, clearSalesInvoiceFilters } from 'actions/salesinvoice';
import { fetchClients } from 'actions/client';
import { fetchProperties } from 'actions/property';
import { stateOptions, states } from 'constants/salesinvoice';
import { ButtonToolbar } from 'react-bootstrap';
import ButtonLink from 'components/elements/ButtonLink';

const mapStateToProps = (state) => {
    return {
        salesInvoiceCollectionResponse: state.salesInvoice.collection,
        filters: state.salesInvoice.filters,
        filterData: state.salesInvoice.filterData,
        isRequesting: state.salesInvoice.isRequesting,
    };
};

const mapDispatchToProps = {
    fetchSalesInvoices: fetchSalesInvoices,
    updateSalesInvoiceFilters: updateSalesInvoiceFilters,
    clearSalesInvoiceFilters: clearSalesInvoiceFilters,
    fetchClients: fetchClients,
    fetchProperties: fetchProperties,
};

class SalesInvoicesList extends Component
{
    constructor(props) {
        super(props);

        this.load = this.load.bind(this);

        this.filterData = FiltersService.updateFilterStateFromUrl(
            this.props.location.search.slice(1),
            this.props.filterData,
            this.props.updateSalesInvoiceFilters,
        );
    }

    render() {
        const columns = [
            {header: 'Client', width: 2, visible: true, key: '_links.client.title', },
            {header: 'Property', width: 2, visible: true, key: '_links.property.title', },
            {header: 'Exchequer Sales Invoice Number', width: 2, visible: true, key: 'exchequerSalesInvoiceNumber', },
            {header: 'State', width: 1, visible: true, key: 'state', modifier: (item) => { return states[item]; }, },
            {header: '', width: 1, visible: true, type: 'button', link: 'self', text: 'View', toState: this.generateToState, fixedRight: true, },
        ];
        const collection = this.props.salesInvoiceCollectionResponse || {};

        return (
            <div className="SalesInvoicesList">
                <h1 className="clearfix">
                    Sales Invoices
                    <ItemCount count={collection.count} />

                    <Reload load={() => {this.load()}} />

                    <ButtonToolbar className="float-right">
                        <ButtonLink size="sm" to={`${this.props.match.path}/add`}>
                            Create Sales Invoice
                        </ButtonLink>
                    </ButtonToolbar>
                </h1>

                <Filters onFilter={this.props.updateSalesInvoiceFilters}
                    onClear={this.props.clearSalesInvoiceFilters}
                    filterData={this.filterData}>
                    <Filters.SingleSelect action={this.props.fetchClients}
                        label="Client"
                        filterKey="client"
                        responseKey="clients" />
                    <Filters.SingleSelect action={this.props.fetchProperties}
                        label="Property"
                        filterKey="property"
                        responseKey="properties" />
                    <Filters.SimpleSelect label="State"
                        filterKey="state"
                        options={stateOptions}
                        multiple={true} />
                    <Filters.Text label="Exchequer PIN"
                        filterKey="exchequerPin" />
                    <Filters.Text label="Exchequer SIN"
                        filterKey="exchequerSin" />
                    <Filters.Text label="Elogbooks Job ID"
                        filterKey="elogbooksJobId" />
                </Filters>

                <Loading isLoading={this.props.isRequesting} size="md">
                    <NoResultsFound count={collection.count}>
                        <Table data={collection.salesInvoices || []}
                            columns={columns}
                            collection={collection} />

                        <Paginator page={collection.page}
                            count={collection.count}
                            limit={collection.limit}
                            onPage={(page) => this.changePage(page)} />
                    </NoResultsFound>
                </Loading>
            </div>
        );
    }

    componentDidUpdate(prevProps) {
        if (!lodash.isEqual(prevProps.filters, this.props.filters)) {
            this.filterData = this.props.filterData;

            this.load();
        }
    }

    changePage(page) {
        Object.assign(this.props.filters, {page: page});
        this.load();
    }

    load() {
        this.props.fetchSalesInvoices(this.props.filters);
    }

    generateToState(salesInvoice) {
        return `/sales-invoices/${salesInvoice.id}`;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesInvoicesList);
