import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from 'Login';
import ForgottenPassword from 'ForgottenPassword';
import PasswordReset from 'PasswordReset';

class PreAuthContainer extends Component
{
    render() {
        return (
            <div className="PreAuthContainer">
                <Switch>
                    <Route path="/login" component={Login} exact />
                    <Route path="/forgotten-password" component={ForgottenPassword} />
                    <Route path="/password-reset" component={ PasswordReset } />

                    <Redirect from="/" to="/login" />
                 </Switch>
            </div>
        );
    }
}

export default PreAuthContainer;
