import React, { Component } from 'react';
import Loading from 'components/higher-order-components/Loading';
import Reload from 'components/elements/Reload';
import Response from 'services/Response';
import Elogbooks from 'services/Elogbooks';
import { fetchJobByClient } from 'services/data/elogbooks/job';
import { fetchClient } from 'services/data/Client';
import { DateTime } from 'luxon';
import NumberFormatting from 'services/NumberFormatting';
import NoteFeed from 'components/notes/NoteFeed';

class PendingJobDetails extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            client: {},
            jobResponse: {},
        };
    }

    render() {
        const job = this.state.jobResponse,
            client = this.state.client;

        const invoiceDate = Elogbooks.getJobAttributeValue(job.attributes, 'value_invoice_date'),
            labourValue = Elogbooks.getJobAttributeValue(job.attributes, 'value_labour'),
            materialValue = Elogbooks.getJobAttributeValue(job.attributes, 'value_material'),
            individualInvoice = Elogbooks.getJobAttributeValue(job.attributes, 'value_separate_invoice'),
            valuesRejected = Elogbooks.getJobAttributeValue(job.attributes, 'value_rejected');

        return (
            <section className="PendingJobDetails container-fluid">
                <div className="row">
                    <div className="col col-7">
                        <div className="panel">
                            <Loading isLoading={this.state.loading} size="md">
                                <h1 className="clearfix">
                                    {job.summary}

                                    <Reload load={this.load} />
                                </h1>

                                <dl>
                                    <dt>Client</dt>
                                    <dd>{client.name}</dd>
                                    <dt>Site Name</dt>
                                    <dd>{Response.getLinkAttribute(job, 'site', 'title')}</dd>
                                    <dt>Approver</dt>
                                    <dd>{Response.getLinkAttribute(job, 'current-approver', 'title')} {this.getContactDetails('approver')}</dd>
                                    <dt>Service Provider</dt>
                                    <dd>{Response.getLinkAttribute(job, 'operative', 'title')} {this.getContactDetails('operative')}</dd>
                                    <dt>Elogbooks Job ID</dt>
                                    <dd>
                                        {job.id}
                                        &nbsp;
                                        <a href={Elogbooks.createFrontendLink(
                                                client.installationUrl,
                                                '/user/jobs/{job}/status',
                                                {
                                                    job: Response.getLinkAttribute(job, 'self', 'href'),
                                                }
                                            )}
                                            className="small"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            View Job
                                        </a>
                                    </dd>
                                    <dt>Summary</dt>
                                    <dd>{job.summary}</dd>
                                    {!job.description ? null : (
                                        <>
                                            <dt>Description</dt>
                                            <dd>{job.description}</dd>
                                        </>
                                    )}
                                    {!job.completedAt ? null : (
                                        <>
                                            <dt>Completed At</dt>
                                            <dd>{DateTime.fromISO(job.completedAt).toUTC().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</dd>
                                        </>
                                    )}
                                    {valuesRejected !== true ? null : (
                                        <>
                                            <dt>Values Rejected?</dt>
                                            <dd>Yes</dd>
                                        </>
                                    )}
                                    {!labourValue ? null : (
                                        <>
                                            <dt>Labour Value</dt>
                                            <dd>{NumberFormatting.formatCurrency(labourValue)}</dd>
                                        </>
                                    )}
                                    {!materialValue ? null : (
                                        <>
                                            <dt>Material Value</dt>
                                            <dd>{NumberFormatting.formatCurrency(materialValue)}</dd>
                                        </>
                                    )}
                                    {!invoiceDate ? null : (
                                        <>
                                            <dt>Invoice Date</dt>
                                            <dd>{DateTime.fromISO(invoiceDate).toUTC().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</dd>
                                        </>
                                    )}
                                    <dt>Individual Invoice?</dt>
                                    <dd>{individualInvoice ? 'Yes' : 'No'}</dd>
                                </dl>
                            </Loading>
                        </div>
                    </div>

                    <div className="col col-5">
                        <div className="panel">
                            <Loading isLoading={this.state.loading} size="md">
                                <NoteFeed resource={Response.getLink(job, 'notes')}
                                    createResource={Response.getLink(job, 'create-note')} />
                            </Loading>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    getContactDetails = (contact) => {
        if (this.state.jobResponse.contactInformation) {
            const contactInformation = this.state.jobResponse.contactInformation[contact];

            if (contactInformation.email || contactInformation.telephone) {
                return (
                    <span>(
                        {!contactInformation.email ? null : (
                            <a href={`mailto:${contactInformation.email}`}>{contactInformation.email}</a>
                        )}
                        {contactInformation.email && contactInformation.telephone ? ' / ' : ''}
                        {!contactInformation.telephone ? null : (
                            contactInformation.telephone
                        )}
                    )</span>
                );
            }
        }

        if (this.state.jobResponse.contactInformation) {
            const contactInformation = this.state.jobResponse.contactInformation[contact];
            let contactParts = [];

            if (contactInformation.email) {
                contactParts.push(`<a href="mailto:${contactInformation.email}">${contactInformation.email}</a>`);
            }

            if (contactInformation.telephone) {
                contactParts.push(contactInformation.telephone);
            }

            if (contactParts.length) {
                return contactParts;
            }
        }
    };

    componentDidMount() {
        this.load();
    }

    load = () => {
        this.setState({loading: true});

        const { clientId, elogbooksJobId } = this.props.match.params;

        Promise.all([
            fetchClient(clientId),
            fetchJobByClient(clientId, elogbooksJobId),
        ]).then((responses) => {
            this.setState({
                loading: false,
                client: responses[0],
                jobResponse: responses[1],
            });
        });
    };
}

export default PendingJobDetails;
