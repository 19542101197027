import {
    FETCHED_COMPLETED_PENDING_VALUE_APPROVAL_JOBS_BY_CLIENT,
    FETCHED_COMPLETED_PENDING_VALUE_JOBS_BY_CLIENT,
    FETCHED_COMPLETED_REJECTED_VALUE_JOBS_BY_CLIENT,
    FETCHED_OPEN_JOBS_BY_CLIENT,
    FETCHING_COMPLETED_PENDING_VALUE_APPROVAL_JOBS_BY_CLIENT,
    FETCHING_COMPLETED_PENDING_VALUE_JOBS_BY_CLIENT,
    FETCHING_COMPLETED_REJECTED_VALUE_JOBS_BY_CLIENT,
    FETCHING_OPEN_JOBS_BY_CLIENT,
    UPDATING_PENDING_JOB_FILTERS,
    CLEARING_PENDING_JOB_FILTERS,
    UPDATING_PENDING_JOB_ORDERING,
} from 'actions/elogbooks/job';
import { LOGOUT } from 'actions/auth';

const defaultState = {
    data: {},
    filters: {},
    filterData: {},
    ordering: null,
};

export default function job(state = defaultState, action)
{
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case UPDATING_PENDING_JOB_ORDERING:
            return Object.assign(baseState, {
                ordering: action.ordering,
            });

        case UPDATING_PENDING_JOB_FILTERS:
            return Object.assign(baseState, {
                filters: action.filters,
                filterData: action.filterData,
            });

        case CLEARING_PENDING_JOB_FILTERS:
            return Object.assign(baseState, {
                filters: defaultState.filters,
                filterData: defaultState.filterData,
            });

        case FETCHED_COMPLETED_PENDING_VALUE_APPROVAL_JOBS_BY_CLIENT:
            var client = Object.assign(
                    {},
                    baseState.data[action.client] || {},
                    {
                        pendingapproval: {
                            collection: action.response,
                            count: action.response.count,
                            filters: action.filters,
                            isRequesting: false,
                        },
                    }
                ),
                data = Object.assign({}, baseState.data, {[action.client]: client});

            return Object.assign(baseState, {data: data});

        case FETCHED_COMPLETED_PENDING_VALUE_JOBS_BY_CLIENT:
            var client = Object.assign(
                    {},
                    baseState.data[action.client] || {},
                    {
                        pendingvalue: {
                            collection: action.response,
                            count: action.response.count,
                            filters: action.filters,
                            isRequesting: false,
                        },
                    }
                ),
                data = Object.assign({}, baseState.data, {[action.client]: client});

            return Object.assign(baseState, {data: data});

        case FETCHED_COMPLETED_REJECTED_VALUE_JOBS_BY_CLIENT:
            var client = Object.assign(
                    {},
                    baseState.data[action.client] || {},
                    {
                        rejected: {
                            collection: action.response,
                            count: action.response.count,
                            filters: action.filters,
                            isRequesting: false,
                        },
                    }
                ),
                data = Object.assign({}, baseState.data, {[action.client]: client});

            return Object.assign(baseState, {data: data});

        case FETCHED_OPEN_JOBS_BY_CLIENT:
            var client = Object.assign(
                    {},
                    baseState.data[action.client] || {},
                    {
                        open: {
                            collection: action.response,
                            count: action.response.count,
                            filters: action.filters,
                            isRequesting: false,
                        },
                    }
                ),
                data = Object.assign({}, baseState.data, {[action.client]: client});

            return Object.assign(baseState, {data: data});

        case FETCHING_COMPLETED_PENDING_VALUE_APPROVAL_JOBS_BY_CLIENT:
            var client = Object.assign(
                    {},
                    baseState.data[action.client] || {},
                    {
                        pendingapproval: {
                            isRequesting: true
                        },
                    },
                ),
                data = Object.assign({}, baseState.data, {[action.client]: client});

            return Object.assign(baseState, {data: data});

        case FETCHING_COMPLETED_PENDING_VALUE_JOBS_BY_CLIENT:
            var client = Object.assign(
                    {},
                    baseState.data[action.client] || {},
                    {
                        pendingvalue: {
                            isRequesting: true
                        },
                    },
                ),
                data = Object.assign({}, baseState.data, {[action.client]: client});

            return Object.assign(baseState, {data: data});

        case FETCHING_COMPLETED_REJECTED_VALUE_JOBS_BY_CLIENT:
            var client = Object.assign(
                    {},
                    baseState.data[action.client] || {},
                    {
                        rejected: {
                            isRequesting: true
                        },
                    },
                ),
                data = Object.assign({}, baseState.data, {[action.client]: client});

            return Object.assign(baseState, {data: data});

        case FETCHING_OPEN_JOBS_BY_CLIENT:
            var client = Object.assign(
                    {},
                    baseState.data[action.client] || {},
                    {
                        open: {
                            isRequesting: true
                        },
                    },
                ),
                data = Object.assign({}, baseState.data, {[action.client]: client});

            return Object.assign(baseState, {data: data});

        case LOGOUT:
            return Object.assign({}, defaultState);

        default:
            return Object.assign(baseState);
    }
}
