import HTTP from 'services/HTTP';
import { toast } from 'react-toastify';

export {
    create,
};

function create(data = {})
{
    return HTTP
        .post('/salesinvoices/new', data)
        .then((response) => {
            toast.success('Sales invoice created');

            return response.data;
        })
        .catch((error) => {
            toast.error(error.response.data.message);

            return false;
        });
}
