import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { fetchUser } from 'actions/user';
import Loading from 'components/higher-order-components/Loading';
import Reload from 'components/elements/Reload';
import JWT from 'services/JWT';
import HoldBadge from 'components/elements/HoldBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons'

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching: state.user.isFetching,
        userResponse: state.user.users[ownProps.match.params.id],
    };
};

const mapDispatchToProps = {
    fetchUser: fetchUser,
};

class UserDetails extends Component
{
    constructor(props) {
        super(props);

        this.load = this.load.bind(this);
    }

    render() {
        const { match, history } = this.props;
        const user = this.props.userResponse || {roles: []};

        return (
            <div className="UserDetails">
                <Loading isLoading={this.props.isFetching} size="md">
                    <h1 className="clearfix">
                        {user.forename} {user.surname}

                        <Reload load={this.load} />

                        { JWT.hasRole('ROLE_ADMINISTRATOR', 'ROLE_ADMINISTRATOR_USERS') === false ? null :
                            <Button className="float-right"
                                size="sm"
                                onClick={() => history.push(`${match.url}/edit`)}>
                                Edit
                            </Button>
                        }
                    </h1>

                    <dl>
                        <dt>Email</dt>
                        <dd>{user.email}{user.isPrimaryEmail ? <span className='ml-1'><FontAwesomeIcon icon={faCheckCircle} size="xs" /></span> : null}</dd>
                        <dt>Roles</dt>
                        <dd>{user.roles.map((item) => { return item.substring(5).toLowerCase().replace(/\b[a-z]/g, (letter) => letter.toUpperCase()); }).join(', ')}</dd>
                        <dt>Status</dt>
                        <dd>{user.isActive ? 'Active' : 'Inactive'}</dd>
                        {!user.highlightColour ? null : (
                            <>
                                <dt>Highlight Colour</dt>
                                <dd className="mt-2"><HoldBadge prefix="Highlight example for" user={user}/></dd>
                            </>
                        )}
                    </dl>
                </Loading>
            </div>
        );
    }

    componentDidMount() {
        // if (typeof this.props.userResponse === 'undefined') {
            this.load();
        // }
    }

    load() {
        const { id } = this.props.match.params;

        this.props.fetchUser(id);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
