import HTTP from 'services/HTTP';

export const CREATED_USER = 'CREATED_USER';
export const CREATING_USER = 'CREATING_USER';
export const CREATING_USER_FAILED = 'CREATING_USER_FAILED';
export const FETCHED_USER = 'FETCHED_USER';
export const FETCHED_USERS = 'FETCHED_USERS';
export const FETCHING_USER = 'FETCHING_USER';
export const FETCHING_USERS = 'FETCHING_USERS';
export const FETCHING_USER_FAILED = 'FETCHING_USER_FAILED';
export const UPDATED_USER = 'UPDATED_USER';
export const UPDATING_USER = 'UPDATING_USER';
export const UPDATING_USER_FAILED = 'UPDATING_USER_FAILED';

export {
    create,
    fetchUser,
    fetchUsers,
    update,
};

function fetchUsers(params = {})
{
    return dispatch => {
        dispatch(fetchingUsers(params));

        return HTTP
            .get('/users', params)
            .then((response) => {
                dispatch(fetchedUsers(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingUserFailed(response.data));

                return false;
            });
    };
}

function create(data = {})
{
    return (dispatch, getState) => {
        dispatch(creatingUser(data));

        return HTTP
            .post('/users/new', data)
            .then((response) => {
                dispatch(createdUser(response.data));
                dispatch(fetchUsers(getState().user.filters));

                return response.data;
            }, (response) => {
                dispatch(creatingUserFailed(response.data));

                return false;
            });
    };
}

function update(id, data = {})
{
    return (dispatch, getState) => {
        dispatch(updatingUser(id, data));

        return HTTP
            .put('/users/' + id, data)
            .then((response) => {
                dispatch(updatedUser(id, response.data));
                dispatch(fetchUsers(getState().user.filters));

                return response.data;
            }, (response) => {
                dispatch(updatingUserFailed(id, response.data));

                return false;
            });
    };
}

function fetchUser(id)
{
    return dispatch => {
        dispatch(fetchingUser(id));

        return HTTP
            .get('/users/' + id)
            .then((response) => {
                dispatch(fetchedUser(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingUserFailed(response.data));

                return false;
            });
    };
}

function fetchingUsers(params = {})
{
    return {
        type: FETCHING_USERS,
    }
}

function fetchedUsers(response)
{
    return {
        type: FETCHED_USERS,
        collection: response,
    }
}

function creatingUser(data = {})
{
    return {
        type: CREATING_USER,
        data: data,
    };
}

function createdUser(response)
{
    return {
        type: CREATED_USER,
    };
}

function creatingUserFailed(response)
{
    return {
        type: CREATING_USER_FAILED,
    };
}

function updatingUser(id, data = {})
{
    return {
        type: UPDATING_USER,
        id: id,
        data: data,
    };
}

function updatedUser(id, response)
{
    return {
        type: UPDATED_USER,
        id: id,
        response: response,
    };
}

function updatingUserFailed(id, response)
{
    return {
        type: UPDATING_USER_FAILED,
        id: id,
        response: response,
    };
}

function fetchingUser(id)
{
    return {
        type: FETCHING_USER,
        id: id,
    };
}

function fetchingUserFailed(response)
{
    return {
        type: FETCHING_USER_FAILED,
    };
}

function fetchedUser(response)
{
    return {
        type: FETCHED_USER,
        response: response,
    };
}
