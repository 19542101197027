import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import ClientsList from 'components/clients/ClientsList';
import ClientAddEdit from 'components/clients/ClientAddEdit';
import ClientDetails from 'components/clients/ClientDetails';

class Clients extends Component
{
    render() {
        const { match } = this.props;

        return (
            <div className="Clients">
                <Switch>
                    <Route path={`${match.path}`} component={ClientsList} exact />
                    <Route path={`${match.path}/add`} component={ClientAddEdit} />
                    <Route path={`${match.path}/:id/edit`} component={ClientAddEdit} />
                    <Route path={`${match.path}/:id`} component={ClientDetails} />
                </Switch>
            </div>
        );
    }
}

export default withRouter(Clients);
