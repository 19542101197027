import React, { Component } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import PropTypes from 'prop-types';

class AsyncSelect extends Component
{
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        action: PropTypes.func.isRequired,
        responseKey: PropTypes.string.isRequired,
        requestFilters: PropTypes.object,
        requestKey: PropTypes.string,
        idKey: PropTypes.string,
        labelKey: PropTypes.string,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        defaultSelected: PropTypes.array,
    };

    static defaultProps = {
        placeholder: 'Type to search...',
        idKey: 'id',
        labelKey: 'name',
        requestKey: 'name',
        disabled: false,
        requestFilters: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            options: [],
            isLoading: false,
            typeaheadId: Math.ceil(Math.random() * 999999) + '-' + Math.ceil(Math.random() * 999999),
        };
    }

    render() {
        return (
            <AsyncTypeahead id={this.state.typeaheadId}
                options={this.state.options}
                isLoading={this.state.isLoading}
                onSearch={this.handleSearch}
                onChange={this.handleChange}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
                selectHintOnEnter={true}
                defaultSelected={this.props.defaultSelected} />
        );
    }

    handleChange = (item) => {
        this.props.onChange(item[0]);
    };

    handleSearch = (query) => {
        this.setState({isLoading: true});

        this.props.action({
            ...this.props.requestFilters,
            [this.props.requestKey]: query,
        }).then((response) => {
            this.setState({
                isLoading: false,
                options: response[this.props.responseKey].map((item) => {
                    return {
                        id: item[this.props.idKey],
                        label: item[this.props.labelKey],
                    };
                }),
            });
        });
    };
}

export default AsyncSelect;
