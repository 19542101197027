import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class Icon extends Component
{
    render() {
        const { icon, ...others } = this.props;

        return <FontAwesomeIcon icon={['fas', icon]} {...others} />;
    }
}
