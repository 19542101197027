import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Button, FormGroup, FormControl, FormLabel, InputGroup } from "react-bootstrap";
import queryString from 'querystring';
import HTTP from 'services/HTTP';
import ButtonLink from 'components/elements/ButtonLink';
import { USER_PASSWORD_LENGTH_MINIMUM } from 'constants/user';

import './PasswordReset.scss';

class PasswordReset extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            password: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event)
    {
        event.stopPropagation();
        event.preventDefault();

        const { history } = this.props;

        let parsedQueryString = queryString.parse(this.props.location.search.split('?')[1]);
        const data = {
            token: parsedQueryString.token,
            password: this.state.password,
        };

        HTTP
            .post('/password-reset', data)
            .then((response) => {
                if (response) {
                    toast.success('Password reset successfully');

                    history.push('/login');

                    return true;
                }

                toast.error('Unable to reset password');

                return false;
            })
    }

    render()
    {
        return (
            <div className="PasswordReset">
                <form ref="form"
                    onSubmit={ e => this.handleSubmit(e) }>
                    <FormGroup controlId="password" bssize="large">
                        <FormLabel>Password</FormLabel>
                        <FormControl id="password"
                            type="password"
                            autoComplete="new-password"
                            placeholder="Password"
                            value={ this.state.password }
                            onChange={ e => { this.setState({"password": e.target.value}) } }
                            minLength={ USER_PASSWORD_LENGTH_MINIMUM }
                            required />
                    </FormGroup>
                    <FormGroup>
                        <ButtonLink type="button"
                            variant="default"
                            className="float-left"
                            to="/login">
                            Cancel
                        </ButtonLink>

                        <Button type="submit"
                            variant="primary"
                            className="float-right">
                            Submit
                        </Button>
                    </FormGroup>
                </form>
            </div>
        );
    }
}

export default PasswordReset;
