import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import PropertiesList from 'components/properties/PropertiesList';
import PropertyAddEdit from 'components/properties/PropertyAddEdit';
import PropertyDetails from 'components/properties/PropertyDetails';

class Properties extends Component
{
    render() {
        const { match } = this.props;

        return (
            <div className="Properties">
                <Switch>
                    <Route path={`${match.path}`} component={PropertiesList} exact />
                    <Route path={`${match.path}/add`} component={PropertyAddEdit} />
                    <Route path={`${match.path}/:id/edit`} component={PropertyAddEdit} />
                    <Route path={`${match.path}/:id`} component={PropertyDetails} />
                </Switch>
            </div>
        );
    }
}

export default withRouter(Properties);
