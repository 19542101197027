const notOnHold = {
    id: 0,
    label: 'No',
};

const onHold = {
    id: 1,
    label: 'Yes',
};

const holdOptions = [
    notOnHold,
    onHold,
];

export {
    notOnHold,
    onHold,
    holdOptions,
}
