import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export default class Reload extends Component
{
    render() {
        const helper = (
            <Tooltip>
                Reload
            </Tooltip>
        );

        return (
            <OverlayTrigger trigger="hover" placement="right" overlay={helper}>
                <div className="Reload" onClick={() => this.props.load()}>
                    <FontAwesomeIcon icon={['fas', 'sync']} />
                </div>
            </OverlayTrigger>
        );
    }
}
