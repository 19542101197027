import HTTP from 'services/HTTP';
import JWT from 'services/JWT';

export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const TOKEN_INVALID = 'TOKEN_INVALID';
export const TOKEN_VALID = 'TOKEN_VALID';

export {
    checkToken,
    login,
    logout,
};

function login(credentials) {
    return dispatch => {
        dispatch(requestLogin(credentials));

        return HTTP
            .post('/auth', credentials)
            .then((response) => {
                dispatch(loginSuccess(response.data));
            }, (response) => {
                dispatch(loginFailure(response.data));
            });
    };
}

function logout() {
    return dispatch => {
        dispatch(processLogout());
    };
}

function checkToken(token) {
    return dispatch => {
        if (JWT.isValid(token)) {
            return dispatch(existingTokenIsValid(token));
        }

        return dispatch(existingTokenIsInvalid(token));
    };
}


function requestLogin(credentials) {
    return {
        type: REQUEST_LOGIN,
        isRequesting: true,
        isAuthenticated: false
    };
}

function loginSuccess(response) {
    return {
        type: LOGIN_SUCCESS,
        isRequesting: false,
        isAuthenticated: true,
        token: response.token,
        user: response.user,
    };
}

function loginFailure(response) {
    return {
        type: LOGIN_FAILURE,
        isRequesting: false,
        isAuthenticated: false,
    };
}

function existingTokenIsValid(token) {
    return {
        type: TOKEN_VALID,
        token: token,
    };
}

function existingTokenIsInvalid(token) {
    return {
        type: TOKEN_INVALID,
    };
}

function processLogout() {
    return {
        type: LOGOUT,
    };
}
