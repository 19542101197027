import {
    SIDEBAR_TOGGLE
} from 'actions/sidebar';
import { LOGOUT } from 'actions/auth';

const defaultState = {
    isSidebarExpanded: true,
};

export default function sidebar(state = defaultState, action)
{
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case SIDEBAR_TOGGLE:
            return Object.assign(baseState, {
                isSidebarExpanded: !state.isSidebarExpanded,
            });

        case LOGOUT:
            return Object.assign({}, defaultState);

        default:
            return Object.assign(baseState);
    }
}
