import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUsers } from 'actions/user';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Table from 'components/elements/Table';
import Loading from 'components/higher-order-components/Loading';
import NoResultsFound from 'components/higher-order-components/NoResultsFound';
import Reload from 'components/elements/Reload';
import Paginator from 'components/elements/Paginator';
import ItemCount from 'components/elements/ItemCount';
import JWT from 'services/JWT';

const mapStateToProps = (state) => {
    return {
        filters: state.user.filters,
        isLoading: state.user.isRequesting,
        userCollectionResponse: state.user.collection,
    };
};

const mapDispatchToProps = {
    fetchUsers: fetchUsers,
};

class UsersList extends Component
{
    static propTypes = {
        filters: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
        userCollectionResponse: PropTypes.shape({
            count: PropTypes.number.isRequired,
            limit: PropTypes.number.isRequired,
            page: PropTypes.number.isRequired,
            pages: PropTypes.number.isRequired,
            users: PropTypes.array,
        }).isRequired,
    };

    static defaultProps = {
        filters: {},
        isLoading: false,
        userCollectionResponse: {
            count: 0,
            limit: 10,
            page: 1,
            pages: 0,
            users: undefined,
        },
    };

    constructor(props) {
        super(props);

        this.changePage = this.changePage.bind(this);
        this.load = this.load.bind(this);
    }

    render() {
        const { match, history } = this.props;

        const columns = [
            {header: 'Forename', width: 5, visible: true, key: 'forename', },
            {header: 'Surname', width: 5, visible: true, key: 'surname', },
            {header: 'Roles', width: 3, visible: true, key: 'roles', type: 'array', modifier: (item) => { return item.substring(5).toLowerCase().replace(/\b[a-z]/g, (letter) => letter.toUpperCase()); }, },
            {header: 'Status', width: 2, visible: true, key: 'isActive', type: 'active', },
            {header: '', width: 2, visible: true, type: 'button', link: 'self', text: 'View', toState: this.generateToState, fixedRight: true, },
        ];
        const collection = this.props.userCollectionResponse || {};

        return (
           <div className="UsersList">
                <h1 className="clearfix">
                    Users List
                    <ItemCount count={collection.count} />

                    <Reload load={this.load} />

                    { JWT.hasRole('ROLE_ADMINISTRATOR', 'ROLE_ADMINISTRATOR_USERS') === false ? null :
                        <Button className="float-right"
                            size="sm"
                            onClick={() => history.push(`${match.path}/add`)}>
                            Add a New User
                        </Button>
                    }
                </h1>

                <Loading isLoading={this.props.isLoading} size="md">
                    <NoResultsFound count={collection.count}>
                        <Table
                            data={collection.users || []}
                            columns={columns} />

                        <Paginator page={collection.page}
                            count={collection.count}
                            limit={collection.limit}
                            onPage={this.changePage}/>
                    </NoResultsFound>
                </Loading>
           </div>
        );
    }

    componentDidMount() {
        if (typeof this.props.userCollectionResponse !== 'object' ||
            typeof this.props.userCollectionResponse.users === 'undefined') {
            this.load();
        }
    }

    generateToState(user) {
        return '/users/' + user.id;
    }

    changePage(page) {
        Object.assign(this.props.filters, {page: page});

        this.load();
    }

    load() {
        this.props.fetchUsers(this.props.filters);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
