import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

class ConfirmationDialog extends Component
{
    static propTypes = {
        show: PropTypes.bool.isRequired,
        hide: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        title: PropTypes.string,
        body: PropTypes.string,
    };

    static defaultProps = {
        title: 'Are you sure?',
    };

    render() {
        return (
            <Modal show={this.props.show}
                onHide={this.props.hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                { !this.props.body ? null : (
                    <Modal.Body>
                        {this.props.body}
                    </Modal.Body>
                )}
                <Modal.Footer>
                    <Button variant="secondary"
                        onClick={this.props.hide}>
                        Cancel
                    </Button>
                    <Button variant="primary"
                        onClick={this.props.onConfirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ConfirmationDialog;
