import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import JWT from 'services/JWT';

const ProtectedRoute = ({component: Component, requireRole, ...rest}) => {
    function hasRole() {
        return JWT.hasRole(requireRole);
    }

    return (
        <Route {...rest} render={props => (
            hasRole() ?
                <Component {...props} /> :
                <Redirect to="/dashboard" />
        )} />
    );
};

export default ProtectedRoute;