import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from 'components/higher-order-components/Loading';
import Reload from 'components/elements/Reload';
import { fetchPurchaseInvoice, creditPurchaseInvoice, cancelPurchaseInvoiceHold } from 'actions/purchaseinvoice';
import Response from 'services/Response';
import NumberFormatting from 'services/NumberFormatting';
import { Button, ButtonToolbar, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import PromptDialog from 'components/elements/PromptDialog';
import { states } from 'constants/purchaseinvoice';
import Elogbooks from 'services/Elogbooks';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';

const mapStateToProps = (state, ownProps) => {
    return {
        isRequesting: state.purchaseInvoice.isRequesting,
        purchaseInvoiceResponse: state.purchaseInvoice.purchaseInvoices[ownProps.match.params.id],
    };
};

const mapDispatchToProps = {
    fetchPurchaseInvoice: fetchPurchaseInvoice,
    creditPurchaseInvoice: creditPurchaseInvoice,
    cancelPurchaseInvoiceHold: cancelPurchaseInvoiceHold,
};

class PurchaseInvoiceDetails extends Component
{
    constructor(props) {
        super(props);

        this.load = this.load.bind(this);

        this.state = {
            showCancelHoldConfirmation: false,
            showRaiseCreditPrompt: false,
            purchaseCreditNumber: '',
        };
    }

    render() {
        const purchaseInvoice = this.props.purchaseInvoiceResponse || {};

        return (
            <div className="PurchaseInvoiceDetails">
                <Loading isLoading={this.props.isRequesting} size="md">
                    <h1 className="clearfix">
                        {purchaseInvoice.exchequerPurchaseInvoiceNumber || 'Pending Sync'}

                        <Reload load={this.load} />

                        <ButtonToolbar className="float-right">
                            { Response.getLink(purchaseInvoice, 'credit') === null ? null :
                                <Button onClick={() => this.setState({showRaiseCreditPrompt: true})}
                                    size="sm">
                                    Raise Credit
                                </Button>
                            }
                            { Response.getLink(purchaseInvoice, 'cancel-hold') === null ? null :
                                <Button onClick={() => this.setState({showCancelHoldConfirmation: true})}
                                    size="sm"
                                    className="ml-2">
                                    Cancel Hold
                                </Button>
                            }
                        </ButtonToolbar>

                        <ConfirmationDialog show={this.state.showCancelHoldConfirmation}
                            hide={() => this.setState({showCancelHoldConfirmation: false})}
                            onConfirm={() => this.handleCancelHold()} />

                        <PromptDialog show={this.state.showRaiseCreditPrompt}
                            hide={() => this.setState({showRaiseCreditPrompt: false})}
                            onSubmit={(e) => this.handleRaiseCredit(e)}
                            validateForm={() => {return this.validatePurchaseCreditForm()}}
                            title={`Credit ${purchaseInvoice.exchequerPurchaseInvoiceNumber}`}>
                            <FormGroup controlId="creditNumber">
                                <FormLabel>Purchase Credit Number</FormLabel>
                                <FormControl as="input"
                                    ref="creditNumber"
                                    value={this.state.purchaseCreditNumber}
                                    onChange={e => { this.setState({purchaseCreditNumber: e.target.value}) }} />
                            </FormGroup>
                        </PromptDialog>
                    </h1>

                    <dl>
                        <dt>Client</dt>
                        <dd>{Response.getLinkAttribute(purchaseInvoice, 'client', 'title')}</dd>
                        <dt>Property</dt>
                        <dd>{Response.getLinkAttribute(purchaseInvoice, 'property', 'title')}</dd>
                        <dt>Elogbooks Job ID</dt>
                        <dd>
                            {Response.getLinkAttribute(purchaseInvoice, 'job', 'elogbooksJobId')}
                            &nbsp;
                            <a href={Elogbooks.createFrontendLink(
                                    Response.getLinkAttribute(purchaseInvoice, 'client', 'installationUrl'),
                                    '/user/jobs/{job}/status',
                                    {
                                        job: Response.getLinkAttribute(purchaseInvoice, 'job', 'elogbooksJobLink'),
                                    }
                                )}
                                className="small"
                                target="_blank"
                                rel="noopener noreferrer">
                                View Job
                            </a>
                        </dd>
                        <dt>Sage Purchase Order Number</dt>
                        <dd>{purchaseInvoice.sagePurchaseOrderNumber}</dd>
                        <dt>Exchequer(Sage) Purchase Invoice Number</dt>
                        <dd>{purchaseInvoice.exchequerPurchaseInvoiceNumber}</dd>
                        <dt>Total Labour</dt>
                        <dd>{NumberFormatting.formatCurrency(purchaseInvoice.labourValue)}</dd>
                        <dt>Total Materials</dt>
                        <dd>{NumberFormatting.formatCurrency(purchaseInvoice.materialValue)}</dd>
                        <dt>Total (Labour + Materials)</dt>
                        <dd>{NumberFormatting.formatCurrency(purchaseInvoice.labourValue + purchaseInvoice.materialValue)}</dd>
                        <dt>State</dt>
                        <dd>{states[purchaseInvoice.state]}</dd>
                    </dl>
                </Loading>
            </div>
        );
    }

    componentDidMount() {
        this.load();
    }

    handleCancelHold() {
        this.setState({showCancelHoldConfirmation: false});

        this.props.cancelPurchaseInvoiceHold(this.props.purchaseInvoiceResponse.id);
    }

    handleRaiseCredit(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({showRaiseCreditPrompt: false});

        this.props.creditPurchaseInvoice(
            this.props.purchaseInvoiceResponse.id,
            {
                purchaseCreditNumber: this.state.purchaseCreditNumber,
            }
        ).then((result) => {
            if (result) {
                this.setState({purchaseCreditNumber: ''});
            }
        });
    }

    validatePurchaseCreditForm() {
        return !!this.state.purchaseCreditNumber;
    }

    load() {
        const { id } = this.props.match.params;

        this.props.fetchPurchaseInvoice(id);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseInvoiceDetails);