import HTTP from 'services/HTTP';

export const FETCHING_ELOGBOOKS_PROPERTIES = 'FETCHING_ELOGBOOKS_PROPERTIES';
export const FETCHED_ELOGBOOKS_PROPERTIES = 'FETCHED_ELOGBOOKS_PROPERTIES';
export const FETCHING_ELOGBOOKS_PROPERTIES_FAILED = 'FETCHING_ELOGBOOKS_PROPERTIES_FAILED';
export const UPDATING_ELOGBOOKS_PROPERTY_FILTERS = 'UPDATING_ELOGBOOKS_PROPERTY_FILTERS';
export const CLEARING_ELOGBOOKS_PROPERTY_FILTERS = 'CLEARING_ELOGBOOKS_PROPERTY_FILTERS';

export {
    fetchElogbooksProperties,
    updateElogbooksPropertyFilters,
    clearElogbooksPropertyFilters,
};

function fetchElogbooksProperties(client, params = {})
{
    return dispatch => {
        dispatch(fetchingElogbooksProperties());

        return HTTP
            .get(`/clients/${client}/elogbooks/properties`, params)
            .then((response) => {
                dispatch(fetchedElogbooksProperties(response.data));

                return response.data
            }, (response) => {
                dispatch(fetchingElogbooksPropertiesFailed(response.data));

                return false;
            });
    };
}

function updateElogbooksPropertyFilters(filters, filterData)
{
    return dispatch => {
        dispatch(updatingElogbooksPropertyFilters(filters, filterData));
    };
}

function clearElogbooksPropertyFilters()
{
    return dispatch => {
        dispatch(clearingElogbooksPropertyFilters());
    };
}

function fetchingElogbooksProperties()
{
    return {
        type: FETCHING_ELOGBOOKS_PROPERTIES,
    };
}

function fetchedElogbooksProperties(response)
{
    return {
        type: FETCHED_ELOGBOOKS_PROPERTIES,
        collection: response,
    };
}

function fetchingElogbooksPropertiesFailed(response)
{
    return {
        type: FETCHING_ELOGBOOKS_PROPERTIES_FAILED,
    };
}

function updatingElogbooksPropertyFilters(filters, filterData)
{
    return {
        type: UPDATING_ELOGBOOKS_PROPERTY_FILTERS,
        filters: filters,
        filterData: filterData,
    };
}

function clearingElogbooksPropertyFilters()
{
    return {
        type: CLEARING_ELOGBOOKS_PROPERTY_FILTERS,
    };
}