import React, { Component } from 'react';
import DatePickerComponent from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

class DatePicker extends Component
{
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        startDate: PropTypes.instanceOf(DateTime),
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        placeholder: 'Click to select a date',
    };

    constructor(props) {
        super(props);

        this.state = {
            selected: this.props.startDate ? this.props.startDate.toJSDate() : null,
        };
    }

    render() {
        return (
            <DatePickerComponent dateFormat="dd/MM/yyyy"
                selected={this.state.selected}
                placeholderText={this.props.placeholder}
                onChange={this.onChange} />
        );
    }

    onChange = (date) => {
        this.setState({
            selected: date,
        });

        this.props.onChange(DateTime.fromJSDate(date));
    }
}

export default DatePicker;
