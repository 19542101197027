import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchClients } from 'actions/client';
import PropTypes from 'prop-types';
import Table from 'components/elements/Table';
import Loading from 'components/higher-order-components/Loading';
import NoResultsFound from 'components/higher-order-components/NoResultsFound';
import ButtonLink from 'components/elements/ButtonLink';
import Reload from 'components/elements/Reload';
import Paginator from 'components/elements/Paginator';
import ItemCount from 'components/elements/ItemCount';
import JWT from 'services/JWT';

const mapStateToProps = (state) => {
    return {
        clientCollectionResponse: state.client.collection,
        filters: state.client.filters,
        isLoading: state.client.isRequesting,
    };
};

const mapDispatchToProps = {
    fetchClients: fetchClients,
};

class ClientsList extends Component
{
    static propTypes = {
        clientCollectionResponse: PropTypes.shape({
            clients: PropTypes.array,
            count: PropTypes.number.isRequired,
            limit: PropTypes.number.isRequired,
            page: PropTypes.number.isRequired,
            pages: PropTypes.number.isRequired,
        }).isRequired,
        filters: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        clientCollectionResponse: {
            clients: undefined,
            count: 0,
            limit: 10,
            page: 1,
            pages: 0,
        },
        filters: {},
        isLoading: false,
    };

    constructor(props) {
        super(props);

        this.changePage = this.changePage.bind(this);
        this.load = this.load.bind(this);
    }

    render() {
        const { match } = this.props;
        const columns = [
            {header: 'Name', width: 10, visible: true, key: 'name', },
            {header: '', width: 2, visible: true, type: 'button', link: 'self', text: 'View', toState: this.generateToState, fixedRight: true, },
        ];
        const collection = this.props.clientCollectionResponse || {};

        return (
            <div className="ClientsList">
                <h1 className="clearfix">
                    Clients
                    <ItemCount count={collection.count} />

                    <Reload load={this.load} />

                    { JWT.hasRole('ROLE_ADMINISTRATOR', 'ROLE_ADMINISTRATOR_CLIENTS') === false ? null :
                        <ButtonLink size="sm" className="float-right" to={`${match.path}/add`}>
                            Add a New Client
                        </ButtonLink>
                    }
                </h1>

                <Loading isLoading={this.props.isLoading} size="md">
                    <NoResultsFound count={collection.count}>
                        <Table
                            data={collection.clients || []}
                            columns={columns}
                            collection={collection} />

                        <Paginator page={collection.page}
                            count={collection.count}
                            limit={collection.limit}
                            onPage={this.changePage}/>
                    </NoResultsFound>
                </Loading>
            </div>
        );
    }

    componentDidMount() {
        if (typeof this.props.clientCollectionResponse !== 'object' ||
            typeof this.props.clientCollectionResponse.clients === 'undefined') {
            this.load();
        }
    }

    generateToState(client) {
        return '/clients/' + client.id;
    }

    changePage(page) {
        Object.assign(this.props.filters, {page: page});
        this.load();
    }

    load() {
        this.props.fetchClients(this.props.filters);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsList);
