class Data
{
    static generateCollectionResponse(collectionKey) {
        return {
            count: 0,
            limit: 10,
            page: 1,
            pages: 0,
            [collectionKey]: [],
        };
    }
}

export default Data;
