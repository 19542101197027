import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logout } from 'actions/auth';
import { connect } from 'react-redux';

const mapDispatchToProps = {
    logout: logout
};

class Logout extends Component
{
    render() {
        return (
            <div className="Logout"
                onClick={(e) => { this.props.logout(); }}>
                <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                Logout
            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(Logout);
