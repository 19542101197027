import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { setProcessSalesInvoices, fetchProperty, hold, cancelHold } from 'actions/property';
import Loading from 'components/higher-order-components/Loading';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import Reload from 'components/elements/Reload';
import JWT from 'services/JWT';
import Response from 'services/Response';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lodash from 'lodash';
import HoldBadge from 'components/elements/HoldBadge';

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching: state.property.isFetching,
        propertyResponse: state.property.properties[ownProps.match.params.id],
    };
};

const mapDispatchToProps = {
    fetchProperty: fetchProperty,
    setProcessSalesInvoices: setProcessSalesInvoices,
    hold: hold,
    cancelHold: cancelHold,
};

class PropertyDetails extends Component
{
    constructor(props) {
        super(props);

        this.load = this.load.bind(this);

        this.state = {
            showProcessSalesInvoicesConfirmation: false,
            showHoldConfirmation: false,
        };
    }

    handleProcessSalesInvoices() {
        this.setState({
            showProcessSalesInvoicesConfirmation: false,
        });

        this.props
            .setProcessSalesInvoices(
                this.props.propertyResponse.id,
                this.props.propertyResponse.processSalesInvoices === false
            )
            .then(() => {
                this.load();
            }, (response) => {
                // @todo show an error
            });
    }

    render() {
        const { match, history } = this.props;
        const property = this.props.propertyResponse || {};

        const heldByUser = lodash.get(property, '_links.held-by-user', null);

        return (
            <div className="PropertyDetails">
                <Loading isLoading={this.props.isFetching} size="md">
                    <h1 className="clearfix">
                        {property.name} {!heldByUser ? null : (
                            <HoldBadge prefix="Held by" user={heldByUser} />
                        )}

                        <Reload load={this.load} />

                        {JWT.hasRole('ROLE_ADMINISTRATOR') === false ? null :
                            <ButtonToolbar className="float-right">
                                <Button size="sm"
                                    onClick={() => this.setState({showProcessSalesInvoicesConfirmation: true})}>
                                    {property.processSalesInvoices === true ? (
                                        <span>
                                            <FontAwesomeIcon icon={['fas', 'sync']} size="xs" spin className="mr-2"/>
                                            Cancel Processing Sales Invoices
                                        </span>
                                    ) : (
                                        <span>Process Sales Invoices</span>
                                    )}
                                </Button>
                                <Button className="ml-2"
                                    size="sm"
                                    onClick={() => history.push(`${match.url}/edit`)}>
                                    Edit
                                </Button>
                                { Response.getLink(property, 'cancel-hold') === null ? null :
                                    <Button onClick={() => this.setState({showHoldConfirmation: true})}
                                        size="sm"
                                        className="ml-2">
                                        Cancel Hold
                                    </Button>
                                }
                                { Response.getLink(property, 'hold') === null ? null :
                                    <Button onClick={() => this.setState({showHoldConfirmation: true})}
                                        size="sm"
                                        className="ml-2">
                                        Hold
                                    </Button>
                                }
                            </ButtonToolbar>
                        }

                        <ConfirmationDialog show={this.state.showProcessSalesInvoicesConfirmation}
                            hide={() => this.setState({showProcessSalesInvoicesConfirmation: false})}
                            onConfirm={() => this.handleProcessSalesInvoices()} />

                        <ConfirmationDialog show={this.state.showHoldConfirmation}
                            hide={() => this.setState({showHoldConfirmation: false})}
                            onConfirm={this.handleHold} />
                    </h1>

                    <dl>
                        <dt>Client</dt>
                        <dd>{Response.getLinkAttribute(property, 'client', 'title')}</dd>
                        <dt>Elogbooks Reference</dt>
                        <dd>{property.elogbooksReference}</dd>
                        <dt>Exchequer Reference</dt>
                        <dd>{property.exchequerReference}</dd>
                        <dt>Exchequer Customer ID</dt>
                        <dd>{property.exchequerCustomerId}</dd>
                        <dt>Status</dt>
                        <dd>{property.isActive ? 'Active' : 'Inactive'}</dd>
                    </dl>
                </Loading>
            </div>
        );
    }

    componentDidMount() {
        this.load();
    }

    handleHold = () => {
        let action;
        if (Response.getLink(this.props.propertyResponse, 'hold')) {
            action = 'hold';
        } else {
            action = 'cancelHold';
        }

        this.setState({showHoldConfirmation: false});
        this.props[action](this.props.propertyResponse.id);
    };

    load() {
        const { id } = this.props.match.params;

        this.props.fetchProperty(id);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
