import {
    CREATED_SERVICE_PROVIDER,
    CREATING_SERVICE_PROVIDER,
    CREATING_SERVICE_PROVIDER_FAILED,
    FETCHED_SERVICE_PROVIDER,
    FETCHED_SERVICE_PROVIDERS,
    FETCHING_SERVICE_PROVIDER,
    FETCHING_SERVICE_PROVIDERS,
    FETCHING_SERVICE_PROVIDER_FAILED,
    UPDATED_SERVICE_PROVIDER,
    UPDATING_SERVICE_PROVIDER,
    UPDATING_SERVICE_PROVIDER_FAILED,
    UPDATING_SERVICE_PROVIDER_FILTERS,
    CLEARING_SERVICE_PROVIDER_FILTERS,
} from 'actions/serviceprovider';
import { LOGOUT } from 'actions/auth';
import { active } from 'constants/active';

const defaultState = {
    collection: {
        count: 0,
        limit: 10,
        page: 1,
        pages: 0,
        serviceproviders: [],
    },
    data: undefined,
    filters: {
        order: 'name',
        isActive: [active.id],
    },
    filterData: {
        isActive: {
            value: [active.id],
            data: [active],
        },
    },
    isCreating: false,
    isRequesting: false,
    isUpdating: false,
    serviceproviders: [],
};

export default function serviceProvider(state = defaultState, action)
{
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case UPDATING_SERVICE_PROVIDER_FILTERS:
            return Object.assign(baseState, {
                filterData: action.filterData,
                filters: action.filters,
            });

        case CLEARING_SERVICE_PROVIDER_FILTERS:
            return Object.assign(baseState, {
                filterData: defaultState.filterData,
                filters: defaultState.filters,
            });

        case CREATED_SERVICE_PROVIDER:
            return Object.assign(baseState, {
                isCreating: false,
                data: undefined,
                response: action.response,
            });

        case CREATING_SERVICE_PROVIDER:
            return Object.assign(baseState, {
                isCreating: true,
                data: action.data,
            });

        case CREATING_SERVICE_PROVIDER_FAILED:
            return Object.assign(baseState, {
                isCreating: false,
                data: undefined,
            });

        case FETCHED_SERVICE_PROVIDER:
            return Object.assign(baseState, {
                isFetching: false,
                serviceproviders: {
                    [action.response.id]: action.response,
                },
            });

        case FETCHED_SERVICE_PROVIDERS:
            return Object.assign(baseState, {
                isRequesting: false,
                collection: action.collection,
            });

        case FETCHING_SERVICE_PROVIDER:
            return Object.assign(baseState, {
                isFetching: true,
            });

        case FETCHING_SERVICE_PROVIDERS:
            return Object.assign(baseState, {
                isRequesting: true,
            });

        case FETCHING_SERVICE_PROVIDER_FAILED:
            return Object.assign(baseState, {
                isFetching: false,
            });

        case UPDATED_SERVICE_PROVIDER:
            return Object.assign(baseState, {
                isUpdating: false,
                data: undefined,
                serviceproviders: {
                    [action.id]: Object.assign(baseState.serviceproviders[action.id], action.data),
                }
            });

        case UPDATING_SERVICE_PROVIDER:
            return Object.assign(baseState, {
                isUpdating: true,
                data: action.data,
            });

        case UPDATING_SERVICE_PROVIDER_FAILED:
            return Object.assign(baseState, {
                isUpdating: false,
                data: undefined,
            });

        case LOGOUT:
            return Object.assign({}, defaultState);

        default:
            return Object.assign(baseState);
    }
}
