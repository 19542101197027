import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { GithubPicker } from 'react-color';

class ColourPicker extends Component
{
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        selected: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            selected: this.props.selected,
            showColourPicker: false,
        }
    }

    render() {
        const colours = [
            '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB',
        ];

        let inputStyle = {};
        if (this.state.selected) {
            inputStyle['backgroundColor'] = this.state.selected;
        }

        return (
            <div>
                <div className="cursor-pointer form-control"
                    onClick={this.handleClick}
                    style={inputStyle} />
                { !this.state.showColourPicker ? null : (
                    <>
                        <div className="colour-picker-popover">
                            <GithubPicker colors={colours}
                                width="212px"
                                onChange={this.handleChange} />
                        </div>
                        <div className="colour-picker-cover" onClick={this.handleClick} />
                    </>
                )}
            </div>

        )
    }

    handleChange = (colour) => {
        this.setState({
            showColourPicker: false,
            selected: colour.hex,
        });

        this.props.onChange(colour);
    };

    handleClick = () => {
        this.setState({showColourPicker: !this.state.showColourPicker});
    };
}

export default ColourPicker;
