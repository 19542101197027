import React, { Component } from 'react';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sidebarToggle } from 'actions/sidebar';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import PropTypes from 'prop-types';
import JWT from 'services/JWT';

const routes = [
    {route: 'dashboard', icon: ['fas', 'columns'], text: 'Dashboard', translation: 'navigation.dashboard'},
    {route: 'users', icon: ['fas', 'users'], text: 'Users', translation: 'navigation.users'},
    {route: 'serviceproviders', icon: ['fas', 'user-hard-hat'], text: 'Service Providers', translation: 'navigation.serviceproviders'},
    {route: 'clients', icon: ['fas', 'user-tie'], text: 'Clients', translation: 'navigation.clients'},
    {route: 'properties', icon: ['fas', 'building'], text: 'Properties', translation: 'navigation.properties'},
    {route: 'elogbooks-properties', requireRole: 'ROLE_ADMINISTRATOR', icon: ['fas', 'torii-gate'], text: 'Elogbooks Properties', translation: 'navigation.elogbooks-properties'},
    {route: 'jobs', icon: ['fas', 'award'], text: 'Jobs', translation: 'navigation.jobs'},
    {route: 'job-errors', icon: ['fas', 'debug'], text: 'Job Import Errors', translation: 'navigation.job-errors'},
    {route: 'purchase-invoices', requireRole: 'ROLE_ADMINISTRATOR', icon: ['fas', 'file-invoice-dollar'], text: 'Purchase Invoices', translation: 'navigation.purchase-invoices'},
    {route: 'sales-invoices', requireRole: 'ROLE_ADMINISTRATOR', icon: ['fas', 'cash-register'], text: 'Sales Invoices', translation: 'navigation.sales-invoices'},
    // {route: 'invoices', icon: ['fas', 'file-invoice'], text: 'Invoices', translation: 'navigation.invoices'},
];

const mapStateToProps = (state) => {
    return {
        isSidebarExpanded: state.sidebar.isSidebarExpanded
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        sidebarToggle: function() { dispatch(sidebarToggle()); },
    };
};

class Sidebar extends Component
{
    static propTypes = {
        isSidebarExpanded: PropTypes.bool.isRequired,
        sidebarToggle: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div className="Sidebar">
                <SideNav
                    expanded={this.props.isSidebarExpanded}
                    onToggle={this.props.sidebarToggle} >
                    <Toggle />
                    <Nav>
                        {routes.map((route, index) => {
                            return this.getNavItem(route);
                        })}
                    </Nav>
                </SideNav>
            </div>
        );
    }

    getNavItem(route) {
        if (route.requireRole && !JWT.hasRole(route.requireRole)) {
            return null;
        }

        return (
            <NavItem key={route.route}
                     eventKey={route.route}
                     active={this.isActive('/' + route.route)}
                     onClick={() => {
                         this.navigateTo(route);
                     }}>
                {this.getNavIcon(route)}
                {this.getNavText(route)}
                {this.getNavChildren(route)}
            </NavItem>
        );
    }

    getNavIcon(route) {
        return route.icon
            ? <NavIcon><FontAwesomeIcon icon={route.icon} /></NavIcon>
            : null;
    }

    getNavText(route) {
        return route.text || route.translation
            ? (<NavText>
                {route.translation
                    ? <Trans i18nKey={route.translation}>{route.text}</Trans>
                    : <div>{route.text}</div>
                }
            </NavText>)
            : null
    }

    getNavChildren(route) {
        return (route.children || []).map((child, index) => {
            delete child.icon;
            return this.getNavItem(child);
        });
    }

    isActive(path) {
        const { location } = this.props;

        if (path === '/') {
            return location.pathname === path;
        }

        return location.pathname.match(new RegExp('^' + path)) !== null;
    }

    navigateTo(route) {
        if (!route.children &&
            this.props.location.pathname !== route.route) {
            this.props.history.push('/' + route.route);
        }
    }

    getNavItemByRoute(route) {
        let returnedRoute;

        routes.forEach((value, index) => {
            if (value.route === route) {
                returnedRoute = value;
            }
        });

        return returnedRoute;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
