import HTTP from 'services/HTTP';

export const CREATED_CLIENT = 'CREATED_CLIENT';
export const CREATING_CLIENT = 'CREATING_CLIENT';
export const CREATING_CLIENT_FAILED = 'CREATING_CLIENT_FAILED';
export const FETCHED_CLIENT = 'FETCHED_CLIENT';
export const FETCHED_CLIENTS = 'FETCHED_CLIENTS';
export const FETCHING_CLIENT = 'FETCHING_CLIENT';
export const FETCHING_CLIENTS = 'FETCHING_CLIENTS';
export const FETCHING_CLIENT_FAILED = 'FETCHING_CLIENT_FAILED';
export const UPDATED_CLIENT = 'UPDATED_CLIENT';
export const UPDATING_CLIENT = 'UPDATING_CLIENT';
export const UPDATING_CLIENT_FAILED = 'UPDATING_CLIENT_FAILED';

export {
    create,
    fetchClient,
    fetchClients,
    update,
};

function fetchClients(params = {})
{
    return dispatch => {
        dispatch(fetchingClients(params));

        return HTTP
            .get('/clients', params)
            .then((response) => {
                dispatch(fetchedClients(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingClientFailed(response.data));

                return false;
            });
    };
}

function create(data = {})
{
    return (dispatch, getState) => {
        dispatch(creatingClient(data));

        return HTTP
            .post('/clients/new', data)
            .then((response) => {
                dispatch(createdClient(response.data));
                dispatch(fetchClients(getState().client.filters));

                return response.data;
            }, (response) => {
                dispatch(creatingClientFailed(response.data));

                return false;
            });
    };
}

function update(id, data = {})
{
    return (dispatch, getState) => {
        dispatch(updatingClient(id, data));

        return HTTP
            .put('/clients/' + id, data)
            .then((response) => {
                dispatch(updatedClient(id, response.data));
                dispatch(fetchClients(getState().user.filters));

                return response.data;
            }, (response) => {
                dispatch(updatingClientFailed(id, response.data));

                return false;
            })
    };
}

function fetchClient(id)
{
    return dispatch => {
        dispatch(fetchingClient(id));

        return HTTP
            .get('/clients/' + id, {datagroup: 'details'})
            .then((response) => {
                dispatch(fetchedClient(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingClientFailed(response.data));

                return false;
            });
    }
}

function fetchingClients(params = {})
{
    return {
        type: FETCHING_CLIENTS,
        filters: params,
    };
}

function fetchedClients(response)
{
    return {
        type: FETCHED_CLIENTS,
        collection: response,
    };
}

function creatingClient(data = {})
{
    return {
        type: CREATING_CLIENT,
        data: data,
    };
}

function createdClient(response)
{
    return {
        type: CREATED_CLIENT,
    };
}

function creatingClientFailed(response)
{
    return {
        type: CREATING_CLIENT_FAILED,
    };
}

function fetchingClient(id)
{
    return {
        type: FETCHING_CLIENT,
        id: id,
    };
}

function fetchingClientFailed(response)
{
    return {
        type: FETCHING_CLIENT_FAILED,
    };
}

function fetchedClient(response)
{
    return {
        type: FETCHED_CLIENT,
        response: response,
    };
}

function updatingClient(id, data = {})
{
    return {
        type: UPDATING_CLIENT,
        id: id,
        data: data,
    };
}

function updatedClient(id, response)
{
    return {
        type: UPDATED_CLIENT,
        id: id,
        response: response,
    };
}

function updatingClientFailed(id, response)
{
    return {
        type: UPDATING_CLIENT_FAILED,
        id: id,
        response: response,
    };
}
