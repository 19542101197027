import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchJobErrors } from 'actions/joberror';
import PropTypes from 'prop-types';
import Table from 'components/elements/Table';
import Loading from 'components/higher-order-components/Loading';
import NoResultsFound from 'components/higher-order-components/NoResultsFound';
import Reload from 'components/elements/Reload';
import Paginator from 'components/elements/Paginator';
import ItemCount from 'components/elements/ItemCount';

const mapStateToProps = (state) => {
    return {
        errorCollectionResponse: state.joberror.collection,
        filters: state.joberror.filters,
        isLoading: state.joberror.isRequesting,
    };
};

const mapDispatchToProps = {
    fetchJobErrors: fetchJobErrors,
};

class ErrorsList extends Component
{
    static propTypes = {
        errorCollectionResponse: PropTypes.shape({
            count: PropTypes.number.isRequired,
            errors: PropTypes.array,
            limit: PropTypes.number.isRequired,
            page: PropTypes.number.isRequired,
            pages: PropTypes.number.isRequired,
        }).isRequired,
        filters: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        errorCollectionResponse: {
            count: 0,
            errors: undefined,
            limit: 10,
            page: 1,
            pages: 0,
        },
        filters: {},
        isLoading: false,
    };

    constructor(props) {
        super(props);

        this.changePage = this.changePage.bind(this);
        this.load = this.load.bind(this);
    }

    render() {
        const columns = [
            {header: 'Job ID', width: 1, visible: true, key: 'elogbooksJob.id'},
            {header: 'Error', width: 2, visible: true, key: 'message'},
            {header: 'Site', width: 4, visible: true, key: 'elogbooksJob._links.site.title'},
            {header: 'Site Postcode', width: 1, visible: true, key: 'elogbooksSite.contactInformation.addresses.0.postcode'},
            {header: 'Service Provider', width: 2, visible: true, key: 'elogbooksJob._links.serviceprovider.title'},
            {header: 'Sub Contractor', width: 2, visible: true, key: 'elogbooksJob._links.operative.title'},
        ];
        const collection = this.props.errorCollectionResponse || {};

        return (
            <div className="ErrorList">
                <h1 className="clearfix">
                    Job Errors
                    <ItemCount count={collection.count} />

                    <Reload load={this.load} />
                </h1>
                <Loading isLoading={this.props.isLoading} size="md">
                    <NoResultsFound count={collection.count}>
                        <Table
                            data={collection.errors || []}
                            columns={columns}
                            collection={collection} />

                        <Paginator page={collection.page}
                            count={collection.count}
                            limit={collection.limit}
                            onPage={this.changePage}/>
                    </NoResultsFound>
                </Loading>
            </div>
        );
    }

    componentDidMount() {
        if (typeof this.props.errorCollectionResponse !== 'object' ||
            typeof this.props.errorCollectionResponse.errors === 'undefined') {
            this.load();
        }
    }

    generateToState(error) {
        return '/job-errors/' + error.id;
    }

    changePage(page) {
        Object.assign(this.props.filters, {page: page});
        this.load();
    }

    load() {
        this.props.fetchJobErrors(this.props.filters);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorsList);
