import HTTP from 'services/HTTP';
import { toast } from 'react-toastify';

export const CREATED_PROPERTY = 'CREATED_PROPERTY';
export const CREATING_PROPERTY = 'CREATING_PROPERTY';
export const CREATING_PROPERTY_FAILED = 'CREATING_PROPERTY_FAILED';
export const FETCHED_PROPERTY = 'FETCHED_PROPERTY';
export const FETCHED_PROPERTIES = 'FETCHED_PROPERTIES';
export const FETCHING_PROPERTY = 'FETCHING_PROPERTY';
export const FETCHING_PROPERTIES = 'FETCHING_PROPERTIES';
export const FETCHING_PROPERTY_FAILED = 'FETCHING_PROPERTY_FAILED';
export const UPDATED_PROPERTY = 'UPDATED_PROPERTY';
export const UPDATING_PROPERTY = 'UPDATING_PROPERTY';
export const UPDATING_PROPERTY_FAILED = 'UPDATING_PROPERTY_FAILED';
export const SETTING_PROPERTY_PROCESS_SALES_INVOICES = 'SETTING_PROPERTY_PROCESS_SALES_INVOICES';
export const SETTING_PROPERTY_PROCESS_SALES_INVOICES_FAILED = 'SETTING_PROPERTY_PROCESS_SALES_INVOICES_FAILED';
export const SET_PROPERTY_PROCESS_SALES_INVOICES = 'SET_PROPERTY_PROCESS_SALES_INVOICES';
export const UPDATING_PROPERTY_FILTERS = 'UPDATING_PROPERTY_FILTERS';
export const CLEARING_PROPERTY_FILTERS = 'CLEARING_PROPERTY_FILTERS';

export {
    create,
    fetchProperty,
    fetchProperties,
    update,
    setProcessSalesInvoices,
    updatePropertyFilters,
    clearPropertyFilters,
    hold,
    cancelHold,
};

function hold(id)
{
    return dispatch => {
        return HTTP
            .post(`/properties/${id}/hold`)
            .then(() => {
                dispatch(fetchProperty(id));
                toast.success('Property held');

                return true;
            }).catch(() => {
                toast.error('Unable to hold property');

                return false;
            });
    }
}

function cancelHold(id)
{
    return dispatch => {
        return HTTP
            .post(`/properties/${id}/cancelhold`)
            .then(() => {
                dispatch(fetchProperty(id));
                toast.success('Property hold cancelled');

                return true;
            }).catch(() => {
                toast.error('Unable to cancel property hold');

                return false;
            });
    }
}

function fetchProperties(params = {})
{
    return dispatch => {
        dispatch(fetchingProperties(params));

        return HTTP
            .get('/properties', params)
            .then((response) => {
                dispatch(fetchedProperties(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingPropertyFailed(response.data));

                return false;
            });
    };
}

function create(data = {})
{
    return (dispatch, getState) => {
        dispatch(creatingeProperty(data));

        return HTTP
            .post('/properties/new', data)
            .then((response) => {
                dispatch(createdProperty(response.data));
                dispatch(fetchProperties(getState().user.filters));

                return response.data;
            }, (response) => {
                dispatch(creatingePropertyFailed(response.data));

                return false;
            });
    }
}

function update(id, data = {})
{
    return (dispatch, getState) => {
        dispatch(updatingeProperty(id, data));

        return HTTP
            .put('/properties/' + id, data)
            .then((response) => {
                dispatch(updatedProperty(id, response.data, data));
                dispatch(fetchProperties(getState().user.filters));

                return response.data;
            }, (response) => {
                dispatch(updatingePropertyFailed(id, response.data));

                return false;
            })
    };
}

function setProcessSalesInvoices(id, enable = true)
{
    return (dispatch) => {
        dispatch(settingPropertyProcessSalesInvoices(id));

        var updateMethod = enable ? 'processsalesinvoices' : 'disableprocesssalesinvoices';

        return HTTP
            .put(`/properties/${id}/${updateMethod}`)
            .then((response) => {
                dispatch(setPropertyProcessSalesInvoice(id, response.data));

                return true;
            }, (response) => {
                dispatch(settingPropertyProcessSalesInvoicesFailed(id, response.data));

                return false;
            });
    };
}

function fetchProperty(id)
{
    return dispatch => {
        dispatch(fetchingProperty(id));

        return HTTP
            .get('/properties/' + id)
            .then((response) => {
                dispatch(fetchedProperty(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingPropertyFailed(response.data));

                return false;
            })
    }
}

function updatePropertyFilters(filters, filterData)
{
    return dispatch => {
        dispatch(updatingPropertyFilters(filters, filterData));
    };
}

function clearPropertyFilters()
{
    return dispatch => {
        dispatch(clearingPropertyFilters());
    };
}

function updatingPropertyFilters(filters, filterData)
{
    return {
        type: UPDATING_PROPERTY_FILTERS,
        filters: filters,
        filterData: filterData,
    };
}

function clearingPropertyFilters()
{
    return {
        type: CLEARING_PROPERTY_FILTERS,
    };
}

function fetchingProperties(params = {})
{
    return {
        type: FETCHING_PROPERTIES,
    }
}

function fetchedProperties(response)
{
    return {
        type: FETCHED_PROPERTIES,
        collection: response,
    }
}

function creatingeProperty(data = {})
{
    return {
        type: CREATING_PROPERTY,
        data: data,
    };
}

function createdProperty(response)
{
    return {
        type: CREATED_PROPERTY,
    };
}

function creatingePropertyFailed(response)
{
    return {
        type: CREATING_PROPERTY_FAILED,
    };
}

function updatingeProperty(id, data = {})
{
    return {
        type: UPDATING_PROPERTY,
        id: id,
        data: data,
    };
}

function updatedProperty(id, response, data = {})
{
    return {
        type: UPDATED_PROPERTY,
        id: id,
        response: response,
        data: data,
    };
}

function updatingePropertyFailed(id, response)
{
    return {
        type: UPDATING_PROPERTY_FAILED,
        id: id,
        response: response,
    };
}

function settingPropertyProcessSalesInvoices(id)
{
    return {
        type: SETTING_PROPERTY_PROCESS_SALES_INVOICES,
        id: id,
    };
}

function setPropertyProcessSalesInvoice(id, response)
{
    return {
        type: SET_PROPERTY_PROCESS_SALES_INVOICES,
        id: id,
        response: response,
    };
}

function settingPropertyProcessSalesInvoicesFailed(id, response)
{
    return {
        type: SETTING_PROPERTY_PROCESS_SALES_INVOICES_FAILED,
        id: id,
        response: response,
    };
}

function fetchingProperty(id)
{
    return {
        type: FETCHING_PROPERTY,
        id: id,
    };
}

function fetchingPropertyFailed(response)
{
    return {
        type: FETCHING_PROPERTY_FAILED,
    };
}

function fetchedProperty(response)
{
    return {
        type: FETCHED_PROPERTY,
        response: response,
    };
}
