import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faColumns,
    faCogs,
    faUsers,
    faSignInAlt,
    faSignOutAlt,
    faUserHardHat,
    faUserTie,
    faFileInvoice,
    faSpinnerThird,
    faPlusCircle,
    faSync,
    faBan,
    faBuilding,
    faAward,
    faDebug,
    faToriiGate,
    faFileInvoiceDollar,
    faCashRegister,
    faSortUp,
    faSortDown,
    faSort,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
    faColumns,
    faCogs,
    faUsers,
    faSignInAlt,
    faSignOutAlt,
    faUserHardHat,
    faUserTie,
    faFileInvoice,
    faSpinnerThird,
    faPlusCircle,
    faSync,
    faBan,
    faBuilding,
    faAward,
    faDebug,
    faToriiGate,
    faFileInvoiceDollar,
    faCashRegister,
    faSortUp,
    faSortDown,
    faSort,
);
