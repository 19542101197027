import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchJobs, clearJobFilters, updateJobFilters } from 'actions/job';
import { fetchClients } from 'actions/client';
import { fetchProperties } from 'actions/property';
import { fetchServiceProviders } from 'actions/serviceprovider';
import PropTypes from 'prop-types';
import Table from 'components/elements/Table';
import Loading from 'components/higher-order-components/Loading';
import NoResultsFound from 'components/higher-order-components/NoResultsFound';
import Reload from 'components/elements/Reload';
import Paginator from 'components/elements/Paginator';
import ItemCount from 'components/elements/ItemCount';
import Filters from 'components/elements/Filters';
import lodash from 'lodash';
import FiltersService from 'services/Filters';
import { ButtonToolbar, Badge } from 'react-bootstrap';
import ButtonLink from 'components/elements/ButtonLink';
import HoldBadge from 'components/elements/HoldBadge';
import { holdOptions } from 'constants/hold';

const mapStateToProps = (state) => {
    return {
        clientCollectionResponse: state.client.collection,
        jobCollectionResponse: state.job.collection,
        filters: state.job.filters,
        filterData: state.job.filterData,
        isLoading: state.job.isRequesting,
    };
};

const mapDispatchToProps = {
    fetchJobs: fetchJobs,
    fetchClients: fetchClients,
    fetchProperties: fetchProperties,
    fetchServiceProviders: fetchServiceProviders,
    clearJobFilters: clearJobFilters,
    updateJobFilters: updateJobFilters,
};

class JobsList extends Component
{
    static propTypes = {
        jobCollectionResponse: PropTypes.shape({
            count: PropTypes.number.isRequired,
            jobs: PropTypes.array,
            limit: PropTypes.number.isRequired,
            page: PropTypes.number.isRequired,
            pages: PropTypes.number.isRequired,
        }).isRequired,
        filters: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        jobCollectionResponse: {
            count: 0,
            jobs: undefined,
            limit: 10,
            page: 1,
            pages: 0,
        },
        filters: {},
        isLoading: false,
    };

    constructor(props) {
        super(props);

        this.filterData = FiltersService.updateFilterStateFromUrl(
            this.props.location.search.slice(1),
            this.props.filterData,
            this.props.updateJobFilters
        );

        this.changePage = this.changePage.bind(this);
        this.load = this.load.bind(this);
    }

    render() {
        const { match } = this.props;
        const columns = [
            {header: 'ELB Job ID', width: 1, visible: true, key: 'elogbooksJobId', },
            {header: 'Summary', width: 2, visible: true, key: 'summary', },
            {header: 'Client', width: 2, visible: true, key: '_links.client.title', },
            {header: 'Service Provider', width: 2, visible: true, key: '_links.serviceprovider.title' },
            {header: 'Held By', width: 1, visible: true, key: '_links.held-by-user.name', modifier: this.modifyHoldLabel },
            {header: '', width: 1, visible: true, type: 'button', link: 'self', text: 'View', toState: this.generateToState, fixedRight: true, },
        ];
        const collection = this.props.jobCollectionResponse || {};

        const isCisOptions = [
            {
                id: '~',
                label: 'Not Set'
            },
            {
                id: 0,
                label: 'No'
            },
            {
                id: 1,
                label: 'Yes'
            },
        ];

        return (
            <div className="JobsList">
                <h1 className="clearfix">
                    Jobs
                    <ItemCount count={collection.count} />

                    <Reload load={this.load} />

                    <ButtonToolbar className="float-right">
                        <ButtonLink size="sm" to={`${match.path}/fetch`}>
                            Fetch Job
                        </ButtonLink>
                    </ButtonToolbar>
                </h1>

                <Filters onFilter={this.props.updateJobFilters}
                    onClear={this.props.clearJobFilters}
                    filterData={this.filterData}>
                    <Filters.SingleSelect action={this.props.fetchClients}
                        label="Client"
                        filterKey="client"
                        responseKey="clients" />
                    <Filters.SingleSelect action={this.props.fetchProperties}
                        label="Property"
                        filterKey="property"
                        responseKey="properties" />
                    <Filters.SingleSelect action={this.props.fetchServiceProviders}
                        label="Service Provider"
                        filterKey="serviceProvider"
                        responseKey="serviceProviders" />
                    <Filters.Text label="Exchequer PIN"
                        filterKey="exchequerPin" />
                    <Filters.Text label="Exchequer SIN"
                        filterKey="exchequerSin" />
                    <Filters.SimpleSelect label="Is CIS"
                        filterKey="isCis"
                        options={isCisOptions}
                        multiple={true} />
                    <Filters.SimpleSelect label="On Hold"
                        filterKey="isOnHold"
                        options={holdOptions}
                        multiple={true} />
                    <Filters.Text label="Elogbooks Job ID"
                        filterKey="elogbooksJobId" />
                </Filters>

                <Loading isLoading={this.props.isLoading} size="md">
                    <NoResultsFound count={collection.count}>
                        <Table
                            data={collection.jobs || []}
                            columns={columns}
                            collection={collection} />

                        <Paginator page={collection.page}
                            count={collection.count}
                            limit={collection.limit}
                            onPage={this.changePage}/>
                    </NoResultsFound>
                </Loading>
            </div>
        );
    }

    componentDidMount() {
        if (typeof this.props.jobCollectionResponse !== 'object' ||
            typeof this.props.jobCollectionResponse.jobs === 'undefined') {
            this.load();
        }

        this.loadClients();
    }

    componentDidUpdate(prevProps) {
        this.filterData = this.props.filterData;

        if (!lodash.isEqual(prevProps.filters, this.props.filters)) {
            this.load();
        }
    }

    generateToState(job) {
        return '/jobs/' + job.id;
    }

    modifyHoldLabel = (value, index) => {
        const heldByUser = lodash.get(this.props.jobCollectionResponse.jobs[index], '_links.held-by-user', null);

        if (!heldByUser) {
            return '-';
        }

        return <HoldBadge user={heldByUser} />
    };

    changePage(page) {
        Object.assign(this.props.filters, {page: page});
        this.load();
    }

    load() {
        this.props.fetchJobs(this.props.filters);
    }

    loadClients() {
        this.props.fetchClients();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobsList);
