import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    TOKEN_VALID,
    TOKEN_INVALID,
    LOGOUT,
} from 'actions/auth';

const defaultState = {
    isAuthenticated: false,
    token: undefined,
    user: {},
};

export default function auth(state = defaultState, action)
{
    switch (action.type) {
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isRequesting: action.isRequesting,
                isAuthenticated: action.isAuthenticated,
                token: action.token,
                user: action.user,
            });

        case LOGIN_FAILURE:
            return Object.assign({}, state, {
                isRequesting: action.isRequesting,
                isAuthenticated: action.isAuthenticated,
                token: undefined,
            });

        case TOKEN_VALID:
            return Object.assign({}, state, {
                token: action.token,
            });

        case LOGOUT:
        case TOKEN_INVALID:
            return Object.assign({}, defaultState);

        default:
            return Object.assign({}, state);
    }
}
