import HTTP from 'services/HTTP';
import { toast } from 'react-toastify';

export const FETCHING_SALES_INVOICE = 'FETCHING_SALES_INVOICE';
export const FETCHED_SALES_INVOICE = 'FETCHED_SALES_INVOICE';
export const FETCHING_SALES_INVOICE_FAILED = 'FETCHING_SALES_INVOICE_FAILED';
export const FETCHING_SALES_INVOICES = 'FETCHING_SALES_INVOICES';
export const FETCHED_SALES_INVOICES = 'FETCHED_SALES_INVOICES';
export const FETCHING_SALES_INVOICES_FAILED = 'FETCHING_SALES_INVOICES_FAILED';
export const UPDATING_SALES_INVOICE_FILTERS = 'UPDATING_SALES_INVOICE_FILTERS';
export const CLEARING_SALES_INVOICE_FILTERS = 'CLEARING_SALES_INVOICE_FILTERS';

export {
    fetchSalesInvoices,
    fetchSalesInvoice,
    updateSalesInvoiceFilters,
    clearSalesInvoiceFilters,
    creditSalesInvoice,
    approveSalesInvoice,
}

function approveSalesInvoice(id)
{
    return dispatch => {
        return HTTP
            .post(`/salesinvoices/${id}/approve`)
            .then((response) => {
                dispatch(fetchSalesInvoice(id));
                toast.success('Sales invoice approved');

                return true;
            }).catch((error) => {
                return false;
            });
    };
}

function creditSalesInvoice(id, params)
{
    return dispatch => {
        return HTTP
            .post(`/salesinvoices/${id}/credit`, params)
            .then((response) => {
                dispatch(fetchSalesInvoice(id));
                toast.success('Sales invoice credited');

                return true;
            }).catch((error) => {
                error.response.data.forEach((error) => {
                    toast.error(`${error.property_path} - ${error.message}`);
                });
                
                return false;
            });
    };
}

function fetchSalesInvoice(id)
{
    return dispatch => {
        dispatch(fetchingSalesInvoice(id));

        return HTTP
            .get(`/salesinvoices/${id}`)
            .then((response) => {
                dispatch(fetchedSalesInvoice(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingSalesInvoiceFailed(response.data));

                return false;
            });
    };
}

function fetchSalesInvoices(params = {})
{
    return dispatch => {
        dispatch(fetchingSalesInvoices(params));

        return HTTP
            .get('/salesinvoices', params)
            .then((response) => {
                dispatch(fetchedSalesInvoices(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingSalesInvoicesFailed(response.data));

                return false;
            });
    };
}

function updateSalesInvoiceFilters(filters, filterData)
{
    return dispatch => {
        dispatch(updatingSalesInvoiceFilters(filters, filterData));
    };
}

function clearSalesInvoiceFilters(filters, filterData)
{
    return dispatch => {
        dispatch(clearingSalesInvoiceFilters(filters, filterData));
    };
}

function fetchingSalesInvoice(id)
{
    return {
        type: FETCHING_SALES_INVOICE,
        id: id,
    };
}

function fetchedSalesInvoice(response)
{
    return {
        type: FETCHED_SALES_INVOICE,
        response: response,
    };
}

function fetchingSalesInvoiceFailed(response)
{
    return {
        type: FETCHING_SALES_INVOICE_FAILED,
    };
}

function fetchingSalesInvoices(params = {})
{
    return {
        type: FETCHING_SALES_INVOICES,
    };
}

function fetchedSalesInvoices(response)
{
    return {
        type: FETCHED_SALES_INVOICES,
        collection: response,
    };
}

function fetchingSalesInvoicesFailed(response)
{
    return {
        type: FETCHING_SALES_INVOICES_FAILED,
    };
}

function updatingSalesInvoiceFilters(filters, filterData)
{
    return {
        type: UPDATING_SALES_INVOICE_FILTERS,
        filters: filters,
        filterData: filterData,
    };
}

function clearingSalesInvoiceFilters()
{
    return {
        type: CLEARING_SALES_INVOICE_FILTERS,
    };
}
