const STATE_DRAFT = 0;
const STATE_PROCESSED = 1;
const STATE_INVOICED = 2;
const STATE_ON_HOLD = 3;
const STATE_CREDITED = 4;
const STATE_AUTHORISED = 5;
const STATE_ON_SIN = 6;

const states = {
    [STATE_DRAFT]: 'Draft',
    [STATE_PROCESSED]: 'Processed',
    [STATE_INVOICED]: 'Pending SIN',
    [STATE_ON_HOLD]: 'On Hold',
    [STATE_CREDITED]: 'Credited',
    [STATE_AUTHORISED]: 'Authorised',
    [STATE_ON_SIN]: 'On SIN',
};

const stateOptions = [
    {
        id: STATE_DRAFT,
        label: states[STATE_DRAFT],
    },
    {
        id: STATE_PROCESSED,
        label: states[STATE_PROCESSED],
    },
    {
        id: STATE_INVOICED,
        label: states[STATE_INVOICED],
    },
    {
        id: STATE_ON_HOLD,
        label: states[STATE_ON_HOLD],
    },
    {
        id: STATE_CREDITED,
        label: states[STATE_CREDITED],
    },
    {
        id: STATE_AUTHORISED,
        label: states[STATE_AUTHORISED],
    },
    {
        id: STATE_ON_SIN,
        label: states[STATE_ON_SIN],
    },
];

export {
    STATE_DRAFT,
    STATE_PROCESSED,
    STATE_INVOICED,
    STATE_ON_HOLD,
    STATE_CREDITED,
    STATE_AUTHORISED,
    STATE_ON_SIN,
    states,
    stateOptions,
};

