import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from 'components/elements/Table';
import Loading from 'components/higher-order-components/Loading';
import NoResultsFound from 'components/higher-order-components/NoResultsFound';
import Reload from 'components/elements/Reload';
import Paginator from 'components/elements/Paginator';
import ItemCount from 'components/elements/ItemCount';
import Filters from 'components/elements/Filters';
import { fetchClients } from 'actions/client';
import { Alert } from 'react-bootstrap';
import { updateElogbooksPropertyFilters, clearElogbooksPropertyFilters, fetchElogbooksProperties } from 'actions/elogbooks/property';
import lodash from 'lodash';
import FiltersService from 'services/Filters';

const mapStateToProps = (state) => {
    return {
        clientCollectionResponse: state.client.collection,
        filters: state.elogbooksProperty.filters,
        filterData: state.elogbooksProperty.filterData,
        propertiesCollection: state.elogbooksProperty.collection,
        isRequesting: state.elogbooksProperty.isRequesting,
    };
};

const mapDispatchToProps = {
    fetchClients: fetchClients,
    fetchElogbooksProperties: fetchElogbooksProperties,
    updateElogbooksPropertyFilters: updateElogbooksPropertyFilters,
    clearElogbooksPropertyFilters: clearElogbooksPropertyFilters,
};

class ElogbooksPropertiesList extends Component
{
    constructor(props) {
        super(props);

        this.filterData = FiltersService.updateFilterStateFromUrl(
            this.props.location.search.slice(1),
            this.props.filterData,
            this.props.updateElogbooksPropertyFilters
        );
    }

    render() {
        const columns = [
            {header: 'Site Name', width: 4, visible: true, key: 'name', },
            {header: 'External Reference', width: 4, visible: true, key: 'externalReference', },
            {header: 'Exchequer Reference', width: 4, visible: true, key: 'middlewareData.exchequerReference', },
            {header: 'Property Status', width: 4, visible: true, type: 'active', key: 'middlewareData.active', },
            {header: 'Inactive At', width: 4, visible: true, key: 'inactiveAt', type: 'datetime', },
            {
                header: '',
                width: 2,
                visible: true,
                type: 'button',
                disabled: (value) => {return !value.middlewareData || !value.middlewareData.active},
                link: 'self',
                text: 'View',
                toState: this.generateToState,
                fixedRight: true,
            },
        ];
        const collection = this.props.propertiesCollection;

        return (
            <div className="ElogbooksPropertiesList">
                <h1 className="clearfix">
                    Elogbooks Properties
                    <ItemCount count={collection.count}/>

                    <Reload load={() => {this.load()}} />
                </h1>

                <Filters onFilter={this.props.updateElogbooksPropertyFilters}
                    onClear={this.props.clearElogbooksPropertyFilters}
                    filterData={this.filterData} open>
                    <Filters.SingleSelect action={this.props.fetchClients}
                        label="Client"
                        filterKey="client"
                        responseKey="clients" />
                    <Filters.DateRange label="Inactive At"
                        filterKey="inactiveAtBetween" />
                </Filters>

                {this.filterData.client ? (
                    <Loading isLoading={this.props.isRequesting} size="md">
                        <NoResultsFound count={collection.count}>
                            <Table data={collection.sites || []}
                                columns={columns}
                                collection={collection} />

                            <Paginator page={parseInt(collection.page)}
                                count={parseInt(collection.count)}
                                limit={parseInt(collection.limit)}
                                onPage={(page) => {this.changePage(page)}} />
                        </NoResultsFound>
                    </Loading>
                ) : (
                    <Alert variant="info">Select a client to load properties from Elogbooks</Alert>
                )}
            </div>
        )
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(prevProps) {
        if (!lodash.isEqual(prevProps.filters, this.props.filters)) {
            this.filterData = this.props.filterData;

            this.load();
        }
    }

    changePage(page) {
        Object.assign(this.props.filters, {page: page});
        this.load();
    }

    generateToState(property) {
        return `/properties/${property.middlewareData.id}`;
    }

    load() {
        if (typeof this.filterData.client !== 'undefined') {
            this.props.fetchElogbooksProperties(this.filterData.client.value, this.props.filters);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ElogbooksPropertiesList);