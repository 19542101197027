import React, { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import PropTypes from 'prop-types';
import lodash from 'lodash';

class CollectionSelect extends Component
{
    static propTypes = {
        collection: PropTypes.shape({
            count: PropTypes.number.isRequired,
            page: PropTypes.number.isRequired,
            pages: PropTypes.number.isRequired,
            limit: PropTypes.number.isRequired,
        }),
        collectionKey: PropTypes.string.isRequired,
        idKey: PropTypes.string,
        isLoading: PropTypes.bool,
        labelKey: PropTypes.string,
    };

    static defaultProps = {
        collection: {
            count: 0,
            page: 1,
            pages: 0,
            limit: 0
        },
        idKey: 'id',
        isLoading: false,
        labelKey: 'name',
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: props.isLoading,
            typeaheadId: Math.ceil(Math.random() * 999999) + '-' + Math.ceil(Math.random() * 999999),
        };

        this.state.selected = this.getSelected();

        this.getOptions = this.getOptions.bind(this);
        this.getSelected = this.getSelected.bind(this);
    }

    render() {
        const options = this.getOptions();

        return (
            <div className="CollectionSelect">
                <Typeahead
                    id={this.state.typeaheadId}
                    options={options}
                    isLoading={this.props.isLoading}
                    defaultSelected={this.state.selected}
                    onChange={(items) => {
                        if (this.props.multiple) {
                            return this.props.callback(items);
                        }

                        return this.props.callback(items[0] || {});
                    }}
                    selectHintOnEnter={true} />
            </div>
        );
    }

    getOptions() {
        const {
            collection,
            collectionKey,
            labelKey,
            idKey,
        } = this.props;

        let items = lodash.get(collection, collectionKey) || [],
            options = [];

        items.forEach((item) => {
            options.push({
                id: lodash.get(item, idKey),
                label: lodash.get(item, labelKey),
            });
        });

        return options;
    }

    getSelected() {
        let items = [],
            options = this.getOptions();

        (this.props.defaultSelected || []).forEach((id) => {
            let selectedObject = lodash.find(options, {id: id}),
                selectedItem = lodash.get(selectedObject, 'label');

            if (selectedItem) {
                items.push(selectedItem);
            }
        });

        return items;
    }

    componentDidMount() {
        // ...
    }

    load() {
        // ...
    }
}

export default CollectionSelect;
