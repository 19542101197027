import React, { Component } from 'react';
import DateRangePickerComponent from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

class DateRangePicker extends Component
{
    static propTypes = {
        startDate: PropTypes.instanceOf(DateTime),
        endDate: PropTypes.instanceOf(DateTime),
    };

    constructor(props) {
        super(props);

        this.state = {
            value: '',
        };

        if (this.props.startDate && this.props.endDate) {
            this.state = {
                value: DateRangePicker.formatValue(this.props.startDate, this.props.endDate),
                startDate: this.props.startDate.toJSDate(),
                endDate: this.props.endDate.toJSDate(),
            };
        }
    }

    render() {
        return (
            <DateRangePickerComponent onApply={this.handleApply}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                className="w-100">
                <input type="text"
                    className="readonly-normal form-control w-100"
                    value={this.state.value} readOnly />
            </DateRangePickerComponent>
        );
    }

    handleApply = (event, component) => {
        const startDate = DateTime.fromISO(component.startDate.toISOString());
        const endDate = DateTime.fromISO(component.endDate.toISOString());

        this.setState({
            value: DateRangePicker.formatValue(startDate, endDate),
        });

        this.props.onApply(startDate, endDate);
    };

    static formatValue(startDate, endDate) {
        return `${startDate.toLocaleString()} - ${endDate.toLocaleString()}`;
    }
}

export default DateRangePicker;
