import { combineReducers } from 'redux';

import auth from 'reducers/auth';
import client from 'reducers/client';
import dashboard from 'reducers/dashboard';
import elogbooksJob from 'reducers/elogbooks/job';
import job from 'reducers/job';
import joberror from 'reducers/joberror';
import property from 'reducers/property';
import serviceProvider from 'reducers/serviceprovider';
import sidebar from 'reducers/sidebar';
import user from 'reducers/user';
import elogbooksProperty from 'reducers/elogbooks/property';
import purchaseInvoice from 'reducers/purchaseinvoice';
import salesInvoice from 'reducers/salesinvoice';

export default combineReducers({
    auth,
    client,
    dashboard,
    elogbooksJob,
    job,
    joberror,
    property,
    serviceProvider,
    sidebar,
    user,
    elogbooksProperty,
    purchaseInvoice,
    salesInvoice,
});
