import HTTP from 'services/HTTP';

export {
    fetchJobByClient,
};

function fetchJobByClient(client, elogbooksJobId)
{
    return HTTP
        .get(`/clients/${client}/elogbooks/job/${elogbooksJobId}`)
        .then((response) => {
            return response.data;

        }).catch((error) => {
            console.log(error);

            return false;
        });
}
