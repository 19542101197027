import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, FormControl, FormLabel, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'components/elements/Table';
import NoResultsFound from 'components/higher-order-components/NoResultsFound';
import CollectionSelect from 'components/elements/CollectionSelect';
import { fetchElogbooksJob } from 'actions/job';

const mapStateToProps = (state, ownProps) => {
    return {
        clientCollectionResponse: state.client.collection,
    };
};

const mapDispatchToProps = {
    fetchElogbooksJob: fetchElogbooksJob,
};

const fetchStatus = {
    pending: 'Pending',
    processing: 'Processing',
    complete: 'Complete',
    error: 'Error',
};

class JobFetch extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            elogbooksJobId: '',
            clientId: null,
            clientName: null,
            fetchJobs: [],
            fetching: false,
        };
    }

    render() {
        const columns = [
            {header: 'Elogbooks Job ID', width: 2, visible: true, key: 'jobId'},
            {header: 'Client', width: 2, visible: true, key: 'clientName'},
            {header: 'Fetch Status', width: 4, visible: true, key: 'status'},
            {header: '', width: 1, visible: true, type: 'button', text: 'Remove', onClick: this.handleRemove, fixedRight: true},
        ];
        const clientCollection = this.props.clientCollectionResponse;
        const { history } = this.props;

        return (
            <div className="JobFetch">
                <h1>Fetch Job</h1>

                <Alert variant="info">
                    Elogbooks jobs must have an approved value to be pulled into the middleware. Existing jobs can only be updated if the CIS status has not been selected.
                </Alert>

                <form ref="form"
                    className="clearfix"
                    onSubmit={e => this.handleSubmit(e)}>
                    <FormGroup controlId="client" bssize="large">
                        <FormLabel>Client</FormLabel>
                        <CollectionSelect
                            collection={clientCollection}
                            collectionKey="clients"
                            labelKey="name"
                            idKey="id"
                            callback={(response) => {
                                if (response.id) {
                                    return this.setState({
                                        'clientId': response.id,
                                        'clientName': response.label,
                                    });
                                }

                                return this.setState({'clientId': undefined});
                            }}/>
                    </FormGroup>

                    <FormGroup controlId="elogbooksJobId">
                        <FormLabel>Elogbooks Job ID</FormLabel>
                        <FormControl as="input"
                            ref="elogbooksJobId"
                            value={this.state.elogbooksJobId}
                            onChange={e => { this.setState({'elogbooksJobId': e.target.value}) }} />
                    </FormGroup>

                    <div className="float-right">
                        <Button onClick={() => history.push('/jobs/')}
                            variant="default"
                            bssize="large"
                            to={'/jobs/'}>
                            <FontAwesomeIcon icon={['fas', 'ban']} />
                            Cancel
                        </Button>

                        <Button type="submit"
                            disabled={!this.validateForm() || this.state.fetching}>
                            Add
                        </Button>
                    </div>
                </form>

                <div className="mt-3 clearfix">
                    <NoResultsFound count={this.state.fetchJobs.length} label="No pending jobs">
                        <Table data={this.state.fetchJobs}
                            columns={columns} />

                        <div className="float-right">
                            <Button className="mt-3"
                                onClick={e => this.handleFetch(e)}
                                disabled={this.state.fetching}>
                                Fetch
                            </Button>
                        </div>
                    </NoResultsFound>
                </div>
            </div>
        );
    }

    validateForm() {
        return (this.state.clientId && this.state.elogbooksJobId);
    }

    handleRemove = (index) => {
        this.setState({
            fetchJobs: this.state.fetchJobs.filter((_, i) => i !== index),
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({
            fetchJobs: [...this.state.fetchJobs, {
                jobId: this.state.elogbooksJobId,
                clientId: this.state.clientId,
                clientName: this.state.clientName,
                status: fetchStatus.pending,
            }],
            elogbooksJobId: '',
        });
    };

    handleFetch = (event) => {
        event.preventDefault();

        this.setState({fetching: true});

        const updateTable = (index, update) => {
            let fetchJobs = [...this.state.fetchJobs];
            fetchJobs[index] = Object.assign({}, fetchJobs[index], update);

            this.setState({fetchJobs: fetchJobs});
        };

        this.state.fetchJobs.reduce(async (previousPromise, job, index) => {
            await previousPromise;

            updateTable(index, {status: fetchStatus.processing});

            return this.props.fetchElogbooksJob({
                elogbooksJobId: job.jobId,
                clientId: job.clientId,
            }).then(() => {
                updateTable(index, {status: fetchStatus.complete});
            }).catch((error) => {
                updateTable(index, {
                    status: `${fetchStatus.error} - ${error.response.data.message}`,
                });
            });
        }, Promise.resolve()).then(() => {
            this.setState({
                fetching: false,
            });
        });
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(JobFetch);
