import HTTP from 'services/HTTP';

export {
    fetchServiceProviders,
};

function fetchServiceProviders(params = {})
{
    return HTTP
        .get('/serviceproviders', params)
        .then((response) => {
            return response.data;
        }).catch(() => {
            return false;
        });
}
