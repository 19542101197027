import HTTP from 'services/HTTP';

export {
    fetchPurchaseInvoices,
};

function fetchPurchaseInvoices(params = {})
{
    return HTTP
        .get('/purchaseinvoices', params)
        .then((response) => {
            return response.data;
        }).catch(() => {
            return false;
        });
}
