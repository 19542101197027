import axios from 'axios';
import Storage from 'services/Storage';

class HTTP
{
    static getHeaders(headers = {}) {
        if (!headers.Authorization) {
            let token = Storage.load('auth.token');
            if (token) {
                headers.Authorization = 'Bearer ' + token;
            }
        }

        return headers;
    }

    static get(url, params, headers = {}) {
        params = HTTP.convertParamsToString(params);

        return axios
            .get(HTTP.getUrl(url) + '?' + params, {
                headers: HTTP.getHeaders(headers),
                validateStatus: (status) => {
                    return [200].indexOf(status) !== -1;
                },
            });
    }

    static post(url, data, headers = {}) {
        return axios
            .post(HTTP.getUrl(url), data, {
                headers: HTTP.getHeaders(headers),
                validateStatus: (status) => {
                    return [200, 201, 202, 204].indexOf(status) !== -1;
                },
            });
    }

    static put(url, data, headers = {}) {
        return axios
            .put(HTTP.getUrl(url), data, {
                headers: HTTP.getHeaders(headers),
                validateStatus: (status) => {
                    return [204].indexOf(status) !== -1;
                },
            });
    }

    static patch(url, data, headers = {}) {
        return axios
            .patch(HTTP.getUrl(url), data, {
                headers: HTTP.getHeaders(headers),
                validateStatus: (status) => {
                    return [204].indexOf(status) !== -1;
                },
            });
    }

    static delete(url, headers = {}) {
        return axios
            .delete(HTTP.getUrl(url), {
                headers: HTTP.getHeaders(headers),
                validateStatus: (status) => {
                    return [200, 202, 204].indexOf(status) !== -1;
                },
            });
    }

    static getUrl(url) {
        return process.env.REACT_APP_BASE_URL + url;
    }

    static convertParamsToString(params = {}, prefix)
    {
        var result = [],
            property;

        for (property in params) {
            if (params.hasOwnProperty(property)) {
                var key = prefix ? prefix + '[' + property + ']' : property,
                    value = params[property],
                    toPush = (
                        (value !== null && typeof value === 'object') ?
                        HTTP.convertParamsToString(value, key) :
                        encodeURIComponent(key) + '=' + encodeURIComponent(value)
                    );

                result.push(toPush);
            }
        }

        return result.join('&');
    }
}

export default HTTP;
