import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchProperties, updatePropertyFilters, clearPropertyFilters } from 'actions/property';
import { fetchClients } from 'actions/client';
import PropTypes from 'prop-types';
import Table from 'components/elements/Table';
import Loading from 'components/higher-order-components/Loading';
import NoResultsFound from 'components/higher-order-components/NoResultsFound';
import ButtonLink from 'components/elements/ButtonLink';
import Reload from 'components/elements/Reload';
import Paginator from 'components/elements/Paginator';
import ItemCount from 'components/elements/ItemCount';
import JWT from 'services/JWT';
import Filters from 'components/elements/Filters';
import FiltersService from 'services/Filters';
import lodash from 'lodash';
import { activeOptions } from 'constants/active';
import HoldBadge from 'components/elements/HoldBadge';

const mapStateToProps = (state) => {
    return {
        propertyCollectionResponse: state.property.collection,
        filters: state.property.filters,
        filterData: state.property.filterData,
        isLoading: state.property.isRequesting,
    };
};

const mapDispatchToProps = {
    fetchProperties: fetchProperties,
    updatePropertyFilters: updatePropertyFilters,
    clearPropertyFilters: clearPropertyFilters,
    fetchClients: fetchClients,
};

class PropertiesList extends Component
{
    static propTypes = {
        propertyCollectionResponse: PropTypes.shape({
            count: PropTypes.number.isRequired,
            properties: PropTypes.array,
            limit: PropTypes.number.isRequired,
            page: PropTypes.number.isRequired,
            pages: PropTypes.number.isRequired,
        }).isRequired,
        filters: PropTypes.object.isRequired,
        isLoading: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        propertyCollectionResponse: {
            count: 0,
            properties: undefined,
            limit: 10,
            page: 1,
            pages: 0,
        },
        filters: {},
        isLoading: false,
    };

    constructor(props) {
        super(props);

        this.filterData = FiltersService.updateFilterStateFromUrl(
            this.props.location.search.slice(1),
            this.props.filterData,
            this.props.updatePropertyFilters
        );

        this.changePage = this.changePage.bind(this);
        this.load = this.load.bind(this);
    }

    render() {
        const { match } = this.props;
        const columns = [
            {header: 'Name', width: 6, visible: true, key: 'name', },
            {header: 'Held By', width: 2, visible: true, key: '_links.held-by-user.name', modifier: this.modifyHoldLabel },
            {header: '', width: 1, visible: true, type: 'button', link: 'self', text: 'View', toState: this.generateToState, fixedRight: true, },
        ];
        const collection = this.props.propertyCollectionResponse || {};

        const isOnHoldOptions = [
            {
                id: 0,
                label: 'No',
            },
            {
                id: 1,
                label: 'Yes',
            },
        ];

        return (
            <div className="PropertiesList">
                <h1 className="clearfix">
                    Properties
                    <ItemCount count={collection.count} />

                    <Reload load={this.load} />

                    { JWT.hasRole('ROLE_ADMINISTRATOR', 'ROLE_ADMINISTRATOR_CLIENTS') === false ? null :
                        <ButtonLink size="sm" className="float-right" to={`${match.path}/add`}>
                            Add a New Property
                        </ButtonLink>
                    }
                </h1>

                <Filters onFilter={this.props.updatePropertyFilters}
                    onClear={this.props.clearPropertyFilters}
                    filterData={this.filterData}>
                    <Filters.SingleSelect action={this.props.fetchClients}
                        label="Client"
                        filterKey="client"
                        responseKey="clients" />
                    <Filters.Text label="Name"
                        filterKey="name" />
                    <Filters.SimpleSelect label="Is Active"
                        filterKey="isActive"
                        options={activeOptions}
                        multiple={true} />
                    <Filters.SimpleSelect label="On Hold"
                        filterKey="isOnHold"
                        options={isOnHoldOptions}
                        multiple={true} />
                </Filters>

                <Loading isLoading={this.props.isLoading} size="md">
                    <NoResultsFound count={collection.count}>
                        <Table
                            data={collection.properties || []}
                            columns={columns}
                            collection={collection} />

                        <Paginator page={collection.page}
                            count={collection.count}
                            limit={collection.limit}
                            onPage={this.changePage}/>
                    </NoResultsFound>
                </Loading>
            </div>
        );
    }

    componentDidMount() {
        if (typeof this.props.propertyCollectionResponse !== 'object' ||
            typeof this.props.propertyCollectionResponse.properties === 'undefined') {
            this.load();
        }
    }

    componentDidUpdate(prevProps) {
        this.filterData = this.props.filterData;

        if (!lodash.isEqual(prevProps.filters, this.props.filters)) {
            this.load();
        }
    }

    generateToState(property) {
        return '/properties/' + property.id;
    }

    modifyHoldLabel = (value, index) => {
        const heldByUser = lodash.get(this.props.propertyCollectionResponse.properties[index], '_links.held-by-user', null);

        if (!heldByUser) {
            return '-';
        }

        return <HoldBadge user={heldByUser} />
    };

    changePage(page) {
        Object.assign(this.props.filters, {page: page});
        this.load();
    }

    load() {
        this.props.fetchProperties(this.props.filters);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesList);
