import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from 'components/elements/Table';
import Loading from 'components/higher-order-components/Loading';
import NoResultsFound from 'components/higher-order-components/NoResultsFound';
import Reload from 'components/elements/Reload';
import Paginator from 'components/elements/Paginator';
import ItemCount from 'components/elements/ItemCount';
import Elogbooks from 'services/Elogbooks';
import Filters from 'components/elements/Filters';
import lodash from 'lodash';
import { fetchServiceProviders } from 'actions/serviceprovider';
import { fetchClient } from 'actions/client';
import {
    fetchOpenJobsByClient,
    fetchCompletedPendingValueJobsByClient,
    fetchCompletedRejectedValueJobsByClient,
    fetchCompletedPendingValueApprovalJobsByClient,
    updatePendingJobFilters,
    clearPendingJobFilters,
    updatePendingJobOrdering,
} from 'actions/elogbooks/job';
import FiltersService from 'services/Filters';
import { DateTime } from 'luxon';
import { Button, ButtonToolbar, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import JWT from 'services/JWT';
import PromptDialog from 'components/elements/PromptDialog';
import { batchCreate } from 'services/data/Note';

const mapStateToProps = (state, ownProps) => {
    return {
        filters: state.elogbooksJob.filters,
        filterData: state.elogbooksJob.filterData,
        elogbooksJob: state.elogbooksJob.data,
        clientResponse: state.client.clients[ownProps.match.params.clientId],
        ordering: state.elogbooksJob.ordering,
    };
};

const mapDispatchToProps = {
    fetchOpenJobsByClient: fetchOpenJobsByClient,
    fetchCompletedPendingValueJobsByClient: fetchCompletedPendingValueJobsByClient,
    fetchCompletedRejectedValueJobsByClient: fetchCompletedRejectedValueJobsByClient,
    fetchCompletedPendingValueApprovalJobsByClient: fetchCompletedPendingValueApprovalJobsByClient,
    fetchClient: fetchClient,
    fetchServiceProviders: fetchServiceProviders,
    updatePendingJobFilters: updatePendingJobFilters,
    clearPendingJobFilters: clearPendingJobFilters,
    updatePendingJobOrdering: updatePendingJobOrdering,
};

class PendingJobsList extends Component
{
    constructor(props) {
        super(props);

        this.filterData = FiltersService.updateFilterStateFromUrl(
            this.props.location.search.slice(1),
            this.props.filterData,
            this.props.updatePendingJobFilters,
        );

        const types = {
            'open': {
                title: 'Open',
                collectionKey: 'open',
                action: props.fetchOpenJobsByClient,
            },
            'pending-value': {
                title: 'Completed, Pending Value',
                collectionKey: 'pendingvalue',
                action: props.fetchCompletedPendingValueJobsByClient,
            },
            'rejected-value': {
                title: 'Completed, Rejected Value',
                collectionKey: 'rejected',
                action: props.fetchCompletedRejectedValueJobsByClient,
            },
            'pending-approval': {
                title: 'Completed, Pending Value Approval',
                collectionKey: 'pendingapproval',
                action: props.fetchCompletedPendingValueApprovalJobsByClient,
            },
        };

        this.type = types[props.match.params.type];
        this.clientId = props.match.params.clientId;

        this.state = {
            loading: false,
            selectedJobs: {},
            showAddNoteDialog: false,
            newNote: '',
        };
    }

    render() {
        let approverNameSortDirection = null;
        if (this.props.ordering === '-approverNameSort') {
            approverNameSortDirection = 'desc';
        } else if (this.props.ordering === 'approverNameSort') {
            approverNameSortDirection = 'asc';
        }

        const columns = [
            {header: 'Note', width: 0.5, visible: true, type: 'checkbox', },
            {header: 'ELB Job ID', width: 1, visible: true, key: 'id', },
            {header: 'Service Provider', width: 2, visible: true, key: '_links.operative.title', },
            {header: 'Site Name', width: 2, visible: true, key: '_links.site.title', },
            {header: 'Approver', width: 2, visible: true, key: '_links.current-approver.title', sortable: true, defaultSort: approverNameSortDirection, sortKey: 'approverNameSort', },
            {header: 'Completed At', type: 'datetime', width: 2, visible: true, key: 'completedAt', },
            {header: 'Invoice Date', type: 'text', width: 2, visible: true, key: 'attributes', modifier: this.getInvoiceDate },
            {header: '', width: 1, visible: true, type: 'button', link: 'self', text: 'View', toState: this.generateToState, fixedRight: true, },
        ];

        const collection = this.props.elogbooksJob[this.clientId][this.type.collectionKey].collection || {},
            client = this.props.clientResponse || {};

        const addNoteLabel = this.getAddNoteLabel();

        return (
            <div className="PendingJobsList">
                <Loading isLoading={this.state.loading} size="md">
                    <h1 className="clearfix">
                        {`${client.name} Pending Jobs (${this.type.title})`}
                        <ItemCount count={collection.count} />

                        <Reload load={this.load} />

                        { JWT.hasRole('ROLE_ADMINISTRATOR') === false ? null :
                            <ButtonToolbar className="float-right">
                                <Button size="sm"
                                    disabled={!Object.keys(this.state.selectedJobs).length}
                                    onClick={() => this.handleClearJobSelection()}>
                                    Clear Job Selection
                                </Button>
                                <Button size="sm"
                                    className="ml-2"
                                    disabled={!Object.keys(this.state.selectedJobs).length}
                                    onClick={() => this.setState({showAddNoteDialog: true})}>
                                    {addNoteLabel}
                                </Button>
                            </ButtonToolbar>
                        }

                        <PromptDialog show={this.state.showAddNoteDialog}
                            hide={() => this.setState({showAddNoteDialog: false})}
                            validateForm={this.validateAddNoteForm}
                            onSubmit={this.handleAddNote}
                            title={addNoteLabel}>
                            <FormGroup controlId="note">
                                <FormLabel>Write a note...</FormLabel>
                                <FormControl as="textarea"
                                    rows="4"
                                    maxLength="3000"
                                    value={this.state.newNote}
                                    onChange={e => this.setState({newNote: e.target.value})} />
                            </FormGroup>
                        </PromptDialog>
                    </h1>

                    <Filters onFilter={this.props.updatePendingJobFilters}
                        onClear={this.props.clearPendingJobFilters}
                        filterData={this.filterData}>
                        <Filters.SingleSelect action={this.props.fetchServiceProviders}
                            label="Service Provider"
                            filterKey="operative"
                            responseKey="serviceProviders"
                            idKey="elogbooksUserId" />
                        <Filters.DateRange label="Completed At"
                            filterKey="completedBetween" />
                        <Filters.DateRange label="Service Provider Invoice Date"
                            filterKey="serviceProviderInvoiceDateBetween" />
                    </Filters>

                    <NoResultsFound count={collection.count}>
                        <Table data={collection.jobs}
                            columns={columns}
                            collection={collection}
                            onSort={this.handleSort}
                            onRowSelect={this.handleRowSelect}
                            selectedRows={this.state.selectedJobs} />

                        <Paginator page={parseInt(collection.page)}
                            count={parseInt(collection.count)}
                            limit={parseInt(collection.limit)}
                            onPage={this.changePage} />
                    </NoResultsFound>
                </Loading>
            </div>
        );
    }

    validateAddNoteForm = () => {
        return Object.keys(this.state.selectedJobs).length && this.state.newNote.length;
    };

    handleAddNote = (event) => {
        event.preventDefault();

        const data = {
            note: this.state.newNote,
            elogbooksJobIds: Object.keys(this.state.selectedJobs),
        };

        batchCreate(this.clientId, data).then((response) => {
            if (response) {
                this.setState({newNote: '', showAddNoteDialog: false});
            }
        });
    };

    handleClearJobSelection = () => {
        this.setState({
            selectedJobs: {},
        });
    };

    getAddNoteLabel = () => {
        let count = Object.keys(this.state.selectedJobs).length;
        return `Add Note ${count ? `(${count} Job${count > 1 ? 's' : ''})` : ''}`;
    };

    getInvoiceDate = (attributes) => {
        const date = Elogbooks.getJobAttributeValue(attributes, 'value_invoice_date');

        if (date) {
            return DateTime.fromISO(date).toUTC().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
        }

        return '-';
    };

    componentDidMount() {
        if (!lodash.has(this.props.elogbooksJob, `${this.clientId}.${this.type.collectionKey}.collection`) || !this.props.clientResponse) {
            this.load();
        }
    }

    componentDidUpdate(prevProps) {
        this.filterData = this.props.filterData;

        if (!lodash.isEqual(prevProps.filters, this.props.filters) || prevProps.ordering !== this.props.ordering) {
            this.load();
        }
    }

    handleSort = (sort) => {
        let orderingString = '';

        if (sort.direction === 'asc') {
            orderingString = sort.sortKey;
        } else if (sort.direction === 'desc') {
            orderingString = `-${sort.sortKey}`;
        }

        this.props.updatePendingJobOrdering(orderingString);
    };

    handleRowSelect = (row) => {
        const selected = Object.assign({}, this.state.selectedJobs, {[row.id]: !this.state.selectedJobs[row.id]});
        const filtered = Object.keys(selected).reduce((obj, key) => {
            selected[key] === true && (obj[key] = selected[key]);

            return obj;
        }, {});

        this.setState({
            selectedJobs: filtered,
        });
    };

    load = () => {
        this.setState({loading: true});

        Promise.all([
            this.props.fetchClient(this.clientId),
            this.type.action(this.clientId, Object.assign({}, this.props.filters, {order: this.props.ordering})),
        ]).then(() => {
            this.setState({
                loading: false,
            });
        });
    };

    generateToState = (job) => {
        return `/pending-jobs/${this.clientId}/${job.id}`;
    };

    changePage = (page) => {
        Object.assign(this.props.filters, {page: page});
        this.load();
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingJobsList);
