import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import ServiceProvidersList from 'components/serviceproviders/ServiceProvidersList';
import ServiceProviderAddEdit from 'components/serviceproviders/ServiceProviderAddEdit';
import ServiceProviderDetails from 'components/serviceproviders/ServiceProviderDetails';

class ServiceProviders extends Component
{
    render() {
        const { match } = this.props;

        return (
            <div className="ServiceProviders">
                <Switch>
                    <Route path={`${match.path}`} component={ServiceProvidersList} exact />
                    <Route path={`${match.path}/add`} component={ServiceProviderAddEdit} />
                    <Route path={`${match.path}/:id/edit`} component={ServiceProviderAddEdit} />
                    <Route path={`${match.path}/:id`} component={ServiceProviderDetails} />
                </Switch>
            </div>
        );
    }
}

export default withRouter(ServiceProviders);
