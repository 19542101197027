import React, { Component } from 'react';
import Logout from 'Logout';
import logo from 'assets/images/logo.png';

export default class Header extends Component
{
    render() {
        return (
            <div className="Header">
                <header>
                    <img src={logo} alt="Elogbooks Facilities Services" />

                    <div className="float-right">
                        <Logout />
                    </div>
                </header>
            </div>
        );
    }
}
