import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import SalesInvoicesList from 'components/sales-invoices/SalesInvoicesList';
import SalesInvoiceDetails from 'components/sales-invoices/SalesInvoiceDetails';
import SalesInvoiceAdd from 'components/sales-invoices/SalesInvoiceAdd';

class SalesInvoices extends Component
{
    render() {
        const { match } = this.props;

        return (
            <div className="SalesInvoices">
                <Switch>
                    <Route path={`${match.path}`} component={SalesInvoicesList} exact />
                    <Route path={`${match.path}/add`} component={SalesInvoiceAdd} />
                    <Route path={`${match.path}/:id`} component={SalesInvoiceDetails} />
                </Switch>
            </div>
        )
    }
}

export default withRouter(SalesInvoices);
