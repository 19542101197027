import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import PendingJobsList from 'components/pending-jobs/PendingJobsList';
import PendingJobDetails from 'components/pending-jobs/PendingJobDetails';

class PendingJobs extends Component
{
    render() {
        const { match } = this.props;

        return (
            <div className="PendingJobs">
                <Switch>
                    <Route path={`${match.path}/:clientId/:type(open|pending-value|rejected-value|pending-approval)`} component={PendingJobsList} />
                    <Route path={`${match.path}/:clientId/:elogbooksJobId`} component={PendingJobDetails} />
                    <Redirect from="/" to="/dashboard" />
                </Switch>
            </div>
        );
    }
}

export default withRouter(PendingJobs);
