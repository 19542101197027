import React, { Component } from 'react';
import PropTypes from 'prop-types';

class HoldBadge extends Component
{
    static propTypes = {
        user: PropTypes.object.isRequired,
        prefix: PropTypes.string,
    };

    static defaultProps = {
        prefix: '',
    };

    render() {
        const style = {
            backgroundColor: this.props.user.highlightColour,
        };

        return (
            <span style={style} className="hold-badge">{this.props.prefix} {this.props.user.forename}</span>
        );
    }
}

export default HoldBadge;
