import HTTP from 'services/HTTP';
import { toast } from 'react-toastify';

export const FETCHING_PURCHASE_INVOICE = 'FETCHING_PURCHASE_INVOICE';
export const FETCHED_PURCHASE_INVOICE = 'FETCHED_PURCHASE_INVOICE';
export const FETCHING_PURCHASE_INVOICE_FAILED = 'FETCHING_PURCHASE_INVOICE_FAILED';
export const FETCHING_PURCHASE_INVOICES = 'FETCHING_PURCHASE_INVOICES';
export const FETCHED_PURCHASE_INVOICES = 'FETCHED_PURCHASE_INVOICES';
export const FETCHING_PURCHASE_INVOICES_FAILED = 'FETCHING_PURCHASE_INVOICES_FAILED';
export const UPDATING_PURCHASE_INVOICE_FILTERS = 'UPDATING_PURCHASE_INVOICE_FILTERS';
export const CLEARING_PURCHASE_INVOICE_FILTERS = 'CLEARING_PURCHASE_INVOICE_FILTERS';

export {
    fetchPurchaseInvoices,
    fetchPurchaseInvoice,
    updatePurchaseInvoiceFilters,
    clearPurchaseInvoiceFilters,
    creditPurchaseInvoice,
    cancelPurchaseInvoiceHold,
}

function cancelPurchaseInvoiceHold(id)
{
    return dispatch => {
        return HTTP
            .post(`/purchaseinvoices/${id}/cancelhold`)
            .then(() => {
                dispatch(fetchPurchaseInvoice(id));
                toast.success('Purchase invoice hold cancelled');

                return true;
            }).catch((error) => {
                error.response.data.forEach((error) => {
                    toast.error(`${error.property_path} - ${error.message}`);
                });
            });
    };
}

function creditPurchaseInvoice(id, params)
{
    return dispatch => {
        return HTTP
            .post(`/purchaseinvoices/${id}/credit`, params)
            .then((response) => {
                dispatch(fetchPurchaseInvoice(id));
                toast.success('Purchase invoice credited');

                return true;
            }).catch((error) => {
                error.response.data.forEach((error) => {
                    toast.error(`${error.property_path} - ${error.message}`);
                });

                return false;
            });
    };
}

function fetchPurchaseInvoice(id)
{
    return dispatch => {
        dispatch(fetchingPurchaseInvoice(id));

        return HTTP
            .get(`/purchaseinvoices/${id}`)
            .then((response) => {
                dispatch(fetchedPurchaseInvoice(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingPurchaseInvoiceFailed(response.data));

                return false;
            });
    };
}

function fetchPurchaseInvoices(params = {})
{
    return dispatch => {
        dispatch(fetchingPurchaseInvoices(params));

        return HTTP
            .get('/purchaseinvoices', params)
            .then((response) => {
                dispatch(fetchedPurchaseInvoices(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingPurchaseInvoicesFailed(response.data));

                return false;
            });
    };
}

function updatePurchaseInvoiceFilters(filters, filterData)
{
    return dispatch => {
        dispatch(updatingPurchaseInvoiceFilters(filters, filterData));
    };
}

function clearPurchaseInvoiceFilters(filters, filterData)
{
    return dispatch => {
        dispatch(clearingPurchaseInvoiceFilters(filters, filterData));
    };
}

function fetchingPurchaseInvoice(id)
{
    return {
        type: FETCHING_PURCHASE_INVOICE,
        id: id,
    };
}

function fetchedPurchaseInvoice(response)
{
    return {
        type: FETCHED_PURCHASE_INVOICE,
        response: response,
    };
}

function fetchingPurchaseInvoiceFailed(response)
{
    return {
        type: FETCHING_PURCHASE_INVOICE_FAILED,
    };
}

function fetchingPurchaseInvoices(params = {})
{
    return {
        type: FETCHING_PURCHASE_INVOICES,
    };
}

function fetchedPurchaseInvoices(response)
{
    return {
        type: FETCHED_PURCHASE_INVOICES,
        collection: response,
    };
}

function fetchingPurchaseInvoicesFailed(response)
{
    return {
        type: FETCHING_PURCHASE_INVOICES_FAILED,
    };
}

function updatingPurchaseInvoiceFilters(filters, filterData)
{
    return {
        type: UPDATING_PURCHASE_INVOICE_FILTERS,
        filters: filters,
        filterData: filterData,
    };
}

function clearingPurchaseInvoiceFilters()
{
    return {
        type: CLEARING_PURCHASE_INVOICE_FILTERS,
    };
}