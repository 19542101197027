import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchClients } from 'actions/client';
import { create, update, fetchProperty } from 'actions/property';
import CollectionSelect from 'components/elements/CollectionSelect';
import Response from 'services/Response';

const mapStateToProps = (state, ownProps) => {
    return {
        clientCollectionResponse: state.client.collection,
        isLoading: state.client.isRequesting,
        propertyResponse: state.property.properties[ownProps.match.params.id],
    };
};

const mapDispatchToProps = {
    create: create,
    update: update,
    fetchProperty: fetchProperty,
    fetchClients: fetchClients,
};

class PropertyAddEdit extends Component
{
    constructor(props) {
        super(props);

        const property = this.props.propertyResponse || {};

        this.state = {
            name: property.name || "",
            elogbooksReference: property.elogbooksReference || "",
            exchequerReference: property.exchequerReference || "",
            exchequerCustomerId: property.exchequerCustomerId || "",
            isActive: [true, false].indexOf(property.isActive) !== -1 ? (property.isActive ? 'true' : 'false') : 'false',
            clientId: Response.getLinkAttribute(property, 'client', 'id'),
        };
    }

    validateForm() {
        return (this.state.name &&
            this.state.elogbooksReference &&
            this.state.exchequerReference &&
            this.state.exchequerCustomerId &&
            ["true", "false"].indexOf(this.state.isActive) !== -1 &&
            this.state.clientId &&

            this.refs.name &&
            this.refs.elogbooksReference &&
            this.refs.exchequerReference &&
            this.refs.exchequerCustomerId &&

            this.refs.name.validity.valid &&
            this.refs.elogbooksReference.validity.valid &&
            this.refs.exchequerReference.validity.valid &&
            this.refs.exchequerCustomerId.validity.valid &&

            [undefined, null, ""].indexOf(this.state.clientId) === -1);
    }

    handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        const data = {
            name: this.state.name,
            elogbooksReference: this.state.elogbooksReference,
            exchequerReference: this.state.exchequerReference,
            exchequerCustomerId: this.state.exchequerCustomerId,
            isActive: this.state.isActive,
            clientId: this.state.clientId,
        };

        if (this.props.propertyResponse &&
            this.props.propertyResponse.id) {
            return this.props
                .update(this.props.propertyResponse.id, data)
                .then((response) => {
                    this.props.history.push('/properties/' + this.props.propertyResponse.id);
                }, (response) => {
                    // @todo show an error
                });
        }

        return this.props
            .create(data)
            .then((response) => {
                this.props.history.push('/properties/');
            }, (response) => {
                // @todo show an error
            });
    }

    render() {
        const { match, history } = this.props;
        const { id } = this.props.match.params;
        const cancelState = match.url.substring(0, match.url.length - (match.params.id ? 5 : 4));
        const clientCollection = this.props.clientCollectionResponse;

        return (
            <div className="PropertyAddEdit">
                <h1 className="clearfix">
                    { id ? 'Edit Property' : 'Add a New Property' }
                </h1>

                <form ref="form"
                    className="clearfix"
                    onSubmit={e => this.handleSubmit(e)}>
                    <FormGroup controlId="name" bssize="large">
                        <FormLabel>Name</FormLabel>
                        <FormControl as="input"
                            ref="name"
                            value={this.state.name}
                            onChange={e => { this.setState({"name": e.target.value}) }}
                            maxLength={60}
                            autoFocus />
                    </FormGroup>

                    <FormGroup controlId="elogbooksReference" bssize="large">
                        <FormLabel>Elogbooks Reference</FormLabel>
                        <FormControl as="input"
                            ref="elogbooksReference"
                            value={this.state.elogbooksReference}
                            onChange={e => { this.setState({"elogbooksReference": e.target.value}) }} />
                    </FormGroup>
                    <FormGroup controlId="exchequerReference" bssize="large">
                        <FormLabel>Exchequer Reference</FormLabel>
                        <FormControl as="input"
                            ref="exchequerReference"
                            value={this.state.exchequerReference}
                            onChange={e => { this.setState({"exchequerReference": e.target.value}) }} />
                    </FormGroup>
                    <FormGroup controlId="exchequerCustomerId" bssize="large">
                        <FormLabel>Exchequer Customer ID</FormLabel>
                        <FormControl as="input"
                            ref="exchequerCustomerId"
                            value={this.state.exchequerCustomerId}
                            onChange={e => { this.setState({"exchequerCustomerId": e.target.value}) }} />
                    </FormGroup>
                    <FormGroup controlId="isActive" bssize="large">
                        <FormLabel>Status</FormLabel>
                        <FormControl as="select"
                            ref="isActive"
                            value={this.state.isActive}
                            onChange={e => { this.setState({"isActive": e.target.value}) }}>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                        </FormControl>
                    </FormGroup>

                    <FormGroup controlId="client" bssize="large">
                        <FormLabel>Client</FormLabel>
                        <CollectionSelect
                            collection={clientCollection}
                            collectionKey="clients"
                            labelKey="name"
                            idKey="id"
                            defaultSelected={[this.state.clientId]}
                            callback={(response) => {
                                if (response.id) {
                                    return this.setState({"clientId": response.id});
                                }

                                return this.setState({"clientId": undefined});
                            }}/>
                    </FormGroup>

                    <div className="float-right">
                        <Button onClick={() => history.push(cancelState)}
                            variant="default"
                            bssize="large"
                            to={cancelState}>
                            <FontAwesomeIcon icon={['fas', 'ban']} />
                            Cancel
                        </Button>

                        <Button type="submit"
                            className="margin-left"
                            bssize="large"
                            disabled={!this.validateForm()}>
                            <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                            { id ? 'Update' : 'Create' }
                        </Button>
                    </div>
                </form>
            </div>
        );
    }

    componentDidMount()  {
        const { id } = this.props.match.params;

        if (typeof id === 'string' &&
            typeof this.props.propertyResponse === 'undefined') {
            this.load(id);
        }

        this.loadClients();
    }

    load(id) {
        this.props.fetchProperty(id);
    }

    loadClients() {
        this.props.fetchClients();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertyAddEdit);
