import {
    FETCHING_SALES_INVOICE,
    FETCHED_SALES_INVOICE,
    FETCHING_SALES_INVOICE_FAILED,
    FETCHING_SALES_INVOICES,
    FETCHED_SALES_INVOICES,
    FETCHING_SALES_INVOICES_FAILED,
    UPDATING_SALES_INVOICE_FILTERS,
    CLEARING_SALES_INVOICE_FILTERS,
} from 'actions/salesinvoice';
import { LOGOUT } from 'actions/auth';

const defaultState = {
    collection: {
        count: 0,
        limit: 10,
        page: 1,
        pages: 0,
        salesInvoices: [],
    },
    filters: {},
    filterData: {},
    isRequesting: false,
    salesInvoices: {},
};

export default function salesInvoice(state = defaultState, action)
{
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case FETCHING_SALES_INVOICE:
            return Object.assign(baseState, {
                isRequesting: true,
            });

        case FETCHED_SALES_INVOICE:
            return Object.assign(baseState, {
                isRequesting: false,
                salesInvoices: {
                    [action.response.id]: action.response,
                },
            });

        case FETCHING_SALES_INVOICE_FAILED:
            return Object.assign(baseState, {
                isRequesting: false,
            });

        case FETCHING_SALES_INVOICES:
            return Object.assign(baseState, {
                isRequesting: true,
            });

        case FETCHED_SALES_INVOICES:
            return Object.assign(baseState, {
                isRequesting: false,
                collection: action.collection,
            });

        case FETCHING_SALES_INVOICES_FAILED:
            return Object.assign(baseState, {
                isRequesting: false,
            });

        case UPDATING_SALES_INVOICE_FILTERS:
            return Object.assign(baseState, {
                filterData: action.filterData,
                filters: action.filters,
            });

        case CLEARING_SALES_INVOICE_FILTERS:
            return Object.assign(baseState, {
                filterData: defaultState.filterData,
                filters: defaultState.filters,
            });

        case LOGOUT:
            return Object.assign({}, defaultState);

        default:
            return Object.assign(baseState);
    }
}