class Response
{
    static getLink(response, link)
    {
        return Response.getLinkAttribute(response, link, 'href');
    }

    static getLinkAttribute(response, link, attribute)
    {
        if (response._links &&
            response._links[link] &&
            typeof response._links[link][attribute] !== 'undefined') {
            return response._links[link][attribute];
        }

        return null;
    }
}

export default Response
