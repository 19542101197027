const STATE_DRAFT = 0;
const STATE_PROCESSED = 1;
const STATE_INVOICED = 2;
const STATE_CREDITED = 3;
const STATE_SETTLED = 4;
const STATE_PROCESSING = 5;

const states = {
    [STATE_DRAFT]: 'Draft',
    [STATE_PROCESSED]: 'Processed',
    [STATE_INVOICED]: 'Invoiced',
    [STATE_CREDITED]: 'Credited',
    [STATE_SETTLED]: 'Settled',
    [STATE_PROCESSING]: 'Processing',
};

const stateOptions = [
    {
        id: STATE_DRAFT,
        label: states[STATE_DRAFT],
    },
    {
        id: STATE_PROCESSED,
        label: states[STATE_PROCESSED],
    },
    {
        id: STATE_INVOICED,
        label: states[STATE_INVOICED],
    },
    {
        id: STATE_CREDITED,
        label: states[STATE_CREDITED],
    },
    {
        id: STATE_SETTLED,
        label: states[STATE_SETTLED],
    },
    {
        id: STATE_PROCESSING,
        label: states[STATE_PROCESSING],
    },
];

export {
    STATE_DRAFT,
    STATE_PROCESSED,
    STATE_INVOICED,
    STATE_CREDITED,
    STATE_SETTLED,
    STATE_PROCESSING,
    states,
    stateOptions,
};
