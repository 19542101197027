import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import ErrorsList from 'components/jobs/ErrorsList';

class Errors extends Component
{
    render() {
        const { match } = this.props;

        return (
            <div className="Errors">
                <Switch>
                    <Route path={`${match.path}`} component={ErrorsList} exact />
                </Switch>
            </div>
        );
    }
}

export default withRouter(Errors);
