import React, { Component } from 'react';

export default class Footer extends Component
{
    render() {
        return (
            <div className="Footer">
                Copyright &copy; Elogbooks Facilities Services Ltd - Reg Company No. 11560925
            </div>
        );
    }
}
