import HTTP from 'services/HTTP';
import { toast } from 'react-toastify';

export const CREATED_SERVICE_PROVIDER = 'CREATED_SERVICE_PROVIDER';
export const CREATING_SERVICE_PROVIDER = 'CREATING_SERVICE_PROVIDER';
export const CREATING_SERVICE_PROVIDER_FAILED = 'CREATING_SERVICE_PROVIDER_FAILED';
export const FETCHED_SERVICE_PROVIDER = 'FETCHED_SERVICE_PROVIDER';
export const FETCHED_SERVICE_PROVIDERS = 'FETCHED_SERVICE_PROVIDERS';
export const FETCHING_SERVICE_PROVIDER = 'FETCHING_SERVICE_PROVIDER';
export const FETCHING_SERVICE_PROVIDERS = 'FETCHING_SERVICE_PROVIDERS';
export const FETCHING_SERVICE_PROVIDER_FAILED = 'FETCHING_SERVICE_PROVIDER_FAILED';
export const UPDATED_SERVICE_PROVIDER = 'UPDATED_SERVICE_PROVIDER';
export const UPDATING_SERVICE_PROVIDER = 'UPDATING_SERVICE_PROVIDER';
export const UPDATING_SERVICE_PROVIDER_FAILED = 'UPDATING_SERVICE_PROVIDER_FAILED';
export const UPDATING_SERVICE_PROVIDER_FILTERS = 'UPDATING_SERVICE_PROVIDER_FILTERS';
export const CLEARING_SERVICE_PROVIDER_FILTERS = 'CLEARING_SERVICE_PROVIDER_FILTERS';

export {
    create,
    fetchServiceProvider,
    fetchServiceProviders,
    update,
    hold,
    cancelHold,
    updateServiceProviderFilters,
    clearServiceProviderFilters,
};

function updateServiceProviderFilters(filters, filterData)
{
    return dispatch => {
        dispatch(updatingServiceProviderFilters(filters, filterData));
    };
}

function clearServiceProviderFilters()
{
    return dispatch => {
        dispatch(clearingServiceProviderFilters());
    };
}

function hold(id)
{
    return dispatch => {
        return HTTP
            .post(`/serviceproviders/${id}/hold`)
            .then(() => {
                dispatch(fetchServiceProvider(id));
                toast.success('Service provider held');

                return true;
            }).catch(() => {
                toast.error('Unable to hold service provider');

                return false;
            });
    };
}

function cancelHold(id)
{
    return dispatch => {
        return HTTP
            .post(`/serviceproviders/${id}/cancelhold`)
            .then(() => {
                dispatch(fetchServiceProvider(id));
                toast.success('Service provider hold cancelled');

                return true;
            }).catch(() => {
                toast.error('Unable to cancel service provider hold');

                return false;
            });
    };
}

function fetchServiceProviders(params = {})
{
    return dispatch => {
        dispatch(fetchingServiceProviders(params));

        return HTTP
            .get('/serviceproviders', params)
            .then((response) => {
                dispatch(fetchedServiceProviders(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingServiceProviderFailed(response.data));

                return false;
            });
    };
}

function create(data = {})
{
    return (dispatch, getState) => {
        dispatch(creatingServiceProvider(data));

        return HTTP
            .post('/serviceproviders/new', data)
            .then((response) => {
                dispatch(createdServiceProvider(response.data));
                dispatch(fetchServiceProviders(getState().serviceProvider.filters));

                return response.data;
            }, (response) => {
                dispatch(creatingServiceProviderFailed(response.data));

                return false;
            });
    };
}

function update(id, data = {})
{
    return (dispatch, getState) => {
        dispatch(updatingServiceProvider(id, data));

        return HTTP
            .put('/serviceproviders/' + id, data)
            .then((response) => {
                dispatch(updatedServiceProvider(id, response.data));
                dispatch(fetchServiceProviders(getState().user.filters));

                return response.data;
            }, (response) => {
                dispatch(updatingServiceProviderFailed(id, response.data));

                return false;
            });
    };
}

function fetchServiceProvider(id)
{
    return dispatch => {
        dispatch(fetchingServiceProvider(id));

        return HTTP
            .get('/serviceproviders/' + id)
            .then((response) => {
                dispatch(fetchedServiceProvider(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingServiceProviderFailed(response.data));

                return false;
            });
    };
}

function updatingServiceProviderFilters(filters, filterData)
{
    return {
        type: UPDATING_SERVICE_PROVIDER_FILTERS,
        filters: filters,
        filterData: filterData,
    };
}

function clearingServiceProviderFilters()
{
    return {
        type: CLEARING_SERVICE_PROVIDER_FILTERS,
    };
}

function fetchingServiceProviders(params = {})
{
    return {
        type: FETCHING_SERVICE_PROVIDERS,
    };
}

function fetchedServiceProviders(response)
{
    return {
        type: FETCHED_SERVICE_PROVIDERS,
        collection: response,
    };
}

function creatingServiceProvider(data = {})
{
    return {
        type: CREATING_SERVICE_PROVIDER,
        data: data,
    };
}

function createdServiceProvider(response)
{
    return {
        type: CREATED_SERVICE_PROVIDER,
    };
}

function creatingServiceProviderFailed(response)
{
    return {
        type: CREATING_SERVICE_PROVIDER_FAILED,
    };
}

function updatingServiceProvider(id, data = {})
{
    return {
        type: UPDATING_SERVICE_PROVIDER,
        id: id,
        data: data,
    };
}

function updatedServiceProvider(id, response)
{
    return {
        type: UPDATED_SERVICE_PROVIDER,
        id: id,
        response: response,
    };
}

function updatingServiceProviderFailed(id, response)
{
    return {
        type: UPDATING_SERVICE_PROVIDER_FAILED,
        id: id,
        response: response,
    };
}

function fetchingServiceProvider(id)
{
    return {
        type: FETCHING_SERVICE_PROVIDER,
        id: id,
    };
}

function fetchingServiceProviderFailed(response)
{
    return {
        type: FETCHING_SERVICE_PROVIDER_FAILED,
    };
}

function fetchedServiceProvider(response)
{
    return {
        type: FETCHED_SERVICE_PROVIDER,
        response: response,
    };
}
