import HTTP from 'services/HTTP';

export const FETCHED_JOB_ERROR = 'FETCHED_JOB_ERROR';
export const FETCHED_JOB_ERRORS = 'FETCHED_JOB_ERRORS';
export const FETCHING_JOB_ERROR = 'FETCHING_JOB_ERROR';
export const FETCHING_JOB_ERRORS = 'FETCHING_JOB_ERRORS';
export const FETCHING_JOB_ERROR_FAILED = 'FETCHING_JOB_ERROR_FAILED';

export {
    fetchJobError,
    fetchJobErrors,
};

function fetchJobErrors(params = {})
{
    return dispatch => {
        dispatch(fetchingJobErrors(params));

        return HTTP
            .get('/jobs/errors', params)
            .then((response) => {
                dispatch(fetchedJobErrors(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingJobErrorFailed(response.data));

                return false;
            });
    };
}

function fetchJobError(id)
{
    return dispatch => {
        dispatch(fetchingJobError(id));

        return HTTP
            .get('/jobs/errors/' + id)
            .then((response) => {
                dispatch(fetchedJobError(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingJobErrorFailed(response.data));

                return false;
            });
    };
}

function fetchingJobErrors(params = {})
{
    return {
        type: FETCHING_JOB_ERRORS,
    };
}

function fetchedJobErrors(response)
{
    return {
        type: FETCHED_JOB_ERRORS,
        collection: response,
    };
}

function fetchingJobError(id)
{
    return {
        type: FETCHING_JOB_ERROR,
        id: id,
    };
}

function fetchingJobErrorFailed(response)
{
    return {
        type: FETCHING_JOB_ERROR_FAILED,
    };
}

function fetchedJobError(response)
{
    return {
        type: FETCHED_JOB_ERROR,
        response: response,
    };
}
