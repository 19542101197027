import HTTP from 'services/HTTP';

export {
    fetchProperties,
};

function fetchProperties(params = {})
{
    return HTTP
        .get('/properties', params)
        .then((response) => {
            return response.data;
        }).catch(() => {
            return false;
        });
}
