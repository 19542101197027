class Elogbooks
{
    static createFrontendLink(installationUrl, route, parameters = {})
    {
        installationUrl = (installationUrl || '')
            .replace(/-api\.v2/g, '.v2')
            .replace(/-api\.frontend/g, '.frontend')
            .replace(/\/api\/$/g, '/')
            .replace(/\/api$/, '')
            .replace(/\/+$/, '');

        route = route
            .replace(/^\/+/, '');

        let modifiedRoute = Elogbooks.parseRouteParameters(route, parameters);

        return installationUrl + '/#/' + modifiedRoute;
    }

    static parseRouteParameters(route, parameters = {})
    {
        let modifiedRoute = route;

        Object.entries(parameters).forEach(([key, value], index) => {
            let replacingString = new RegExp('{' + key + '}', 'g');

            modifiedRoute = modifiedRoute.replace(replacingString, btoa(value));
        });

        return modifiedRoute;
    }

    static getJobAttributeValue(attributes, attributeKey) {
        for (let attribute in attributes) {
            if (attributes[attribute].key === attributeKey) {
                return attributes[attribute].value;
            }
        }

        return null;
    }
}

export default Elogbooks;
