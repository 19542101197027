import HTTP from 'services/HTTP';
import { fetchOpenJobsByClient } from 'actions/elogbooks/job';

export const FETCHED_ALL_CLIENTS = 'FETCHED_ALL_CLIENTS';
export const FETCHED_COUNT_JOB_ERRORS = 'FETCHED_COUNT_JOB_ERRORS';
export const FETCHED_COUNT_JOBS = 'FETCHED_COUNT_JOBS';
export const FETCHING_ALL_CLIENTS = 'FETCHING_ALL_CLIENTS';
export const FETCHING_ALL_CLIENTS_FAILED = 'FETCHING_ALL_CLIENTS_FAILED';
export const FETCHING_COUNT_JOB_ERRORS = 'FETCHING_COUNT_JOB_ERRORS';
export const FETCHING_COUNT_JOB_ERRORS_FAILED = 'FETCHING_COUNT_JOB_ERRORS_FAILED';
export const FETCHING_COUNT_JOBS = 'FETCHING_COUNT_JOBS';
export const FETCHING_COUNT_JOBS_FAILED = 'FETCHING_COUNT_JOBS_FAILED';

export {
    fetchAllClients,
    fetchCountJobErrors,
    fetchCountJobs,
};

function fetchAllClients(params = {})
{
    return dispatch => {
        dispatch(fetchingAllClients());

        return HTTP
            .get('/clients', Object.assign({}, {limit: 100}, params))
            .then((response) => {
                dispatch(fetchedAllClients(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingAllClientsFailed(response.data));

                return false;
            });
    };
}

function fetchCountJobErrors(params = {})
{
    return dispatch => {
        params.limit = 0;

        dispatch(fetchingCountJobErrors(params));

        return HTTP
            .get('/jobs/errors', params)
            .then((response) => {
                dispatch(fetchedCountJobErrors(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingCountJobErrorsFailed(response.data));

                return false;
            });
    };
}

function fetchCountJobs(params = {})
{
    return dispatch => {
        params.limit = 0;

        dispatch(fetchingCountJobs(params));

        return HTTP
            .get('/jobs', params)
            .then((response) => {
                dispatch(fetchedCountJobs(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingCountJobsFailed(response.data));

                return false;
            });
    };
}

function fetchingCountJobErrorsFailed(params = {})
{
    return {
        type: FETCHING_COUNT_JOB_ERRORS_FAILED,
    };
}

function fetchingCountJobErrors(params = {})
{
    return {
        type: FETCHING_COUNT_JOB_ERRORS,
    };
}

function fetchedCountJobErrors(response)
{
    return {
        type: FETCHED_COUNT_JOB_ERRORS,
        collection: response,
    };
}

function fetchingCountJobs(params = {})
{
    return {
        type: FETCHING_COUNT_JOBS,
    };
}

function fetchingCountJobsFailed(response)
{
    return {
        type: FETCHING_COUNT_JOBS_FAILED,
    };
}

function fetchedCountJobs(response)
{
    return {
        type: FETCHED_COUNT_JOBS,
        collection: response,
    };
}

function fetchingAllClients()
{
    return {
        type: FETCHING_ALL_CLIENTS,
    };
}

function fetchedAllClients(response)
{
    return {
        type: FETCHED_ALL_CLIENTS,
        collection: response,
    };
}

function fetchingAllClientsFailed(response)
{
    return {
        type: FETCHING_ALL_CLIENTS_FAILED,
    };
}
