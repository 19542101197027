import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

class PromptDialog extends Component
{
    static propTypes = {
        show: PropTypes.bool.isRequired,
        hide: PropTypes.func.isRequired,
        validateForm: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
    };

    render() {
        return (
            <Modal show={this.props.show}
                onHide={this.props.hide}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <form ref="prompt-form"
                    onSubmit={this.props.onSubmit}>
                    <Modal.Body>
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                            onClick={this.props.hide}>
                            Cancel
                        </Button>
                        <Button variant="primary"
                            type="submit"
                            disabled={!this.props.validateForm()}
                            onClick={this.props.onConfirm}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

export default PromptDialog;