import {
    FETCHED_ALL_CLIENTS,
    FETCHED_COUNT_JOB_ERRORS,
    FETCHED_COUNT_JOBS,
    FETCHING_ALL_CLIENTS,
    FETCHING_COUNT_JOB_ERRORS,
    FETCHING_COUNT_JOBS,
} from 'actions/dashboard';
import { LOGOUT } from 'actions/auth';

const defaultState = {
    clients: {
        collection: {
            clients: undefined,
            count: 0,
            limit: 0,
            page: 1,
            pages: 0,
        },
        clients: [],
        filters: {},
        isRequesting: false,
    },
    errors: {
        collection: {
            count: 0,
            errors: undefined,
            limit: 0,
            page: 1,
            pages: 0,
        },
        errors: [],
        filters: {
            limit: 0,
        },
        isRequesting: false,
    },
    jobs: {
        collection: {
            count: 0,
            jobs: undefined,
            limit: 0,
            page: 1,
            pages: 0,
        },
        filters: {
            isCis: ['~'],
            limit: 0,
            modifiers: {},
        },
        isRequesting: false,
        jobs: [],
    }
};

export default function dashboard(state = defaultState, action)
{
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case FETCHED_ALL_CLIENTS:
            var shallow = Object.assign(baseState.clients, {
                collection: action.collection,
                isRequesting: false,
            });

            return Object.assign(baseState, {clients: shallow});

        case FETCHED_COUNT_JOB_ERRORS:
            var shallow = Object.assign(baseState.errors, {
                collection: action.collection,
                isRequesting: false,
            });

            return Object.assign(baseState, {errors: shallow});

        case FETCHED_COUNT_JOBS:
            var shallow = Object.assign(baseState.jobs, {
                isRequesting: false,
                collection: action.collection,
            });

            return Object.assign(baseState, {jobs: shallow});

        case FETCHING_ALL_CLIENTS:
            var shallow = Object.assign(baseState.clients, {
                isRequesting: true,
            });

            return Object.assign(baseState, {clients: shallow});

        case FETCHING_COUNT_JOB_ERRORS:
            var shallow = Object.assign(baseState.errors, {
                isRequesting: true,
            });

            return Object.assign(baseState, {errors: shallow});

        case FETCHING_COUNT_JOBS:
            var shallow = Object.assign(baseState.jobs, {
                isRequesting: true,
            });

            return Object.assign(baseState, {jobs: shallow});

        case LOGOUT:
            return Object.assign({}, defaultState);

        default:
            return Object.assign(baseState);
    }
}
