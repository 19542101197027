import {
    CREATED_CLIENT,
    CREATING_CLIENT,
    CREATING_CLIENT_FAILED,
    FETCHED_CLIENT,
    FETCHED_CLIENTS,
    FETCHING_CLIENT,
    FETCHING_CLIENTS,
    FETCHING_CLIENT_FAILED,
    UPDATED_CLIENT,
    UPDATING_CLIENT,
    UPDATING_CLIENT_FAILED,
} from 'actions/client';
import { LOGOUT } from 'actions/auth';
import { merge } from 'lodash';

const defaultState = {
    clients: [],
    collection: {
        clients: undefined,
        count: 0,
        limit: 10,
        page: 1,
        pages: 0,
    },
    data: undefined,
    filters: {
        order: 'name',
    },
    isCreating: false,
    isRequesting: false,
    isUpdating: false,
};

export default function client(state = defaultState, action)
{
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case CREATED_CLIENT:
            return Object.assign(baseState, {
                isCreating: false,
                data: undefined,
                response: action.response,
            });

        case CREATING_CLIENT:
            return Object.assign(baseState, {
                isCreating: true,
                data: action.data,
            });

        case CREATING_CLIENT_FAILED:
            return Object.assign(baseState, {
                isCreating: false,
                data: undefined,
            });

        case FETCHED_CLIENT:
            let shallow = merge({}, baseState.clients, {
                [action.response.id]: action.response,
            });
            shallow[action.response.id].alternateJobCodes = action.response.alternateJobCodes;

            return Object.assign(baseState, {
                isFetching: false,
                clients: shallow,
            });

        case FETCHED_CLIENTS:
            return Object.assign(baseState, {
                isRequesting: false,
                collection: action.collection,
            });

        case FETCHING_CLIENT:
            return Object.assign(baseState, {
                isFetching: true,
            });

        case FETCHING_CLIENTS:
            return Object.assign(baseState, {
                isRequesting: true,
                filters: action.filters,
            });

        case FETCHING_CLIENT_FAILED:
            return Object.assign(baseState, {
                isFetching: false,
            });

        case UPDATED_CLIENT:
            return Object.assign(baseState, {
                isUpdating: false,
                data: undefined,
                clients: {
                    [action.id]: Object.assign({}, baseState.clients[action.id], action.data),
                }
            });

        case UPDATING_CLIENT:
            return Object.assign(baseState, {
                isUpdating: true,
                data: action.data,
            });

        case UPDATING_CLIENT_FAILED:
            return Object.assign(baseState, {
                isUpdating: false,
                data: undefined,
            });

        case LOGOUT:
            return Object.assign({}, defaultState);

        default:
            return Object.assign(baseState);
    }
}
