import HTTP from 'services/HTTP';
import { toast } from 'react-toastify';

export const FETCHED_JOB = 'FETCHED_JOB';
export const FETCHED_JOBS = 'FETCHED_JOBS';
export const FETCHING_JOB = 'FETCHING_JOB';
export const FETCHING_JOBS = 'FETCHING_JOBS';
export const FETCHING_JOB_FAILED = 'FETCHING_JOB_FAILED';
export const UPDATED_JOB = 'UPDATED_JOB';
export const UPDATING_JOB = 'UPDATING_JOB';
export const UPDATING_JOB_FAILED = 'UPDATING_JOB_FAILED';
export const CLEARING_JOB_FILTERS = 'CLEARING_JOB_FILTERS';
export const UPDATING_JOB_FILTERS = 'UPDATING_JOB_FILTERS';
export const DELETING_JOB = 'DELETING_JOB';
export const DELETED_JOB = 'DELETED_JOB';
export const DELETING_JOB_FAILED = 'DELETING_JOB_FAILED';

export {
    fetchJob,
    fetchJobs,
    update,
    clearJobFilters,
    updateJobFilters,
    deleteJob,
    fetchElogbooksJob,
    hold,
    cancelHold,
};

function hold(id)
{
    return dispatch => {
        return HTTP
            .post(`/jobs/${id}/hold`)
            .then(() => {
                dispatch(fetchJob(id));
                toast.success('Job held');

                return true;
            }).catch(() => {
                toast.error('Unable to hold job');

                return false;
            });
    }
}

function cancelHold(id)
{
    return dispatch => {
        return HTTP
            .post(`/jobs/${id}/cancelhold`)
            .then(() => {
                dispatch(fetchJob(id));
                toast.success('Job hold cancelled');

                return true;
            }).catch(() => {
                toast.error('Unable to cancel job hold');

                return false;
            });
    }
}

function fetchJobs(params = {})
{
    return dispatch => {
        dispatch(fetchingJobs(params));

        return HTTP
            .get('/jobs', params)
            .then((response) => {
                dispatch(fetchedJobs(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingJobFailed(response.data));

                return false;
            });
    };
}

function update(id, data = {})
{
    return (dispatch, getState) => {
        dispatch(updatingJob(id, data));

        return HTTP
            .put('/jobs/' + id, data)
            .then((response) => {
                dispatch(updatedJob(id, response.data));
                dispatch(fetchJobs(getState().user.filters));

                return response.data;
            }, (response) => {
                dispatch(updatingJobFailed(id, response.data));

                return false;
            })
    };
}

function deleteJob(id)
{
    return (dispatch, getState) => {
        dispatch(deletingJob(id));

        return HTTP
            .delete('/jobs/' + id)
            .then(() => {
                dispatch(deletedJob(id));
                dispatch(fetchJobs(getState().job.filters));
                toast.success('Job deleted');

                return true;
            }).catch((error) => {
                dispatch(deletingJobFailed(id));
                toast.error(error.response.data.message);

                return false;
            });
    };
}

function fetchJob(id)
{
    return dispatch => {
        dispatch(fetchingJob(id));

        return HTTP
            .get('/jobs/' + id)
            .then((response) => {
                dispatch(fetchedJob(response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingJobFailed(response.data));

                return false;
            })
    }
}

function fetchElogbooksJob(data)
{
    return dispatch => {
        return HTTP
            .post('/jobs/fetch', data);
    }
}

function clearJobFilters()
{
    return dispatch => {
        dispatch(clearingJobFilters());
    }
}

function updateJobFilters(filters, filterData)
{
    return dispatch => {
        dispatch(updatingJobFilters(filters, filterData));
    }
}

function clearingJobFilters()
{
    return {
        type: CLEARING_JOB_FILTERS,
    };
}

function updatingJobFilters(filters, filterData) {
    return {
        type: UPDATING_JOB_FILTERS,
        filters: filters,
        filterData: filterData,
    };
}

function fetchingJobs(params = {})
{
    return {
        type: FETCHING_JOBS,
    };
}

function fetchedJobs(response)
{
    return {
        type: FETCHED_JOBS,
        collection: response,
    };
}

function updatingJob(id, data = {})
{
    return {
        type: UPDATING_JOB,
        id: id,
        data: data,
    };
}

function updatedJob(id, response)
{
    return {
        type: UPDATED_JOB,
        id: id,
        response: response,
    };
}

function updatingJobFailed(id, response)
{
    return {
        type: UPDATING_JOB_FAILED,
        id: id,
        response: response,
    };
}

function deletingJob(id)
{
    return {
        type: DELETING_JOB,
        id: id,
    }
}

function deletedJob(id)
{
    return {
        type: DELETED_JOB,
        id: id,
    }
}

function deletingJobFailed(id)
{
    return {
        type: DELETING_JOB_FAILED,
        id: id,
    }
}

function fetchingJob(id)
{
    return {
        type: FETCHING_JOB,
        id: id,
    };
}

function fetchingJobFailed(response)
{
    return {
        type: FETCHING_JOB_FAILED,
    };
}

function fetchedJob(response)
{
    return {
        type: FETCHED_JOB,
        response: response,
    };
}
