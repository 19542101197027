import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import UsersList from 'components/users/UsersList';
import UserAddEdit from 'components/users/UserAddEdit';
import UserDetails from 'components/users/UserDetails';

class Users extends Component
{
    render() {
        const { match } = this.props;

        return (
            <div className="Users">
                <Switch>
                    <Route path={`${match.path}`} component={UsersList} exact />
                    <Route path={`${match.path}/add`} component={UserAddEdit} />
                    <Route path={`${match.path}/:id/edit`} component={UserAddEdit} />
                    <Route path={`${match.path}/:id`} component={UserDetails} />
                </Switch>
            </div>
        );
    }
}

export default withRouter(Users);
