import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import HTTP from 'services/HTTP';

import './ForgottenPassword.scss';

export default class ForgottenPassword extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            email: '',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validateForm() {
        return true;
    }

    handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        const { email } = this.state;
        const { history } = this.props;

        const data = {
            email: email,
        };

        return HTTP
            .post('/forgotten-password', data)
            .then((response) => {
                if (response) {
                    toast.success('Password reset request successful');

                    history.push('/login');

                    return true;
                }

                toast.error('Unable to request a password reset');

                return false;
            });
    }

    render() {
        const { email } = this.state;

        return (
            <div className="ForgottenPassword">
                <form ref="form"
                    onSubmit={e => this.handleSubmit(e)}>
                    <FormGroup controlId="email" bssize="large">
                        <FormLabel>Email Address</FormLabel>
                        <FormControl type="email"
                            ref="email"
                            value={email}
                            onChange={e => { this.setState({"email": e.target.value}) }}
                            autoFocus />
                    </FormGroup>

                    <Button type="submit"
                        block
                        bssize="large"
                        disabled={!this.validateForm()}>
                        Request Password Reset
                    </Button>

                    <Link to="/login">Login</Link>
                </form>
            </div>
        );
    }
}
