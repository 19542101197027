import React, { Component } from 'react';
import { Table as FDTable, Column as FDColumn, Cell as FDCell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import PropTypes from 'prop-types';
import ContainerProperties from 'components/higher-order-components/ContainerProperties';
import lodash from 'lodash';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { DateTime } from 'luxon';
import NumberFormatting from 'services/NumberFormatting';
import Icon from 'components/elements/Icon';

class Table extends Component
{
    static propTypes = {
        data: PropTypes.array.isRequired,
        columns: PropTypes.arrayOf(PropTypes.shape({
            header: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,

            visible: PropTypes.bool,
        })).isRequired,
        numberOfRows: PropTypes.number,
        onSort: PropTypes.func,
        onRowSelect: PropTypes.func,
        selectedRows: PropTypes.object,
    };

    static defaultProps = {
        numberOfRows: 10
    };

    constructor(props) {
        super(props);

        this.sortOrder = [
            '',
            'asc',
            'desc',
        ];

        this.state = {
            columnOrder: props.columns.map((column, index) => {
                return column.header;
            }),
            fixedColumns: props.columns.filter((column, index) => {
                return column.fixed;
            }),
            fixedRightColumns: props.columns.filter((column, index) => {
                return column.fixedRight;
            }),
            columnSort: props.columns.map(column => {
                return column.defaultSort ? this.sortOrder.indexOf(column.defaultSort) : 0;
            }),
        };

        this.columnReorderEndCallback = this.columnReorderEndCallback.bind(this);
    }

    render() {
        const rowHeight = 40;
        const actualNumberOfRows = (this.props.numberOfRows < this.props.data.length)
            ? this.props.numberOfRows
            : this.props.data.length;
        const height = (actualNumberOfRows * rowHeight) + rowHeight;

        return (
            <div>
                <ContainerProperties>
                    {({usableWidth}) => {
                        return (
                            <FDTable
                                rowHeight={rowHeight}
                                headerHeight={rowHeight}
                                rowsCount={actualNumberOfRows}
                                width={usableWidth}
                                height={height + 2}
                                onColumnReorderEndCallback={this.columnReorderEndCallback}
                                isColumnReordering={false}
                                >
                                {this.renderColumns(usableWidth)}
                            </FDTable>
                        );
                    }}
                </ContainerProperties>
            </div>
        );
    }

    renderColumns(usableWidth) {
        return this.state.columnOrder.map((columnIndex, index) => {
            let column = lodash.find(this.props.columns, {header: columnIndex});

            return (
                <FDColumn
                    allowCellsRecycling={true}
                    isReorderable={!column.fixed && !column.fixedRight}
                    key={index}
                    columnKey={column.header}
                    header={this.renderHeader(column, index)}
                    width={this.getColumnWidth(column.width, usableWidth)}
                    fixed={column.fixed}
                    fixedRight={column.fixedRight}
                    cell={({rowIndex}) => {
                        return this.renderCell(column, rowIndex);
                    }} />
            );
        });
    }

    renderHeader(column, index) {
        let props = {}, sortIcon = null;
        if (column.sortable === true) {
            props = {
                onClick: () => {
                    this.handleSort(column, index);
                },
                className: 'cursor-pointer',
            };

            sortIcon = [<Icon icon="sort" />, <Icon icon="sort-up" />, <Icon icon="sort-down" />][this.state.columnSort[index]];
        }

        return <FDCell {...props}>{column.header} {sortIcon}</FDCell>;
    }

    handleSort = (column, index) => {
        const currentSort = this.state.columnSort[index],
            columnSort = [...this.state.columnSort],
            nextSort = (currentSort + 1 === 3) ? 0 : currentSort + 1;

        columnSort[index] = nextSort;

        this.setState({
            columnSort: columnSort,
        });

        if (typeof this.props.onSort === 'function') {
            this.props.onSort({
                sortKey: column.sortKey,
                direction: [null, 'asc', 'desc'][nextSort],
            });
        }
    };

    renderCell(column, rowIndex) {
        switch (column.type) {
            case 'checkbox':
                return this.renderCellCheckbox(column, rowIndex);

            case 'button':
                return this.renderCellButton(column, rowIndex);

            case 'array':
                return this.renderCellArray(column, rowIndex);

            case 'datetime':
                return this.renderCellDateTime(column, rowIndex);

            case 'active':
                return this.renderCellActive(column, rowIndex);

            case 'currency':
                return this.renderCellCurrency(column, rowIndex);

            case undefined:
            case 'text':
            default:
                return this.renderCellText(column, rowIndex);
        }
    }

    renderCellCheckbox(column, rowIndex) {
        return (
            <FDCell>
                <label className="cursor-pointer text-center w-100 h-100">
                    <input type="checkbox"
                        onChange={e => {this.props.onRowSelect(this.props.data[rowIndex])}}
                        checked={this.props.selectedRows[this.props.data[rowIndex].id] || false} />
                </label>
            </FDCell>
        );
    }

    renderCellArray(column, rowIndex) {
        let values = this.props.data[rowIndex][column.key];

        if (column.modifier) {
            values = values.map(column.modifier);
        }

        return <FDCell>{values.join(', ')}</FDCell>;
    }

    renderCellText(column, rowIndex) {
        let value = lodash.get(this.props.data[rowIndex], column.key);

        if (column.modifier) {
            value = column.modifier(value, rowIndex);
        }

        return <FDCell>{value}</FDCell>;

    }

    renderCellCurrency(column, rowIndex) {
        return <FDCell>{NumberFormatting.formatCurrency(lodash.get(this.props.data[rowIndex], column.key))}</FDCell>;
    }

    renderCellActive(column, rowIndex) {
        const value = lodash.get(this.props.data[rowIndex], column.key);
        let content = '-';
        if (value === true) {
            content = 'Active';
        } else if (value === false) {
            content = 'Inactive';
        }

        return <FDCell>{content}</FDCell>;
    }

    renderCellDateTime(column, rowIndex) {
        const dateTime = lodash.get(this.props.data[rowIndex], column.key);

        return <FDCell>
            {dateTime ? DateTime.fromISO(dateTime).toUTC().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : '-'}
        </FDCell>
    }

    renderCellButton(column, rowIndex) {
        const { history } = this.props;

        let disabled = false;
        if (typeof column.disabled === 'function') {
            disabled = column.disabled(this.props.data[rowIndex]);
        }

        let text = column.text;
        if (typeof column.text === 'function') {
            text = column.text(this.props.data[rowIndex]);
        }

        if (column.link) {
            let toState = column.toState;

            if (typeof column.toState === 'function') {
                toState = column.toState(this.props.data[rowIndex]);
            }

            return (
                <FDCell>
                    <Button disabled={disabled} size="sm" onClick={() => history.push(toState)} block>
                        {text}
                    </Button>
                </FDCell>
            );
        } else if (column.onClick) {
            return (
                <FDCell>
                    <Button disabled={disabled} size="sm" onClick={() => column.onClick(rowIndex)} block>
                        {text}
                    </Button>
                </FDCell>
            );
        }

        return <FDCell><Button disabled={disabled} size="sm" block>{text}</Button></FDCell>;
    }

    getColumnWidth(width, usableWidth) {
        let totalWidth = 0;
        this.props.columns.forEach((column, index) => {
            if (column.visible) {
                totalWidth += column.width;
            }
        });

        return usableWidth * (width / totalWidth);
    }

    columnReorderEndCallback(event) {
        let columnOrder = this.state.columnOrder.filter((columnKey) => {
            return columnKey !== event.reorderColumn;
        });

        if (event.columnAfter) {
            columnOrder.splice(columnOrder.indexOf(event.columnAfter), 0, event.reorderColumn);
        } else if (lodash.findIndex(this.state.fixedColumns, event.reorderColumn) !== -1) {
            columnOrder.splice(this.state.fixedColumns.length - 1, 0, event.reorderColumn);

        } else {
            columnOrder.push(event.reorderColumn);
        }

        this.setState({
            columnOrder: columnOrder
        });
    }
}

export default withRouter(Table);
