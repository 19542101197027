import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export default class ButtonLink extends Component
{
    render() {
        const {to, className, style, ...buttonProps} = this.props;

        return (
            <div className={'ButtonLink' + (className ? ' ' + className : '')} style={style}>
                <Button {...buttonProps}>
                    <Link to={to}>
                        {this.props.children}
                    </Link>
                </Button>
            </div>
        );
    }
}
