import {
    CREATED_USER,
    CREATING_USER,
    CREATING_USER_FAILED,
    FETCHED_USER,
    FETCHED_USERS,
    FETCHING_USER,
    FETCHING_USERS,
    FETCHING_USER_FAILED,
    UPDATED_USER,
    UPDATING_USER,
    UPDATING_USER_FAILED,
} from 'actions/user';
import { LOGOUT } from 'actions/auth';

const defaultState = {
    collection: {
        count: 0,
        limit: 10,
        page: 1,
        pages: 0,
        users: undefined,
    },
    data: undefined,
    filters: {
        order: 'forename',
    },
    isCreating: false,
    isFetching: false,
    isRequesting: false,
    isUpdating: false,
    users: [],
};

export default function user(state = defaultState, action)
{
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case CREATED_USER:
            return Object.assign(baseState, {
                isCreating: false,
                data: undefined,
                response: action.response,
            });

        case CREATING_USER:
            return Object.assign(baseState, {
                isCreating: true,
                data: action.data,
            });

        case CREATING_USER_FAILED:
            return Object.assign(baseState, {
                isCreating: false,
                data: undefined,
            });

        case FETCHED_USER:
            return Object.assign(baseState, {
                isFetching: false,
                users: {
                    [action.response.id]: action.response,
                },
            });

        case FETCHED_USERS:
            return Object.assign(baseState, {
                isRequesting: false,
                collection: action.collection,
            });

        case FETCHING_USER:
            return Object.assign(baseState, {
                isFetching: true,
            });

        case FETCHING_USERS:
            return Object.assign(baseState, {
                isRequesting: true,
            });

        case FETCHING_USER_FAILED:
            return Object.assign(baseState, {
                isFetching: false,
            });

        case UPDATED_USER:
            return Object.assign(baseState, {
                isUpdating: false,
                data: undefined,
                users: {
                    [action.id]: Object.assign(baseState.users[action.id], action.data),
                }
            });

        case UPDATING_USER:
            return Object.assign(baseState, {
                isUpdating: true,
                data: action.data,
            });

        case UPDATING_USER_FAILED:
            return Object.assign(baseState, {
                isUpdating: false,
                data: undefined,
            });

        case LOGOUT:
            return Object.assign({}, defaultState);

        default:
            return Object.assign(baseState);
    }
}
