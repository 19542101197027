import { DateTime } from 'luxon';
import {
    UPDATING_ELOGBOOKS_PROPERTY_FILTERS,
    CLEARING_ELOGBOOKS_PROPERTY_FILTERS,
    FETCHING_ELOGBOOKS_PROPERTIES,
    FETCHED_ELOGBOOKS_PROPERTIES,
    FETCHING_ELOGBOOKS_PROPERTIES_FAILED,
} from 'actions/elogbooks/property';

const defaultFilterDates = {
    startDate: DateTime.local().minus({days: 2}),
    endDate: DateTime.local().plus({days: 3}),
};

const defaultState = {
    collection: {
        count: 0,
        sites: undefined,
        limit: 10,
        page: 1,
        pages: 0,
    },
    filters: {
        inactiveAtBetween: `${defaultFilterDates.startDate.toISODate()},${defaultFilterDates.endDate.toISODate()}`,
    },
    filterData: {
        inactiveAtBetween: {
            data: {
                startDate: defaultFilterDates.startDate,
                endDate: defaultFilterDates.endDate,
            },
            value: `${defaultFilterDates.startDate.toISODate()},${defaultFilterDates.endDate.toISODate()}`,
        },
    },
    isRequesting: false,
    elogbooksProperties: [],
};

export default function elogbooksProperty(state = defaultState, action)
{
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case FETCHING_ELOGBOOKS_PROPERTIES:
            return Object.assign(baseState, {
                isRequesting: true,
            });

        case FETCHED_ELOGBOOKS_PROPERTIES:
            return Object.assign(baseState, {
                isRequesting: false,
                collection: action.collection,
            });

        case FETCHING_ELOGBOOKS_PROPERTIES_FAILED:
            return Object.assign(baseState, {
                isRequesting: false,
            });

        case UPDATING_ELOGBOOKS_PROPERTY_FILTERS:
            return Object.assign(baseState, {
                filterData: action.filterData,
                filters: action.filters,
            });

        case CLEARING_ELOGBOOKS_PROPERTY_FILTERS:
            return Object.assign(baseState, {
                filterData: defaultState.filterData,
                filters: defaultState.filters,
                collection: defaultState.collection,
            });

        default:
            return Object.assign(baseState);
    }
}