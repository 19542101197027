import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, FormGroup, FormControl, FormLabel, FormText } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { update, fetchJob, fetchJobs } from 'actions/job';
import HTTP from 'services/HTTP';
import Response from 'services/Response';
import NumberFormatting from 'services/NumberFormatting';
import Elogbooks from 'services/Elogbooks';
import { fetchProperties } from 'services/data/Property';
import AsyncSelect from 'components/elements/AsyncSelect';
import Loading from 'components/higher-order-components/Loading';

const mapStateToProps = (state, ownProps) => {
    return {
        filters: state.job.filters,
        jobResponse: state.job.jobs[ownProps.match.params.id],
    };
};

const mapDispatchToProps = {
    update: update,
    fetchJob: fetchJob,
    fetchJobs: fetchJobs,
};

class JobEdit extends Component
{
    constructor(props) {
        super(props);

        const job = this.props.jobResponse || {};

        this.state = {
            approvalNote: job.wasAutoApproved ? '' : (job.approvalNote || ''),
            isIndividualInvoice: [true, false].indexOf(job.isIndividualInvoice) !== -1 ? (job.isIndividualInvoice ? 'true' : 'false') : undefined,
            isCis: [true, false].indexOf(job.isCis) !== -1 ? (job.isCis ? 'true' : 'false') : undefined,
            propertyId: undefined,
            useAlternateJobCode: [true, false].indexOf(job.useAlternateJobCode) !== -1 ? (job.useAlternateJobCode ? 'true' : 'false') : undefined,
            alternateJobCodeReason: '',

            client: undefined,
        };

        // Prefill with SP choice only if the real value was not set yet.
        if (this.state.isCis === undefined && [true, false].indexOf(job.isCisOriginal) !== -1) {
            this.state.isCis = job.isCisOriginal ? 'true' : 'false'
        }

        this.loadAlternateJobCodes = this.loadAlternateJobCodes.bind(this);
    }

    validateForm() {
        let valid = (
            this.state.approvalNote &&
            this.refs.approvalNote &&
            this.refs.approvalNote.validity.valid &&

            ["true", "false"].indexOf(this.state.isCis) !== -1 &&
            ["true", "false", undefined].indexOf(this.state.useAlternateJobCode) !== -1 &&
            (
                [undefined, 'false'].indexOf(this.state.useAlternateJobCode) !== -1 ||
                (
                    this.props.jobResponse.labourValueMarkup === 0 &&
                    this.props.jobResponse.materialValueMarkup === 0 &&
                    this.state.useAlternateJobCode === 'true' &&
                    typeof this.state.alternateJobCodeReason === 'string' &&
                    this.state.alternateJobCodeReason.length > 0 &&
                    typeof this.state.alternateJobCode === 'string'
                )
            )
        );

        return valid;
    }

    handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        const data ={
            isCis: this.state.isCis,
            approvalNote: this.state.approvalNote,
            isIndividualInvoice: this.state.isIndividualInvoice,
            property: this.state.propertyId || this.props.jobResponse['_links'].property.id,
            useAlternateJobCode: this.state.useAlternateJobCode,
            alternateJobCodeReason: this.state.alternateJobCodeReason,
            alternateJobCode: this.state.alternateJobCode,
        };

        if (this.props.jobResponse &&
            this.props.jobResponse.id) {
            return this.props
                .update(this.props.jobResponse.id, data)
                .then((response) => {
                    this.props.fetchJobs(this.props.filters);
                    this.props.history.push('/jobs');
                }, (response) => {
                    // @todo show an error
                });
        }
    }

    allowIndividualInvoiceSelection = () => {
        return typeof this.props.jobResponse.isCis === 'undefined' && !this.props.jobResponse.isIndividualInvoice;
    };

    onPropertySelect = (property) => {
        if (property && property.id) {
            return this.setState({'propertyId': property.id});
        }

        return this.setState({'propertyId': undefined});
    };

    render() {
        const { match, history } = this.props;
        const { id } = this.props.match.params;
        const cancelState = match.url.substring(0, match.url.lastIndexOf('/'));

        let property;
        if (this.props.jobResponse['_links'].property) {
            property = [{
                id: this.props.jobResponse['_links'].property.id,
                label: this.props.jobResponse['_links'].property.title,
            }];
        }

        return (
            <div className="JobEdit">
                <h1 className="clearfix">
                    { id ? 'Edit Job' : '' }
                </h1>

                {
                    this.props.jobResponse.wasAutoApproved !== true ?
                        null :
                        (
                            <Alert variant="warning">
                                This Job had its values auto approved
                            </Alert>
                        )
                }
                <form ref="form"
                    className="clearfix"
                    onSubmit={e => this.handleSubmit(e)}>
                    <FormGroup controlId="client" bssize="large">
                        <FormLabel>Client</FormLabel>
                        <FormText className="text-muted">
                            {Response.getLinkAttribute(this.props.jobResponse, 'client', 'title')}
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <FormLabel>Property</FormLabel>
                        <AsyncSelect onChange={this.onPropertySelect}
                            action={ (params) => {
                                return fetchProperties(Object.assign({}, params, {isActive: ['true']}));
                            } }
                            responseKey="properties"
                            defaultSelected={property} />
                    </FormGroup>
                    <FormGroup controlId="client" bssize="large">
                        <FormLabel>Service Provider</FormLabel>
                        <FormText className="text-muted">
                            {Response.getLinkAttribute(this.props.jobResponse, 'serviceprovider', 'title')}
                        </FormText>
                    </FormGroup>
                    <FormGroup controlId="elogbooksJobId" bssize="large">
                        <FormLabel>Elogbooks Job ID</FormLabel>
                        <FormText className="text-muted">
                            {this.props.jobResponse.elogbooksJobId}
                            &nbsp;
                            <a href={Elogbooks.createFrontendLink(
                                    Response.getLinkAttribute(this.props.jobResponse, 'client', 'installationUrl'),
                                    '/user/jobs/{job}/status',
                                    {
                                        job: this.props.jobResponse.elogbooksJobLink,
                                    }
                                )}
                                className="small"
                                target="_blank"
                                rel="noopener noreferrer">
                                View Job
                            </a>
                        </FormText>
                    </FormGroup>
                    <FormGroup controlId="summary" bssize="large">
                        <FormLabel>Summary</FormLabel>
                        <FormText className="text-muted">
                            {this.props.jobResponse.summary}
                        </FormText>
                    </FormGroup>
                    <FormGroup controlId="description" bssize="large"
                        className={this.props.jobResponse.description ? '' : 'hidden'}>
                        <FormLabel>Description</FormLabel>
                        <FormText className="text-muted">
                            {this.props.jobResponse.description}
                        </FormText>
                    </FormGroup>
                    <FormGroup controlId="labourValueServiceProvider" bssize="large">
                        <FormLabel>Labour Value</FormLabel>
                        <FormText className="text-muted">
                            {NumberFormatting.formatCurrency(this.props.jobResponse.labourValueServiceProvider)}
                            &nbsp;
                            (
                                { (this.props.jobResponse.labourValueMarkup >= 0 ? '+' : '') + NumberFormatting.format(this.props.jobResponse.labourValueMarkup, 2) }% &nbsp;
                                = &nbsp;
                                { NumberFormatting.formatCurrency(this.props.jobResponse.labourValueApproved)}
                            )
                        </FormText>
                    </FormGroup>
                    <FormGroup controlId="labourValueServiceProvider" bssize="large">
                        <FormLabel>Material Value</FormLabel>
                        <FormText className="text-muted">
                            {NumberFormatting.formatCurrency(this.props.jobResponse.materialValueServiceProvider)}
                            &nbsp;
                            (
                                { (this.props.jobResponse.materialValueMarkup >= 0 ? '+' : '') + NumberFormatting.format(this.props.jobResponse.materialValueMarkup, 2) }% &nbsp;
                                = &nbsp;
                                { NumberFormatting.formatCurrency(this.props.jobResponse.materialValueApproved)}
                            )
                        </FormText>
                    </FormGroup>
                    <FormGroup controlId="isIndividualInvoice" bssize="large">
                        <FormLabel>Is Individual Invoice?</FormLabel>
                        {this.allowIndividualInvoiceSelection() ? (
                            <FormControl as="select"
                                ref="isIndividualInvoice"
                                value={this.state.isIndividualInvoice}
                                onChange={e => {this.setState({'isIndividualInvoice': e.target.value})}}>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </FormControl>
                        ) : (
                            <FormText className="text-muted">
                                {(this.state.isIndividualInvoice ? 'Yes' : 'No')}
                            </FormText>
                        )}
                    </FormGroup>
                    <FormGroup controlId="approvalNote" bssize="large">
                        <FormLabel>Invoice Description</FormLabel>
                        <FormControl as="input"
                            ref="approvalNote"
                            value={this.state.approvalNote}
                            onChange={e => { this.setState({"approvalNote": e.target.value}) }}
                            maxLength={50} />
                    </FormGroup>
                    <FormGroup controlId="isCis" bssize="large">
                        <FormLabel>CIS?</FormLabel>
                        <FormControl as="select"
                            ref="isCis"
                            value={this.state.isCis}
                            onChange={e => { this.setState({"isCis": e.target.value}) }}>
                            <option value="undefined">None Selected</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </FormControl>
                    </FormGroup>
                    {
                        this.props.jobResponse.labourValueMarkup === 0 &&
                            this.props.jobResponse.materialValueMarkup === 0 ? (
                            <FormGroup controlId="useAlternateJobCode" bssize="large">
                                <FormLabel>Use Alternate Job Code?</FormLabel>
                                <FormControl as="select"
                                    ref="useAlternateJobCode"
                                    value={this.state.useAlternateJobCode}
                                    onChange={e => { this.setState({"useAlternateJobCode": e.target.value}) }}>
                                    <option value="undefined">None Selected</option>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </FormControl>
                            </FormGroup>
                        ) : null
                    }
                    {
                        this.props.jobResponse.labourValueMarkup === 0 &&
                            this.props.jobResponse.materialValueMarkup === 0 &&
                            this.state.useAlternateJobCode === 'true' ?
                        (
                            <Loading isLoading={ this.state.client === undefined }>
                                <FormGroup controlId="alternateJobCode" bssize="large">
                                    <FormLabel>Alternate Job Code</FormLabel>
                                    <FormControl as="select"
                                        ref="alternateJobCode"
                                        value={this.state.alternateJobCode}
                                        onChange={e => { this.setState({"alternateJobCode": e.target.value}) }}>
                                        <option value="undefined">None Selected</option>
                                        {
                                            (this.state.client.alternateJobCodes || []).map((alternateJobCode) => {
                                                return (
                                                    <option value={alternateJobCode.id} key={alternateJobCode.id}>{alternateJobCode.name}</option>
                                                );
                                            })
                                        }
                                    </FormControl>
                                </FormGroup>

                                <FormGroup controlId="alternateJobCodeReason" bssize="large">
                                    <FormLabel>Alternate Job Code Reason</FormLabel>
                                    <FormControl as="input"
                                        ref="alternateJobCodeReason"
                                        value={this.state.alternateJobCodeReason}
                                        onChange={e => { this.setState({"alternateJobCodeReason": e.target.value}) }}
                                        maxLength={2000} />
                                </FormGroup>
                            </Loading>
                        ) : null
                    }

                    <div className="float-right">
                        <Button onClick={() => history.push(cancelState)}
                            variant="default"
                            bssize="large"
                            to={cancelState}>
                            <FontAwesomeIcon icon={['fas', 'ban']} />
                            Cancel
                        </Button>

                        <Button type="submit"
                            className="margin-left"
                            bssize="large"
                            disabled={!this.validateForm()}>
                            <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                            { id ? 'Update' : 'Create' }
                        </Button>
                    </div>
                </form>
            </div>
        );
    }

    componentDidMount()
    {
        this.loadAlternateJobCodes();
    }

    loadAlternateJobCodes()
    {
        const job = this.props.jobResponse;

        HTTP
            .get(Response.getLink(job, 'client'))
            .then((response) => {
                this.setState({client: response.data});
            });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobEdit);
