import {
    FETCHED_JOB,
    FETCHED_JOBS,
    FETCHING_JOB,
    FETCHING_JOBS,
    FETCHING_JOB_FAILED,
    UPDATED_JOB,
    UPDATING_JOB,
    UPDATING_JOB_FAILED,
    CLEARING_JOB_FILTERS,
    UPDATING_JOB_FILTERS,
} from 'actions/job';
import { LOGOUT } from 'actions/auth';

const defaultState = {
    collection: {
        count: 0,
        jobs: undefined,
        limit: 10,
        page: 1,
        pages: 0,
    },
    data: undefined,
    filters: {
        isCis: ['~'],
        order: 'id',
    },
    filterData: {
        isCis: {
            value: ['~'],
            data: [{
                id: '~',
                label: 'Not Set',
            }],
        },
    },
    isRequesting: false,
    isUpdating: false,
    jobs: [],
};

export default function job(state = defaultState, action)
{
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {

        case CLEARING_JOB_FILTERS:
            return Object.assign(baseState, {
                filterData: defaultState.filterData,
                filters: defaultState.filters,
            });

        case UPDATING_JOB_FILTERS:
            return Object.assign(baseState, {
                filterData: action.filterData,
                filters: action.filters,
            });

        case FETCHED_JOB:
            return Object.assign(baseState, {
                isFetching: false,
                jobs: {
                    [action.response.id]: action.response,
                },
            });

        case FETCHED_JOBS:
            return Object.assign(baseState, {
                isRequesting: false,
                collection: action.collection,
            });

        case FETCHING_JOB:
            return Object.assign(baseState, {
                isFetching: true,
            });

        case FETCHING_JOBS:
            return Object.assign(baseState, {
                isRequesting: true,
            });

        case FETCHING_JOB_FAILED:
            return Object.assign(baseState, {
                isFetching: false,
            });

        case UPDATED_JOB:
            return Object.assign(baseState, {
                isUpdating: false,
                data: undefined,
                jobs: {
                    [action.id]: Object.assign(baseState.jobs[action.id], action.data),
                }
            });

        case UPDATING_JOB:
            return Object.assign(baseState, {
                isUpdating: true,
                data: action.data,
            });

        case UPDATING_JOB_FAILED:
            return Object.assign(baseState, {
                isUpdating: false,
                data: undefined,
            });


        case LOGOUT:
            return Object.assign({}, defaultState);

        default:
            return Object.assign(baseState);
    }
}
