import HTTP from 'services/HTTP';

export {
    fetchClients,
    fetchClient,
};

function fetchClients(params = {})
{
    return HTTP
        .get('/clients', params)
        .then((response) => {
            return response.data;
        }).catch(() => {
            return false;
        });
}

function fetchClient(id)
{
    return HTTP
        .get(`/clients/${id}`, {datagroup: 'details'})
        .then((response) => {
            return response.data;
        }).catch(() => {
            return false;
        });
}
