import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { fetchClient } from 'actions/client';
import Loading from 'components/higher-order-components/Loading';
import Reload from 'components/elements/Reload';
import JWT from 'services/JWT';

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching: state.client.isFetching,
        clientResponse: state.client.clients[ownProps.match.params.id],
    };
};

const mapDispatchToProps = {
    fetchClient: fetchClient,
};

class ClientDetails extends Component
{
    constructor(props) {
        super(props);

        this.load = this.load.bind(this);
    }

    render() {
        const { match, history } = this.props;
        const client = this.props.clientResponse || {};

        return (
            <div className="ClientDetails">
                <Loading isLoading={this.props.isFetching} size="md">
                    <h1 className="clearfix">
                        {client.name}

                        <Reload load={this.load} />

                        { JWT.hasRole('ROLE_ADMINISTRATOR') === false ? null :
                            <Button className="float-right"
                                size="sm"
                                onClick={() => history.push(`${match.url}/edit`)}>
                                Edit
                            </Button>
                        }
                    </h1>

                    <dl>
                        <dt>Installation API Url</dt>
                        <dd>
                            <a href={client.installationUrl}
                                target="_blank"
                                rel="noopener noreferrer">
                                {client.installationUrl}
                            </a>
                        </dd>
                        <dt>Is Sharing only client?</dt>
                        <dd>{client.isSharingClient ? 'Yes' : 'No'}</dd>
                        <dt>Service Provider ID</dt>
                        <dd>{client.serviceProviderId}</dd>
                        <dt>Cost Centre</dt>
                        <dd>{client.costCentre}</dd>
                        <dt>Status</dt>
                        <dd>{client.isActive ? 'Active' : 'Inactive'}</dd>
                    </dl>

                    { this.listAlternateJobCodes() }
                </Loading>
            </div>
        );
    }

    listAlternateJobCodes()
    {
        const client = this.props.clientResponse || {};
        let alternateJobCodes = client.alternateJobCodes || [];
        let alternateJobCodesCopy = alternateJobCodes.slice();

        let rows = [];
        while (alternateJobCodesCopy.length >= 1) {
            rows.push(alternateJobCodesCopy.splice(0, 3));
        }

        return (
            <>
                <h2>
                    Alternate Job Codes
                </h2>
                <Container fluid className="no-padding">
                    { rows.map((row, index) => {
                        return (
                            <Row sm={3} md={3} lg={3} xl={3} noGutters={true} key={index}>
                                { row.map((item) => {
                                    return (
                                        <Col sm={4} className="bordered" key={item.id + '-' + item.name}>
                                            <div className="content">
                                                <h3>{ item.name }</h3>
                                                <dl>
                                                    <dt>Job Code</dt>
                                                    <dd>{ item.code }</dd>
                                                    <dt>Generates Invoice?</dt>
                                                    <dd>{ item.generatesInvoice ? 'Yes' : 'No' }</dd>
                                                    <dt>Exchequer Customer ID</dt>
                                                    <dd>{ item.exchequerCustomerId }</dd>
                                                </dl>
                                            </div>
                                        </Col>
                                    );
                                }) }
                            </Row>
                        );
                    }) }
                </Container>
            </>
        );
    }

    componentDidMount() {
        this.load();
    }

    load() {
        const { id } = this.props.match.params;

        this.props.fetchClient(id);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
