import React, { Component } from 'react';
import { Button, FormGroup, FormLabel, FormText } from 'react-bootstrap';
import AsyncSelect from 'components/elements/AsyncSelect';
import DatePicker from 'components/elements/DatePicker';
import Filters from 'components/elements/Filters';
import ItemCount from 'components/elements/ItemCount';
import NoResultsFound from 'components/higher-order-components/NoResultsFound';
import Table from 'components/elements/Table';
import Paginator from 'components/elements/Paginator';
import Loading from 'components/higher-order-components/Loading';
import Data from 'services/Data';
import { fetchClients } from 'services/data/Client';
import { fetchProperties } from 'services/data/Property';
import { fetchServiceProviders } from 'services/data/ServiceProvider';
import { fetchPurchaseInvoices } from 'services/data/PurchaseInvoice';
import { create } from 'services/data/SalesInvoice';
import { DateTime } from 'luxon';
import lodash from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import { STATE_INVOICED } from 'constants/purchaseinvoice';

class SalesInvoiceAdd extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            clientId: undefined,
            propertyId: undefined,
            transactionDate: undefined,
            purchaseInvoicesLoading: false,
            purchaseInvoiceCollection: Data.generateCollectionResponse('purchaseInvoices'),
            purchaseInvoiceFilters: {},
            purchaseInvoiceFilterData: {},
            selectedPurchaseInvoices: [],
            showBackdateConfirmation: false,
        };
    }

    render() {
        const propertyRequestFilters = {
            client: this.state.clientId,
            isActive: [1],
            isOnHold: [0],
        };

        const purchaseInvoiceColumns = [
            {header: 'Exchequer PIN', width: 2, visible: true, key: 'exchequerPurchaseInvoiceNumber', },
            {header: 'Elogbooks Job Number', width: 2, visible: true, key: '_links.job.elogbooksJobId', },
            {header: 'Service Provider', width: 2, visible: true, key: '_links.serviceProvider.name', },
            {
                header: '',
                width: 2,
                visible: true,
                type: 'button',
                text: (value) => {
                    return lodash.find(this.state.selectedPurchaseInvoices, {id: value.id}) ? 'Selected' : 'Select';
                },
                fixedRight: true,
                onClick: this.addPurchaseInvoice,
                disabled: (value) => {
                    return !!lodash.find(this.state.selectedPurchaseInvoices, {id: value.id});
                },
            },
        ];

        const selectedPurchaseInvoiceColumns = [
            {header: 'Exchequer PIN', width: 2, visible: true, key: 'exchequerPurchaseInvoiceNumber', },
            {header: 'Elogbooks Job Number', width: 2, visible: true, key: '_links.job.elogbooksJobId', },
            {header: 'Service Provider', width: 2, visible: true, key: '_links.serviceProvider.name', },
            {
                header: '',
                width: 2,
                visible: true,
                type: 'button',
                text: (value) => {
                    return 'Remove';
                },
                fixedRight: true,
                onClick: this.removePurchaseInvoice,
            },
        ];

        return (
            <div className="SalesInvoiceAdd">
                <h1 className="clearfix">
                    Create a New Sales Invoice
                </h1>

                <form ref="form"
                    className="clearfix"
                    onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <FormLabel>Client</FormLabel>
                        <AsyncSelect onChange={this.onClientSelect}
                            action={fetchClients}
                            responseKey="clients" />
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Property</FormLabel>
                        <AsyncSelect onChange={this.onPropertySelect}
                            action={fetchProperties}
                            disabled={!this.state.clientId}
                            responseKey="properties"
                            requestFilters={propertyRequestFilters} />
                        <FormText className="text-muted"><strong>Only active, not held properties will be shown</strong></FormText>
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Transaction Date</FormLabel>
                        <DatePicker onChange={this.onTransactionDateSelect} />
                        <FormText className="text-muted"><strong>If no transaction date is selected, the date of sales invoice approval will be sent to Exchequer</strong></FormText>
                    </FormGroup>

                    { !this.state.clientId || !this.state.propertyId ? null : (
                        <>
                            <hr />

                            <div className="mt-3 clearfix">
                                <Filters onFilter={this.updatePurchaseInvoiceFilters}
                                    onClear={this.clearPurchaseInvoiceFilters}
                                    filterData={this.state.purchaseInvoiceFilterData}
                                    title="Find Purchase Invoices - Filters"
                                    updateUrl={false}
                                    open>
                                    <Filters.Text label="Exchequer PIN"
                                        filterKey="exchequerPin" />
                                    <Filters.Text label="ELB Job Number"
                                        filterKey="elogbooksJobNumber" />
                                    <Filters.SingleSelect action={fetchServiceProviders}
                                        label="Service Provider"
                                        filterKey="serviceProvider"
                                        responseKey="serviceProviders" />
                                </Filters>

                                <Loading isLoading={this.state.purchaseInvoicesLoading}>
                                    <NoResultsFound count={this.state.purchaseInvoiceCollection.count}>
                                        <Table data={this.state.purchaseInvoiceCollection.purchaseInvoices || []}
                                            columns={purchaseInvoiceColumns}
                                            collection={this.state.purchaseInvoiceCollection} />

                                        <Paginator page={this.state.purchaseInvoiceCollection.page}
                                            count={this.state.purchaseInvoiceCollection.count}
                                            limit={this.state.purchaseInvoiceCollection.limit}
                                            onPage={this.changePurchaseInvoicePage}/>
                                    </NoResultsFound>
                                </Loading>
                            </div>

                            <hr />

                            <div className="mt-3 clearfix">
                                <h2>
                                    Selected Purchase Invoices
                                    <ItemCount count={this.state.selectedPurchaseInvoices.length} />
                                </h2>
                                <NoResultsFound count={this.state.selectedPurchaseInvoices.length}
                                    label="No Purchase Invoices selected, use the table above to selected some">
                                    <Table data={this.state.selectedPurchaseInvoices}
                                        columns={selectedPurchaseInvoiceColumns}
                                        numberOfRows={this.state.selectedPurchaseInvoices.length} />
                                </NoResultsFound>
                            </div>
                        </>
                    )}

                    <div className="mt-3 float-right">
                        <Button onClick={() => this.props.history.push('/sales-invoices')}
                                variant="default"
                                bssize="large">
                            <FontAwesomeIcon icon={['fas', 'ban']} />
                            Cancel
                        </Button>

                        <Button type="submit"
                                className="margin-left"
                                bssize="large"
                                disabled={!this.validateForm()}>
                            <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                            Create
                        </Button>
                    </div>
                </form>

                <ConfirmationDialog show={this.state.showBackdateConfirmation}
                    hide={() => this.setState({showBackdateConfirmation: false})}
                    body="You have entered a Transaction Date in the past, please confirm that this is correct."
                    onConfirm={this.createSalesInvoice} />
            </div>
        );
    }

    validateForm() {
        return (
            this.state.clientId &&
            this.state.propertyId &&
            this.state.selectedPurchaseInvoices.length > 0
        );
    }

    handleSubmit = (event) => {
        event.preventDefault();

        if (this.state.transactionDate < DateTime.local().startOf('day')) {
            return this.setState({
                showBackdateConfirmation: true,
            });
        }

        this.createSalesInvoice();
    };

    createSalesInvoice = () => {
        this.setState({
            showBackdateConfirmation: false,
        });

        const data = {
            clientId: this.state.clientId,
            propertyId: this.state.propertyId,
            transactionDate: this.state.transactionDate ? this.state.transactionDate.toLocaleString(DateTime.DATE_SHORT) : null,
            purchaseInvoices: this.state.selectedPurchaseInvoices.map((purchaseInvoice) => {
                return purchaseInvoice.id;
            }),
        };

        create(data).then((response) => {
            if (response) {
                this.props.history.push(`/sales-invoices/${response.id}`);
            }
        });
    };

    onClientSelect = (client) => {
        if (client && client.id) {
            return this.setState({'clientId': client.id}, () => {
                this.loadPurchaseInvoices();
            });
        }

        return this.setState({'clientId': undefined});
    };

    onPropertySelect = (property) => {
        if (property && property.id) {
            return this.setState({'propertyId': property.id}, () => {
                this.loadPurchaseInvoices();
            });
        }

        return this.setState({'propertyId': undefined});
    };

    loadPurchaseInvoices = () => {
        if (!this.state.clientId || !this.state.propertyId) {
            return;
        }

        this.setState({
            purchaseInvoicesLoading: true,
        });

        const filters = {
            state: [STATE_INVOICED],
            client: this.state.clientId,
            property: this.state.propertyId,
            ...this.state.purchaseInvoiceFilters,
        };

        fetchPurchaseInvoices(filters).then((purchaseInvoices) => {
            this.setState({
                purchaseInvoiceCollection: purchaseInvoices,
                purchaseInvoicesLoading: false,
            });
        });
    };

    updatePurchaseInvoiceFilters = (filters, filterData) => {
        this.setState({
            purchaseInvoiceFilters: filters,
            purchaseInvoiceFilterData: filterData,
        }, () => {
            this.loadPurchaseInvoices();
        });
    };

    clearPurchaseInvoiceFilters = () => {
        this.setState({
            purchaseInvoiceFilters: {},
            purchaseInvoiceFilterData: {},
        }, () => {
            this.loadPurchaseInvoices();
        });
    };

    changePurchaseInvoicePage = (page) => {
        this.setState({
            purchaseInvoiceFilters: Object.assign(
                {},
                this.state.purchaseInvoiceFilters,
                {page: page},
            ),
        }, () => {
            this.loadPurchaseInvoices();
        });
    };

    addPurchaseInvoice = (rowIndex) => {
        this.setState({
            selectedPurchaseInvoices: [
                ...this.state.selectedPurchaseInvoices,
                this.state.purchaseInvoiceCollection.purchaseInvoices[rowIndex],
            ],
        });
    };

    removePurchaseInvoice = (rowIndex) => {
        this.setState({
            selectedPurchaseInvoices: this.state.selectedPurchaseInvoices.filter((_, i) => i !== rowIndex),
        });
    };

    onTransactionDateSelect = (date) => {
        this.setState({
            transactionDate: date,
        });
    };
}

export default SalesInvoiceAdd;
