import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, FormGroup, FormControl, FormLabel, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { create, update, fetchClient } from 'actions/client';
import Response from 'services/Response';

const mapStateToProps = (state, ownProps) => {
    return {
        clientResponse: state.client.clients[ownProps.match.params.id],
    };
};

const mapDispatchToProps = {
    create: create,
    update: update,
    fetchClient: fetchClient,
};


class ClientAddEdit extends Component
{
    defaultAlternateJobCode;

    constructor(props) {
        super(props);

        const client = this.props.clientResponse || {};
        this.defaultAlternateJobCode = {
            _links: {
                client: {
                    href: Response.getLink(client, 'self'),
                },
            },
            code: undefined,
            name: undefined,
            exchequerCustomerId: undefined,
            generatesInvoice: undefined,
        };

        this.state = {
            name: client.name || "",
            installationUrl: client.installationUrl || "",
            token: client.token || "",
            serviceProviderId: client.serviceProviderId || "",
            costCentre: client.costCentre || "",
            isActive: [true, false].indexOf(client.isActive) !== -1 ? (client.isActive ? 'true' : 'false') : 'false',
            isSharingClient: client.isSharingClient || 0,
            alternateJobCodes: client.alternateJobCodes || [this.defaultAlternateJobCode],
        };
    }

    validateForm() {
        return (this.state.name &&
            this.state.installationUrl &&
            this.state.token &&
            this.state.serviceProviderId &&
            this.state.costCentre &&
            ["true", "false"].indexOf(this.state.isActive) !== -1 &&

            this.refs.name &&
            this.refs.installationUrl &&
            this.refs.token &&
            this.refs.serviceProviderId &&
            this.refs.costCentre &&

            this.refs.name.validity.valid &&
            this.refs.installationUrl.validity.valid &&
            this.refs.token.validity.valid &&
            this.refs.serviceProviderId.validity.valid &&
            this.refs.costCentre.validity.valid);
    }

    handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        const data = {
            name: this.state.name,
            installationUrl: this.state.installationUrl,
            token: this.state.token,
            serviceProviderId: this.state.serviceProviderId,
            costCentre: this.state.costCentre,
            isActive: this.state.isActive,
            isSharingClient: this.state.isSharingClient,
            alternateJobCodes: this.state.alternateJobCodes.filter(item => {
                return typeof item.code === 'string' &&
                    item.code.length >= 1 &&
                    typeof item.name === 'string' &&
                    item.name.length >= 1 &&
                    typeof item.exchequerCustomerId === 'string' &&
                    item.exchequerCustomerId.length >= 1;
            }).map(item => {
                if ([true, false].indexOf(item.generatesInvoice) !== -1) {
                    item.generatesInvoice = item.generatesInvoice.toString();
                }

                return item;
            }),
        };

        if (this.props.clientResponse &&
            this.props.clientResponse.id) {
            return this.props
                .update(this.props.clientResponse.id, data)
                .then((response) => {
                    this.props.history.push('/clients/' + this.props.clientResponse.id);
                }, (response) => {
                    // @todo show an error
                });
        }

        return this.props
            .create(data)
            .then((response) => {
                this.props.history.push('/clients/');
            }, (response) => {
                // @todo show an error
            });
    }

    render() {
        const { match, history } = this.props;
        const { id } = this.props.match.params;
        const cancelState = match.url.substring(0, match.url.lastIndexOf('/'));

        return (
            <div className="ClientAddEdit">
                <h1 className="clearfix">
                    { id ? 'Edit Client' : 'Add a New Client' }
                </h1>

                <form ref="form"
                    className="clearfix"
                    onSubmit={e => this.handleSubmit(e)}>
                    <FormGroup controlId="name" bssize="large">
                        <FormLabel>Name</FormLabel>
                        <FormControl as="input"
                            ref="name"
                            value={this.state.name}
                            onChange={e => { this.setState({"name": e.target.value}) }}
                            autoFocus />
                    </FormGroup>
                    <FormGroup controlId="installationUrl" bssize="large">
                        <FormLabel>Installation API URL</FormLabel>
                        <FormControl as="input"
                            ref="installationUrl"
                            value={this.state.installationUrl}
                            onChange={e => { this.setState({"installationUrl": e.target.value}) }} />
                    </FormGroup>
                    <FormGroup controlId="token" bssize="large">
                        <FormLabel>Authentication Token</FormLabel>
                        <FormControl as="textarea"
                            ref="token"
                            value={this.state.token}
                            onChange={e => { this.setState({"token": e.target.value}) }} />
                    </FormGroup>
                    <FormGroup controlId="serviceProviderId" bssize="large">
                        <FormLabel>Service Provider ID</FormLabel>
                        <FormControl as="input"
                            type="number"
                            min="1"
                            ref="serviceProviderId"
                            value={this.state.serviceProviderId}
                            onChange={e => { this.setState({"serviceProviderId": e.target.value}) }} />
                    </FormGroup>
                    <FormGroup controlId="costCentre" bssize="large">
                        <FormLabel>Cost Centre</FormLabel>
                        <FormControl as="input"
                            ref="costCentre"
                            value={this.state.costCentre}
                            onChange={e => { this.setState({"costCentre": e.target.value}) }} />
                    </FormGroup>
                    <FormGroup controlId="isActive" bssize="large">
                        <FormLabel>Status</FormLabel>
                        <FormControl as="select"
                            ref="isActive"
                            value={this.state.isActive}
                            onChange={e => { this.setState({"isActive": e.target.value}) }}>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                        </FormControl>
                    </FormGroup>
                    <FormGroup controlId="isActive" bssize="large">
                        <FormLabel>Is Sharing Only Client?</FormLabel>
                        <FormControl as="select"
                                     ref="isSharingClient"
                                     value={this.state.isSharingClient}
                                     onChange={e => { this.setState({"isSharingClient": e.target.value}) }}>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </FormControl>
                    </FormGroup>

                    { this.listAlternateJobCodes() }

                    <div className="float-right">
                        <Button onClick={() => history.push(cancelState)}
                            variant="default"
                            bssize="large"
                            to={cancelState}>
                            <FontAwesomeIcon icon={['fas', 'ban']} />
                            Cancel
                        </Button>

                        <Button type="submit"
                            className="margin-left"
                            bssize="large"
                            disabled={!this.validateForm()}>
                            <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                            { id ? 'Update' : 'Create' }
                        </Button>
                    </div>
                </form>
            </div>
        );
    }

    listAlternateJobCodes()
    {
        const { alternateJobCodes } = this.state;
        const alternateJobCodesCopy = alternateJobCodes.slice();

        if (alternateJobCodesCopy.length === 0) {
            alternateJobCodesCopy.push({});
        }

        let rows = [];
        while (alternateJobCodesCopy.length >= 1) {
            rows.push(alternateJobCodesCopy.splice(0, 3));
        }

        return (
            <>
                <h2>
                    Alternate Job Codes
                </h2>
                <Container fluid className="no-padding">
                    { rows.map((row, index) => {
                        return (
                            <Row sm={3} md={3} lg={3} xl={3} noGutters={true} key={index}>
                            { row.map((item, idx) => {
                                return (
                                    <Col sm={4} className="bordered" key={'Col-' + index + '-' + idx}>
                                        <div className="content">
                                            <FormGroup controlId={'alternateJobCode-name-' + index + '-' + idx} bssize="large">
                                                <FormLabel>Name</FormLabel>
                                                <FormControl as="input"
                                                    type="text"
                                                    ref={"name-" + index + '-' + idx}
                                                    value={ item.name || '' }
                                                    minLength={2}
                                                    maxLength={60}
                                                    onChange={e => {
                                                        let itemIndex = ((index * 3) + idx);
                                                        alternateJobCodes[itemIndex] = Object.assign({}, alternateJobCodes[itemIndex], {name: e.target.value});

                                                        this.setState({alternateJobCodes: alternateJobCodes}, this.checkForEmptyAlternateJobCodes);
                                                    }} />
                                            </FormGroup>
                                            <FormGroup controlId={'alternateJobCode-code-' + index + '-' + idx} bssize="large">
                                                <FormLabel>Job Code</FormLabel>
                                                <FormControl as="input"
                                                    type="text"
                                                    ref={'jobCode-' + index + '-' + idx}
                                                    value={ item.code || '' }
                                                    minLength={0}
                                                    maxLength={255}
                                                    onChange={e => {
                                                        let itemIndex = ((index * 3) + idx);
                                                        alternateJobCodes[itemIndex] = Object.assign({}, alternateJobCodes[itemIndex], {code: e.target.value});

                                                        this.setState({alternateJobCodes: alternateJobCodes}, this.checkForEmptyAlternateJobCodes);
                                                    }} />
                                            </FormGroup>
                                            <FormGroup controlId={'alternateJobCode-generatesInvoice-' + index + '-' + idx} bssize="large">
                                                <FormLabel>Generates Invoice?</FormLabel>
                                                <FormControl as="select"
                                                    ref={'generatesInvoice-' + index + '-' + idx}
                                                    value={ typeof item.generatesInvoice === 'undefined' ? 'undefined' : item.generatesInvoice.toString() }
                                                    onChange={e => {
                                                        let itemIndex = ((index * 3) + idx);
                                                        alternateJobCodes[itemIndex] = Object.assign({}, alternateJobCodes[itemIndex], {generatesInvoice: e.target.value});

                                                        this.setState({alternateJobCodes: alternateJobCodes}, this.checkForEmptyAlternateJobCodes);
                                                    }}>
                                                    <option value="undefined">None Selected</option>
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </FormControl>
                                            </FormGroup>
                                            <FormGroup controlId={'alternateJobCode-exchequerCustomer-' + index + '-' + idx} bssize="large">
                                                <FormLabel>Exchequer Customer ID</FormLabel>
                                                <FormControl as="input"
                                                    type="text"
                                                    ref={'exchequerCustomer-' + index + '-' + idx}
                                                    value={ item.exchequerCustomerId || '' }
                                                    minLength={0}
                                                    maxLength={255}
                                                    onChange={e => {
                                                        let itemIndex = ((index * 3) + idx);
                                                        alternateJobCodes[itemIndex] = Object.assign({}, alternateJobCodes[itemIndex], {exchequerCustomerId: e.target.value});

                                                        this.setState({alternateJobCodes: alternateJobCodes}, this.checkForEmptyAlternateJobCodes);
                                                    }} />
                                            </FormGroup>
                                        </div>
                                    </Col>
                                );
                            }) }
                            </Row>
                        );
                    }) }
                </Container>
            </>
        );
    }

    checkForEmptyAlternateJobCodes()
    {
        const { alternateJobCodes } = this.state;

        let hasEmptyCode = false;
        for (let i in alternateJobCodes) {
            let alternateJobCode = alternateJobCodes[i];

            if (typeof alternateJobCode.code === 'undefined' ||
                alternateJobCode.code.length === 0 ||
                typeof alternateJobCode.name === 'undefined' ||
                alternateJobCode.name.length === 0 ||
                typeof alternateJobCode.exchequerCustomerId === 'undefined' ||
                alternateJobCode.exchequerCustomerId.length === 0) {
                hasEmptyCode = true;
            }
        }

        if (!hasEmptyCode) {
            let newAlternateJobCodes = alternateJobCodes.slice();
                newAlternateJobCodes.push(this.defaultAlternateJobCode);
            this.setState({alternateJobCodes: newAlternateJobCodes});
        }
    }

    componentDidMount()  {
        const { id } = this.props.match.params;

        if (typeof id === 'string' &&
            typeof this.props.clientResponse === 'undefined') {
            this.load(id);
        }

        this.checkForEmptyAlternateJobCodes();
    }

    load(id) {
        this.props.fetchClient(id);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientAddEdit);
