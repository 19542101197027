import HTTP from 'services/HTTP';

export const CLEARING_PENDING_JOB_FILTERS = 'CLEARING_PENDING_JOB_FILTERS';
export const FETCHED_COMPLETED_PENDING_VALUE_APPROVAL_JOBS_BY_CLIENT = 'FETCHED_COMPLETED_PENDING_VALUE_APPROVAL_JOBS_BY_CLIENT';
export const FETCHED_COMPLETED_PENDING_VALUE_JOBS_BY_CLIENT = 'FETCHED_COMPLETED_PENDING_VALUE_JOBS_BY_CLIENT';
export const FETCHED_COMPLETED_REJECTED_VALUE_JOBS_BY_CLIENT = 'FETCHED_COMPLETED_REJECTED_VALUE_JOBS_BY_CLIENT';
export const FETCHED_OPEN_JOBS_BY_CLIENT = 'FETCHED_OPEN_JOBS_BY_CLIENT';
export const FETCHING_COMPLETED_PENDING_VALUE_APPROVAL_JOBS_BY_CLIENT = 'FETCHING_COMPLETED_PENDING_VALUE_APPROVAL_JOBS_BY_CLIENT';
export const FETCHING_COMPLETED_PENDING_VALUE_JOBS_BY_CLIENT = 'FETCHING_COMPLETED_PENDING_VALUE_JOBS_BY_CLIENT';
export const FETCHING_COMPLETED_REJECTED_VALUE_JOBS_BY_CLIENT = 'FETCHING_COMPLETED_REJECTED_VALUE_JOBS_BY_CLIENT';
export const FETCHING_OPEN_JOBS_BY_CLIENT = 'FETCHING_OPEN_JOBS_BY_CLIENT';
export const FETCHING_OPEN_JOBS_BY_CLIENT_FAILED = 'FETCHING_OPEN_JOBS_BY_CLIENT_FAILED';
export const UPDATING_PENDING_JOB_FILTERS = 'UPDATING_PENDING_JOB_FILTERS';
export const UPDATING_PENDING_JOB_ORDERING = 'UPDATING_PENDING_JOB_ORDERING';

export {
    fetchCompletedPendingValueApprovalJobsByClient,
    fetchCompletedPendingValueJobsByClient,
    fetchCompletedRejectedValueJobsByClient,
    fetchOpenJobsByClient,
    updatePendingJobFilters,
    clearPendingJobFilters,
    updatePendingJobOrdering,
};

function updatePendingJobOrdering(ordering)
{
    return dispatch => {
        dispatch(updatingPendingJobOrdering(ordering));
    };
}

function updatePendingJobFilters(filters, filterData)
{
    return dispatch => {
        dispatch(updatingPendingJobFilters(filters, filterData));
    };
}

function clearPendingJobFilters()
{
    return dispatch => {
        dispatch(clearingPendingJobFilters());
    };
}

function fetchOpenJobsByClient(client, params = {})
{
    return dispatch => {
        dispatch(fetchingOpenJobsByClient(client, params));

        return HTTP
            .get('/clients/' + client + '/elogbooks/jobs/open', params)
            .then((response) => {
                dispatch(fetchedOpenJobsByClient(client, response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingOpenJobsByClientFailed(client, response.data));

                return false;
            });
    };
}

function fetchCompletedPendingValueJobsByClient(client, params = {})
{
    return dispatch => {
        dispatch(fetchingCompletedPendingValueJobsByClient(client, params));

        return HTTP
            .get('/clients/' + client + '/elogbooks/jobs/pendingvalue', params)
            .then((response) => {
                dispatch(fetchedCompletedPendingValueJobsByClient(client, response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingCompletedPendingValueJobsByClientFailed(client, response.data));

                return false;
            });
    };
}

function fetchCompletedRejectedValueJobsByClient(client, params = {})
{
    return dispatch => {
        dispatch(fetchingCompletedRejectedValueJobsByClient(client, params));

        return HTTP
            .get('/clients/' + client + '/elogbooks/jobs/rejected', params)
            .then((response) => {
                dispatch(fetchedCompletedRejectedValueJobsByClient(client, response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingCompletedRejectedValueJobsByClientFailed(client, response.data));

                return false;
            });
    };
}

function fetchCompletedPendingValueApprovalJobsByClient(client, params = {})
{
    return dispatch => {
        dispatch(fetchingCompletedPendingValueApprovalJobsByClient(client, params));

        return HTTP
            .get('/clients/' + client + '/elogbooks/jobs/pendingvalueapproval', params)
            .then((response) => {
                dispatch(fetchedCompletedPendingValueApprovalJobsByClient(client, response.data));

                return response.data;
            }, (response) => {
                dispatch(fetchingCompletedPendingValueApprovalJobsByClientFailed(client, response.data));

                return false;
            });
    };
}

function updatingPendingJobOrdering(ordering)
{
    return {
        type: UPDATING_PENDING_JOB_ORDERING,
        ordering: ordering,
    };
}

function updatingPendingJobFilters(filters, filterData)
{
    return {
        type: UPDATING_PENDING_JOB_FILTERS,
        filters: filters,
        filterData: filterData,
    };
}

function clearingPendingJobFilters()
{
    return {
        type: CLEARING_PENDING_JOB_FILTERS,
    };
}

function fetchingOpenJobsByClient(client, params = {})
{
    return {
        type: FETCHING_OPEN_JOBS_BY_CLIENT,
        client: client,
        filters: params,
    };
}

function fetchedOpenJobsByClient(client, response)
{
    return {
        type: FETCHED_OPEN_JOBS_BY_CLIENT,
        client: client,
        response: response,
    };
}

function fetchingOpenJobsByClientFailed(client, response)
{
    return {
        type: FETCHING_OPEN_JOBS_BY_CLIENT_FAILED,
        client: client,
        response: response,
    };
}

function fetchingCompletedPendingValueJobsByClient(client, params = {})
{
    return {
        type: FETCHING_COMPLETED_PENDING_VALUE_JOBS_BY_CLIENT,
        client: client,
        filters: params,
    };
}

function fetchedCompletedPendingValueJobsByClient(client, response)
{
    return {
        type: FETCHED_COMPLETED_PENDING_VALUE_JOBS_BY_CLIENT,
        client: client,
        response: response,
    };
}

function fetchingCompletedPendingValueJobsByClientFailed(client, response)
{}

function fetchingCompletedRejectedValueJobsByClient(client, params = {})
{
    return {
        type: FETCHING_COMPLETED_REJECTED_VALUE_JOBS_BY_CLIENT,
        client: client,
        filters: params,
    };
}

function fetchedCompletedRejectedValueJobsByClient(client, response)
{
    return {
        type: FETCHED_COMPLETED_REJECTED_VALUE_JOBS_BY_CLIENT,
        client: client,
        response: response,
    };
}

function fetchingCompletedRejectedValueJobsByClientFailed(client, response)
{}

function fetchingCompletedPendingValueApprovalJobsByClient(client, params = {})
{
    return {
        type: FETCHING_COMPLETED_PENDING_VALUE_APPROVAL_JOBS_BY_CLIENT,
        client: client,
        filters: params,
    };
}

function fetchedCompletedPendingValueApprovalJobsByClient(client, response)
{
    return {
        type: FETCHED_COMPLETED_PENDING_VALUE_APPROVAL_JOBS_BY_CLIENT,
        client: client,
        response: response,
    };
}

function fetchingCompletedPendingValueApprovalJobsByClientFailed(client, response)
{}
