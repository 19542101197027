import {
    FETCHED_JOB_ERROR,
    FETCHED_JOB_ERRORS,
    FETCHING_JOB_ERROR,
    FETCHING_JOB_ERRORS,
    FETCHING_JOB_ERROR_FAILED,
} from 'actions/joberror';
import { LOGOUT } from 'actions/auth';

const defaultState = {
    collection: {
        count: 0,
        errors: undefined,
        limit: 10,
        page: 1,
        pages: 0,
    },
    data: undefined,
    errors: [],
    filters: {},
    isRequesting: false,
};

export default function joberror(state = defaultState, action)
{
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case FETCHED_JOB_ERROR:
            return Object.assign(baseState, {
                isFetching: false,
                errors: {
                    [action.response.id]: action.response,
                },
            });

        case FETCHED_JOB_ERRORS:
            return Object.assign(baseState, {
                isRequesting: false,
                collection: action.collection,
            });

        case FETCHING_JOB_ERROR:
            return Object.assign(baseState, {
                isFetching: true,
            });

        case FETCHING_JOB_ERRORS:
            return Object.assign(baseState, {
                isRequesting: true,
            });

        case FETCHING_JOB_ERROR_FAILED:
            return Object.assign(baseState, {
                isFetching: false,
            });

        case LOGOUT:
            return Object.assign({}, defaultState);

        default:
            return Object.assign(baseState);
    }
}
