import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Container, Row, Col, Accordion } from 'react-bootstrap';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import lodash from 'lodash';
import ItemCount from 'components/elements/ItemCount';
import { withRouter } from 'react-router-dom';
import FiltersService from 'services/Filters';
import queryString from 'querystring';
import DateRangePicker from 'components/elements/DateRangePicker';
import { DateTime } from 'luxon';

class Filters extends Component
{
    static propTypes = {
        onFilter: PropTypes.func.isRequired,
        onClear: PropTypes.func.isRequired,
        filterData: PropTypes.object.isRequired,
        open: PropTypes.bool,
        title: PropTypes.string,
        updateUrl: PropTypes.bool,
    };

    static defaultProps = {
        open: false,
        title: 'Filters',
        updateUrl: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            filterData: this.props.filterData,
            filterGrid: this.buildFilterGrid(),
            clearing: false,
        };
    }

    componentDidMount() {
        this.setUrl(true);
    }

    componentDidUpdate(prevProps) {
        if (!lodash.isEqual(prevProps.filterData, this.props.filterData) || this.state.clearing) {
            this.setState({
                filterData: this.props.filterData,
                filterGrid: this.buildFilterGrid(),
                clearing: false,
            }, () => this.setUrl());
        }
    };

    render() {
        return (
            <div className="bg-light mb-2 pt-3 pb-3">
                <Accordion defaultActiveKey={this.props.open ? '0' : null}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <Accordion.Toggle as="h2" className="cursor-pointer" eventKey="0">
                                    {this.props.title}
                                    <ItemCount count={this.getActiveFilterCount()} />
                                </Accordion.Toggle>
                            </Col>
                        </Row>
                    </Container>
                    <Accordion.Collapse eventKey="0">
                        <Container fluid className="mt-2">
                            {this.state.filterGrid}

                            <Row>
                                <Col>
                                    <ButtonToolbar>
                                        <Button size="sm"
                                            variant="primary"
                                            className="mr-2"
                                            onClick={this.handleFilter}>
                                            Filter
                                        </Button>
                                        <Button size="sm"
                                            variant="secondary"
                                            onClick={this.handleClear}>
                                            Reset Filters
                                        </Button>
                                    </ButtonToolbar>
                                </Col>
                            </Row>
                        </Container>
                    </Accordion.Collapse>
                </Accordion>
            </div>
        );
    }

    getActiveFilterCount() {
        return Object.keys(this.state.filterData).filter((key) => {
            return !!this.state.filterData[key].value;
        }).length;
    }

    handleClear = (e) => {
        e.preventDefault();

        this.setState({
            clearing: true,
        });

        this.props.onClear();
    };

    handleFilter = (e) => {
        e.preventDefault();

        this.props.onFilter(
            FiltersService.convertFilterDataToRequestData(this.state.filterData),
            this.state.filterData
        );
    };

    handleFilterChange = (selected, filterKey) => {
        this.setState({
            filterData: Object.assign({}, this.state.filterData, {
                [filterKey]: selected,
            })
        });
    };

    buildFilterGrid = () => {
        const filterId = Filters.generateFilterId();
        const cols = 3;

        // split filters into chunks
        let filterGroups = [];
        React.Children.forEach(this.props.children, (child, i) => {
            if (i % cols === 0) {
                filterGroups.push([]);
            }

            filterGroups[filterGroups.length - 1].push(
                React.cloneElement(child, {
                    handleChange: this.handleFilterChange,
                    value: this.props.filterData[child.props.filterKey] ?
                           this.props.filterData[child.props.filterKey].data :
                           '',
                })
            );
        });

        // build grid
        let grid = [];
        filterGroups.forEach((filterGroup, rowIndex) => {
            let row = [];

            // pad out row to correct number of columns
            if (filterGroup.length !== cols) {
                filterGroup = [
                    ...filterGroup,
                    ...Array(cols - filterGroup.length).fill(null),
                ];
            }

            filterGroup.forEach((filter, filterIndex) => {
                row.push(<Col key={`${filterId}-${rowIndex}-${filterIndex}`}>{filter}</Col>);
            });

            grid.push(<Row className="mb-2" key={`${filterId}-${rowIndex}`}>{row}</Row>);
        });

        return grid;
    };

    setUrl(replace = false) {
        if (this.props.updateUrl === true) {
            let query = {};
            Object.entries(this.state.filterData).forEach((filter) => {
                query[filter[0]] = JSON.stringify(this.state.filterData[filter[0]]);
            });

            this.props.history[replace ? 'replace' : 'push']({
                search: '?' + queryString.stringify(query),
            });
        }
    }

    static generateFilterId() {
        return Math.ceil(Math.random() * 999999) + '-' + Math.ceil(Math.random() * 999999);
    }

    static formatSelectResponse(response, responseKey, idKey, labelKey) {
        return response[responseKey].map((item) => {
            return {
                id: item[idKey],
                label: item[labelKey],
            };
        });
    }
}

Filters.SingleSelect = class SingleSelect extends Component
{
    static propTypes = {
        action: PropTypes.func.isRequired,
        requestKey: PropTypes.string,
        filterKey: PropTypes.string.isRequired,
        responseKey: PropTypes.string.isRequired,
        labelKey: PropTypes.string,
        idKey: PropTypes.string,
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        placeholder: 'Type to search...',
        idKey: 'id',
        labelKey: 'name',
        requestKey: 'name',
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            typeaheadId: Filters.generateFilterId(),
            options: [],
        };
    }

    render() {
        let selected;
        if (this.props.value) {
            selected = [this.props.value];
        }

        return (
            <div className="w-100 mb-2">
                <strong>{this.props.label}</strong>
                <AsyncTypeahead className="mt-1"
                    id={this.state.typeaheadId}
                    options={this.state.options}
                    isLoading={this.state.isLoading}
                    onSearch={this.handleSearch}
                    onChange={this.handleChange}
                    placeholder={this.props.placeholder}
                    selectHintOnEnter={true}
                    defaultSelected={selected} />
            </div>
        )
    }

    handleChange = (selected) => {
        if (selected.length) {
            selected = {
                value: selected[0].id,
                data: selected[0],
            };
        } else {
            selected = {
                value: null,
                data: null,
            };
        }

        this.props.handleChange(selected, this.props.filterKey);
    };

    handleSearch = (query) => {
        this.setState({isLoading: true});

        this.props.action({
            [this.props.requestKey]: query,
        }).then((response) => {
            this.setState({
                isLoading: false,
                options: Filters.formatSelectResponse(
                    response,
                    this.props.responseKey,
                    this.props.idKey,
                    this.props.labelKey,
                ),
            });
        });
    };
};

Filters.Text = class Text extends Component
{
    static propTypes = {
        filterKey: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
    };

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value,
        };
    }

    render() {
        return (
            <div className="w-100 mb-2">
                <strong>{this.props.label}</strong>
                <input className="form-control mt-1"
                    placeholder={this.props.placeholder}
                    value={this.state.value}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur} />
            </div>
        );
    }

    handleChange = (e) => {
        this.setState({
            value: e.target.value,
        });
    };

    handleBlur = () => {
        this.props.handleChange({
            value: this.state.value,
            data: this.state.value,
        }, this.props.filterKey);
    };
};

Filters.DateRange = class DateRange extends Component
{
    static propTypes = {
        filterKey: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);
    }

    render() {
        let props = {
            onApply: this.handleApply
        };

        if (this.props.value.startDate && this.props.value.endDate) {
            props['startDate'] = DateTime.fromISO(this.props.value.startDate);
            props['endDate'] = DateTime.fromISO(this.props.value.endDate);
        }

        return (
            <label className="w-100">
                <strong className="d-block mb-1">{this.props.label}</strong>
                <DateRangePicker {...props} />
            </label>
        );
    }

    handleApply = (startDate, endDate) => {
        this.props.handleChange({
            value: `${startDate.toISODate()},${endDate.toISODate()}`,
            data: {
                startDate: startDate,
                endDate: endDate,
            }
        }, this.props.filterKey);
    };
}

Filters.SimpleSelect = class SimpleSelect extends Component
{
    static propTypes = {
        filterKey: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        options: PropTypes.array.isRequired,
        placeholder: PropTypes.string,
        multiple: PropTypes.bool,
    };

    static defaultProps = {
        placeholder: 'Click to select',
        multiple: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            typeaheadId: Filters.generateFilterId(),
            options: this.props.options,
        };
    }

    render() {
        let selected;
        if (this.props.value) {
            if (Array.isArray(this.props.value)) {
                selected = this.props.value;
            } else {
                selected = [this.props.value];
            }
        }

        return (
            <div className="w-100 mb-2">
                <strong>{this.props.label}</strong>
                <Typeahead className="mt-1"
                    id={this.state.typeaheadId}
                    options={this.state.options}
                    placeholder="Click to select"
                    onChange={this.handleChange}
                    defaultSelected={selected}
                    multiple={this.props.multiple} />
            </div>
        );
    }

    handleChange = (selected) => {
        if (selected.length) {
            this.props.handleChange({
                value: selected.map(i => i.id),
                data: selected,
            }, this.props.filterKey);
        }
    };
};

export default withRouter(Filters);
