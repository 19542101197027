import React, { Component } from 'react';
import { Button, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import User, { login } from 'services/User';
import { login } from 'actions/auth';
import './Login.scss';

const mapDispatchToProps = {
    login: login
};

class Login extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
        };
    }

    validateForm() {
        return (this.state.email &&
            this.state.password &&
            this.refs.email &&
            this.refs.email.validity.valid &&
            this.refs.password &&
            this.refs.password.validity.valid);
    }

    handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        const credentials = {
            email: this.state.email,
            password: this.state.password,
        };

        this.props.login(credentials);
    }

    render() {
        return (
            <div className="Login">
                <form ref="form"
                    onSubmit={e => this.handleSubmit(e)}>
                    <FormGroup controlId="email" bssize="large">
                        <FormLabel>Email Address</FormLabel>
                        <FormControl type="email"
                            ref="email"
                            value={this.state.email}
                            onChange={e => { this.setState({"email": e.target.value}) }}
                            autoFocus />
                    </FormGroup>

                    <FormGroup controlId="password" bssize="large">
                        <FormLabel>Password</FormLabel>
                        <FormControl type="password"
                            ref="password"
                            value={this.state.password}
                            autoComplete="current-password"
                            onChange={e => { this.setState({"password": e.target.value}) }} />
                    </FormGroup>

                    <Button type="submit"
                        block
                        bssize="large"
                        disabled={!this.validateForm()}>
                        <FontAwesomeIcon icon={['fas', 'sign-in-alt']} />
                        Login
                    </Button>

                    <Link to="/forgotten-password">Forgotten Password</Link>
                </form>

            </div>
        );
    }
}

export default connect(null, mapDispatchToProps)(Login);
