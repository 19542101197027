import HTTP from 'services/HTTP';
import { toast } from 'react-toastify';

export {
    fetchNotes,
    create,
    batchCreate,
};

function fetchNotes(link, params = {})
{
    return HTTP
        .get(link, params)
        .then((response) => {
            return response.data;
        }).catch(() => {
            return false;
        });
}

function create(endpoint, data = {})
{
    return HTTP
        .post(endpoint, data)
        .then((response) => {
            toast.success('Note created');

            return response.data;
        }).catch((error) => {
            error.response.data.forEach((error) => {
                toast.error(`${error.property_path} - ${error.message}`);
            });

            return false;
        });
}

function batchCreate(clientId, data)
{
    return HTTP
        .post(`/clients/${clientId}/elogbooks/jobs/notes/new`, data)
        .then((response) => {
            toast.success('Notes created');

            return true;
        }).catch((error) => {
            error.response.data.forEach((error) => {
                toast.error(`${error.property_path} - ${error.message}`);
            });

            return false;
        });
}
