import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Sidebar from 'components/layout/Sidebar';
import Header from 'components/layout/Header';
import Main from 'components/layout/Main';
import Footer from 'components/layout/Footer';
import './App.css';

const mapStateToProps = (state) => {
    return {
        isSidebarExpanded: state.sidebar.isSidebarExpanded,
    };
};

class App extends Component
{
    render() {
        return (
            <div className={"App" + (this.props.isSidebarExpanded ? ' App-sidebar-expanded' : '')}>
                <Header />
                <Sidebar />
                <Main />
                <Footer />
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, null)(App));
