import React, { Component } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PropTypes from 'prop-types';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export default class Paginator extends Component
{
    static propTypes = {
        page: PropTypes.number.isRequired,
        count: PropTypes.number.isRequired,
        limit: PropTypes.number.isRequired,

        show: PropTypes.number,

        showFirst: PropTypes.bool,
        showPrevious: PropTypes.bool,
        showNext: PropTypes.bool,
        showLast: PropTypes.bool,

        showEllipsis: PropTypes.bool,

        onPage: PropTypes.func.isRequired,
    };

    static defaultProps = {
        page: 1,
        count: 0,
        limit: 10,

        show: 5,

        showFirst: true,
        showPrevious: true,
        showNext: true,
        showLast: true,

        showEllipsis: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            numberOfPages: Math.ceil(this.props.count / this.props.limit),
            pages: [],
        };

        this.changePage = this.changePage.bind(this);
    }

    getPages() {
        return this.state.pages.map((page, i) => {
            return this.getTooltip(<Pagination.Item key={i}
                onClick={() => this.changePage(page)}
                active={this.props.page === page}>{page}</Pagination.Item>, 'Page ' + page);
        });
    }

    getTooltip(button, text) {
        return (
            <OverlayTrigger trigger="hover" placement="top" overlay={
                <Tooltip>
                    {text}
                </Tooltip>
            } key={text}>
                {button}
            </OverlayTrigger>
        );
    }

    render() {
        const firstButton = this.getTooltip(<Pagination.First onClick={() => this.changePage(1)} />, 'First Page');
        const previousButton = this.getTooltip(<Pagination.Prev onClick={() => this.changePage(this.props.page - 1)} />, 'Previous Page');
        const nextButton = this.getTooltip(<Pagination.Next onClick={() => this.changePage(this.props.page + 1)} />, 'Next Page');
        const lastButton = this.getTooltip(<Pagination.Last onClick={() => this.changePage(this.state.numberOfPages)} />, 'Last Page');

        return (
            <div className="Paginator">
                <Pagination>
                    {this.props.showFirst ? firstButton : null}
                    {this.props.showPrevious ? previousButton : null}

                    {this.getPages()}

                    {this.props.showNext ? nextButton : null}
                    {this.props.showLast ? lastButton : null}
                </Pagination>
            </div>
        );
    }

    changePage(page) {
        if (page <= 0 ||
            page > this.state.numberOfPages ||
            page === this.props.page) {
            return;
        }

        this.props.onPage(page);
    }

    componentDidMount() {
        const numberOfPages = Math.ceil(this.props.count / this.props.limit);
        const pagesBeforeAfter = Math.floor((this.props.show - 1) / 2);
        let startPage = this.props.page > (pagesBeforeAfter + 1) ? pagesBeforeAfter : 1;
        let finalPage = ((this.props.page + pagesBeforeAfter) < numberOfPages) ? (this.props.page + pagesBeforeAfter) : numberOfPages;

        let startPageDifference = ((startPage + pagesBeforeAfter) - this.props.page);

        if (startPageDifference > 0) {
            finalPage += startPageDifference;
        }

        if (finalPage > numberOfPages) {
            finalPage = numberOfPages;
        }

        if (startPage < (this.props.page - pagesBeforeAfter)) {
            startPage = (this.props.page - pagesBeforeAfter);
        }

        this.setState({
            pages: [...Array((finalPage - startPage) + 1).keys()].map((i) => i + startPage),
        });
    }
}
