import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAllClients, fetchCountJobErrors, fetchCountJobs } from 'actions/dashboard';
import { fetchCompletedPendingValueApprovalJobsByClient, fetchCompletedPendingValueJobsByClient, fetchCompletedRejectedValueJobsByClient, fetchOpenJobsByClient } from 'actions/elogbooks/job';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Loading from 'components/higher-order-components/Loading';
import Reload from 'components/elements/Reload';
import NumberFormatting from 'services/NumberFormatting';

const mapStateToProps = (state) => {
    return {
        clients: {
            collection: state.dashboard.clients.collection,
            filters: state.dashboard.clients.filters,
            isLoading: state.dashboard.clients.isRequesting,
        },
        elogbooksJob: state.elogbooksJob,
        errors: {
            collection: state.dashboard.errors.collection,
            filters: state.dashboard.errors.filters,
            isLoading: state.dashboard.errors.isRequesting,
        },
        jobs: {
            collection: state.dashboard.jobs.collection,
            filters: state.dashboard.jobs.filters,
            isLoading: state.dashboard.jobs.isRequesting,
        },
    };
};

const mapDispatchToProps = {
    fetchAllClients: fetchAllClients,
    fetchCompletedPendingValueApprovalJobsByClient: fetchCompletedPendingValueApprovalJobsByClient,
    fetchCompletedPendingValueJobsByClient: fetchCompletedPendingValueJobsByClient,
    fetchCompletedRejectedValueJobsByClient: fetchCompletedRejectedValueJobsByClient,
    fetchCountJobErrors: fetchCountJobErrors,
    fetchCountJobs: fetchCountJobs,
    fetchOpenJobsByClient: fetchOpenJobsByClient,
};

class Dashboard extends Component
{
    static propTypes = {
        clients: PropTypes.shape({
            collection: PropTypes.shape({
                count: PropTypes.number.isRequired,
                clients: PropTypes.array,
                limit: PropTypes.number.isRequired,
                page: PropTypes.number.isRequired,
                pages: PropTypes.number.isRequired,
            }).isRequired,
            filters: PropTypes.object.isRequired,
            isLoading: PropTypes.bool.isRequired,
        }),
        errors: PropTypes.shape({
            collection: PropTypes.shape({
                count: PropTypes.number.isRequired,
                errors: PropTypes.array,
                limit: PropTypes.number.isRequired,
                page: PropTypes.number.isRequired,
                pages: PropTypes.number.isRequired,
            }).isRequired,
            filters: PropTypes.object.isRequired,
            isLoading: PropTypes.bool.isRequired,
        }),
        jobs: PropTypes.shape({
            collection: PropTypes.shape({
                count: PropTypes.number.isRequired,
                jobs: PropTypes.array,
                limit: PropTypes.number.isRequired,
                page: PropTypes.number.isRequired,
                pages: PropTypes.number.isRequired,
            }).isRequired,
            filters: PropTypes.object.isRequired,
            isLoading: PropTypes.bool.isRequired,
        }),
    };

    static defaultProps = {
        clients: {
            collection: {
                count: 0,
                clients: undefined,
                limit: 10,
                page: 1,
                pages: 0,
            },
            filters: {},
            isLoading: false,
        },
        errors: {
            collection: {
                count: 0,
                errors: undefined,
                limit: 10,
                page: 1,
                pages: 0,
            },
            filters: {},
            isLoading: false,
        },
        jobs: {
            collection: {
                count: 0,
                errors: undefined,
                limit: 10,
                page: 1,
                pages: 0,
            },
            filters: {},
            isLoading: false,
        },
    };

    constructor(props) {
        super(props);

        this.load = this.load.bind(this);
        this.reload = this.reload.bind(this);
        this.loadClientData = this.loadClientData.bind(this);
        this.getJobErrorsWidget = this.getJobErrorsWidget.bind(this);
        this.getJobPendingCisWidget = this.getJobPendingCisWidget.bind(this);
        this.getClientStatisticsWidgets = this.getClientStatisticsWidgets.bind(this);
    }

    render() {
        return (
            <div className="Dashboard">
                <h1 className="clearfix">
                    Dashboard

                    <Reload load={this.reload} />
                </h1>

                <div className="Widgets">
                    { this.getJobErrorsWidget() }
                    { this.getJobPendingCisWidget() }
                    { this.getClientStatisticsWidgets() }
                </div>
            </div>
        );
    }

    getJobErrorsWidget() {
        const errors = this.props.errors || {collection: {}};

        return (
            <div className="Widget">
                <h2>Job Errors</h2>

                <Loading isLoading={errors.isLoading} size="md">
                    <Link className={errors.collection.count > 0 ? 'NegativeText' : 'PositiveText'}
                        to="/job-errors">
                        {NumberFormatting.format(errors.collection.count)}
                    </Link>
                </Loading>
           </div>
        );
    }

    getJobPendingCisWidget() {
        const jobs = this.props.jobs || {collection: {}};

        return (
            <div className="Widget">
                <h2>Jobs Pending CIS</h2>

                <Loading isLoading={jobs.isLoading} size="md">
                    <Link className={jobs.collection.count > 0 ? 'NegativeText' : 'PositiveText'}
                        to="/jobs">
                        {NumberFormatting.format(jobs.collection.count)}
                    </Link>
                </Loading>
           </div>
        );
    }

    getClientStatisticsWidgets() {
        const clients = this.props.clients || {collection: {}};

        return (clients.collection.clients || []).map((client) => {
            const elogbooksJob = this.props.elogbooksJob || {data: {}};
            const clientData = elogbooksJob.data[client.id] || {
                open: {count: 0},
                pendingvalue: {count: 0},
            };

            const open = clientData.open || {};
            const pendingvalue = clientData.pendingvalue || {};
            const rejected = clientData.rejected || {};
            const pendingapproval = clientData.pendingapproval || {};

            return (
                <div className="Widget" key={client.id}>
                    <h2>{ client.name }</h2>

                    <Loading isLoading={open.isRequesting} size="md" inline={true}>
                        <OverlayTrigger trigger="hover" placement="top" overlay={
                            <Tooltip>
                                Open Jobs
                            </Tooltip>
                        } key="open">
                            <span>
                                <Link to={`/pending-jobs/${client.id}/open`}>
                                    { NumberFormatting.format(open.count, 0) }
                                </Link>
                            </span>
                        </OverlayTrigger>
                    </Loading>
                    <Loading isLoading={pendingvalue.isRequesting} size="md" inline={true}>
                        <OverlayTrigger trigger="hover" placement="top" overlay={
                            <Tooltip>
                                Completed Jobs, Pending Value
                            </Tooltip>
                        } key="pendingvalue">
                            <span>
                                <Link to={`/pending-jobs/${client.id}/pending-value`}>
                                    { NumberFormatting.format(pendingvalue.count, 0) }
                                </Link>
                            </span>
                        </OverlayTrigger>
                    </Loading>
                    <Loading isLoading={rejected.isRequesting} size="md" inline={true}>
                        <OverlayTrigger trigger="hover" placement="top" overlay={
                            <Tooltip>
                                Completed Jobs, Rejected Value
                            </Tooltip>
                        } key="rejected">
                            <span>
                                <Link to={`/pending-jobs/${client.id}/rejected-value`}>
                                    { NumberFormatting.format(rejected.count, 0) }
                                </Link>
                            </span>
                        </OverlayTrigger>
                    </Loading>
                    <Loading isLoading={pendingapproval.isRequesting} size="md" inline={true}>
                        <OverlayTrigger trigger="hover" placement="top" overlay={
                            <Tooltip>
                            Completed Jobs, Pending Value Approval
                            </Tooltip>
                        } key="pendingapproval">
                            <span>
                                <Link to={`/pending-jobs/${client.id}/pending-approval`}>
                                    { NumberFormatting.format(pendingapproval.count, 0) }
                                </Link>
                            </span>
                        </OverlayTrigger>
                    </Loading>
                </div>
            );
        });
    }

    componentDidMount() {
        this.load();
        this.props.fetchAllClients({isActive: ['true']}).then(() => {
            this.loadClientData();
        });
        this.refreshInterval = setInterval(() => this.load(), 60000);
        this.refreshIntervalClientData = setInterval(() => this.loadClientData(), 300000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
        clearInterval(this.refreshIntervalClientData);
    }

    reload() {
        this.load();
        this.loadClientData();
    }

    load() {
        this.props.fetchCountJobErrors(this.props.errors.filters);
        this.props.fetchCountJobs(this.props.jobs.filters);
    }

    loadClientData() {
        const clients = this.props.clients || {collection: []};

        (clients.collection.clients || []).map((client) => {
            this.props.fetchOpenJobsByClient(client.id, {limit: 0});
            this.props.fetchCompletedPendingValueApprovalJobsByClient(client.id, {limit: 0});
            this.props.fetchCompletedPendingValueJobsByClient(client.id, {limit: 0});
            this.props.fetchCompletedRejectedValueJobsByClient(client.id, {limit: 0});
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
