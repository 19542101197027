import {
    CREATED_PROPERTY,
    CREATING_PROPERTY,
    CREATING_PROPERTY_FAILED,
    FETCHED_PROPERTIES,
    FETCHED_PROPERTY,
    FETCHING_PROPERTIES,
    FETCHING_PROPERTY,
    FETCHING_PROPERTY_FAILED,
    UPDATED_PROPERTY,
    UPDATING_PROPERTY,
    UPDATING_PROPERTY_FAILED,
    SET_PROPERTY_PROCESS_SALES_INVOICES,
    UPDATING_PROPERTY_FILTERS,
    CLEARING_PROPERTY_FILTERS,
} from 'actions/property';
import { LOGOUT } from 'actions/auth';
import { active } from 'constants/active';

const defaultState = {
    collection: {
        count: 0,
        limit: 10,
        page: 1,
        pages: 0,
        properties: [],
    },
    data: undefined,
    filters: {
        order: 'name',
        isActive: [active.id],
    },
    filterData: {
        isActive: {
            value: [active.id],
            data: [active],
        },
    },
    isCreating: false,
    isRequesting: false,
    isUpdating: false,
    properties: [],
};

export default function property(state = defaultState, action)
{
    const baseState = Object.assign({}, defaultState, state);

    switch (action.type) {
        case UPDATING_PROPERTY_FILTERS:
            return Object.assign(baseState, {
                filterData: action.filterData,
                filters: action.filters,
            });

        case CLEARING_PROPERTY_FILTERS:
            return Object.assign(baseState, {
                filterData: defaultState.filterData,
                filters: defaultState.filters,
            });

        case CREATED_PROPERTY:
            return Object.assign(baseState, {
                isCreating: false,
                data: undefined,
                response: action.response,
            });

        case CREATING_PROPERTY:
            return Object.assign(baseState, {
                isCreating: true,
                data: action.data,
            });

        case CREATING_PROPERTY_FAILED:
            return Object.assign(baseState, {
                isCreating: false,
                data: undefined,
            });

        case FETCHED_PROPERTIES:
            return Object.assign(baseState, {
                isRequesting: false,
                collection: action.collection,
            });

        case FETCHED_PROPERTY:
            return Object.assign(baseState, {
                isFetching: false,
                properties: {
                    [action.response.id]: action.response,
                },
            });

        case FETCHING_PROPERTIES:
            return Object.assign(baseState, {
                isRequesting: true,
            });

        case FETCHING_PROPERTY:
            return Object.assign(baseState, {
                isFetching: true,
            });

        case FETCHING_PROPERTY_FAILED:
            return Object.assign(baseState, {
                isFetching: false,
            });

        case UPDATED_PROPERTY:
            return Object.assign(baseState, {
                isUpdating: false,
                data: undefined,
                properties: {
                    [action.id]: Object.assign(baseState.properties[action.id], action.data),
                }
            });

        case UPDATING_PROPERTY:
            return Object.assign(baseState, {
                isUpdating: true,
                data: action.data,
            });

        case UPDATING_PROPERTY_FAILED:
            return Object.assign(baseState, {
                isUpdating: false,
                data: undefined,
            });

        case SET_PROPERTY_PROCESS_SALES_INVOICES:
            return Object.assign(baseState, {
                properties: {
                    [action.id]: Object.assign(baseState.properties[action.id], {processSalesInvoices: true}),
                },
            });

        case LOGOUT:
            return Object.assign({}, defaultState);

        default:
            return Object.assign(baseState);
    }
}
