import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import JobsList from 'components/jobs/JobsList';
import JobDetails from 'components/jobs/JobDetails';
import JobEdit from 'components/jobs/JobEdit';
import JobFetch from 'components/jobs/JobFetch';

class Jobs extends Component
{
    render() {
        const { match } = this.props;

        return (
            <div className="Jobs">
                <Switch>
                    <Route path={`${match.path}`} component={JobsList} exact />
                    <Route path={`${match.path}/fetch`} component={JobFetch} />
                    <Route path={`${match.path}/:id/edit`} component={JobEdit} />
                    <Route path={`${match.path}/:id`} component={JobDetails} />
                </Switch>
            </div>
        );
    }
}

export default withRouter(Jobs);
