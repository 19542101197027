import { Component } from 'react';
import PropTypes from 'prop-types';

export default class NoResultsFound extends Component
{
    static propTypes = {
        count: PropTypes.number.isRequired,
        label: PropTypes.string,
    };

    static defaultProps = {
        count: 0,
        label: 'No Results Found',
    };

    render() {
        const { children, count } = this.props;

        return (
            count === 0 ?
            this.props.label :
            children
        );
    }
}
