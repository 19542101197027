import qs from 'querystring';

class Filters
{
    static convertFilterDataToRequestData(filterData) {
        let filters = {
            modifiers: {},
        };

        Object.entries(filterData).forEach((filter) => {
            if (filter[1].value) {
                filters[filter[0]] = filter[1].value;

                // apply isNull modifier
                if (filter[1].value === '~') {
                    filters.modifiers[filter[0]] = {
                        type: 'isNull',
                    };
                }
            }
        });

        return filters;
    }

    static updateFilterStateFromUrl(queryString, filterData, updateAction) {
        const queryParams = qs.parse(queryString);
        let filters = {};

        if (Object.entries(queryParams).length > 0) {
            Object.entries(queryParams).forEach((filter) => {
                filters[filter[0]] = JSON.parse(filter[1]);
            });

            // update state with action method passed
            updateAction(Filters.convertFilterDataToRequestData(filters), filters);

            // return filter data built from URL
            return filters;
        }

        // return passed filter data
        return filterData;
    }
}

export default Filters;