const inactive = {
    id: 0,
    label: 'No',
};

const active = {
    id: 1,
    label: 'Yes',
};

const activeOptions = [
    inactive,
    active,
];

export {
    inactive,
    active,
    activeOptions,
}