import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, ButtonToolbar } from 'react-bootstrap';
import { fetchJob, deleteJob, hold, cancelHold } from 'actions/job';
import Loading from 'components/higher-order-components/Loading';
import Reload from 'components/elements/Reload';
import JWT from 'services/JWT';
import Response from 'services/Response';
import NumberFormatting from 'services/NumberFormatting';
import Elogbooks from 'services/Elogbooks';
import ConfirmationDialog from 'components/elements/ConfirmationDialog';
import lodash from 'lodash';
import HoldBadge from 'components/elements/HoldBadge';
import NoteFeed from 'components/notes/NoteFeed';

const mapStateToProps = (state, ownProps) => {
    return {
        isFetching: state.job.isFetching,
        jobResponse: state.job.jobs[ownProps.match.params.id],
    };
};

const mapDispatchToProps = {
    fetchJob: fetchJob,
    deleteJob: deleteJob,
    hold: hold,
    cancelHold: cancelHold,
};

class JobDetails extends Component
{
    constructor(props) {
        super(props);

        this.load = this.load.bind(this);

        this.state = {
            showDeleteConfirmation: false,
            showHoldConfirmation: false,
        };
    }

    render() {
        const { match, history } = this.props;
        const job = this.props.jobResponse || {};

        const heldByUser = lodash.get(job, '_links.held-by-user', null);

        return (
            <section className="JobDetails container-fluid">
                <div className="row">
                    <div className="col col-7">
                        <div className="panel">
                            <Loading isLoading={this.props.isFetching} size="md">
                                <h1 className="clearfix">
                                    {job.summary} {!heldByUser ? null : (
                                        <HoldBadge prefix="Held by" user={heldByUser} />
                                    )}

                                    <Reload load={this.load} />

                                    { JWT.hasRole('ROLE_ADMINISTRATOR') === false ? null :
                                        <ButtonToolbar className="float-right">
                                            {
                                                Response.getLink(job, 'update') === null ?
                                                    null :
                                                    <Button size="sm"
                                                            onClick={() => history.push(`${match.url}/edit`)}>
                                                        Edit
                                                    </Button>
                                            }
                                            <Button className="ml-2"
                                                size="sm"
                                                variant="danger"
                                                onClick={() => this.setState({showDeleteConfirmation: true})}>
                                                Delete
                                            </Button>
                                            {
                                                Response.getLink(job, 'cancel-hold') === null ?
                                                    null :
                                                    <Button onClick={() => this.setState({showHoldConfirmation: true})}
                                                        size="sm"
                                                        className="ml-2">
                                                        Cancel Hold
                                                    </Button>
                                            }
                                            {
                                                Response.getLink(job, 'hold') === null ?
                                                    null :
                                                    <Button onClick={() => this.setState({showHoldConfirmation: true})}
                                                        size="sm"
                                                        className="ml-2">
                                                        Hold
                                                    </Button>
                                            }
                                        </ButtonToolbar>
                                    }

                                    <ConfirmationDialog show={this.state.showDeleteConfirmation}
                                        hide={() => this.setState({showDeleteConfirmation: false})}
                                        onConfirm={() => this.handleDelete()} />

                                    <ConfirmationDialog show={this.state.showHoldConfirmation}
                                        hide={() => this.setState({showHoldConfirmation: false})}
                                        onConfirm={this.handleHold} />
                                </h1>

                                {
                                    job.wasAutoApproved !== true ?
                                        null :
                                        (
                                            <Alert variant="warning">
                                                This Job had its values auto approved
                                            </Alert>
                                        )
                                }

                                <dl>
                                    <dt>Client</dt>
                                    <dd>{Response.getLinkAttribute(job, 'client', 'title')}</dd>
                                    <dt>Property</dt>
                                    <dd>{Response.getLinkAttribute(job, 'property', 'title')}</dd>
                                    <dt>Service Provider</dt>
                                    <dd>{Response.getLinkAttribute(job, 'serviceprovider', 'title')}</dd>
                                    <dt>Elogbooks Job ID</dt>
                                    <dd>
                                        {job.elogbooksJobId}
                                        &nbsp;
                                        <a href={Elogbooks.createFrontendLink(
                                                Response.getLinkAttribute(job, 'client', 'installationUrl'),
                                                '/user/jobs/{job}/status',
                                                {
                                                    job: job.elogbooksJobLink,
                                                }
                                            )}
                                            className="small"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            View Job
                                        </a>
                                    </dd>
                                    <dt>Summary</dt>
                                    <dd>{job.summary}</dd>
                                    <dt className={job.description ? '' : 'hidden'}>Description</dt>
                                    <dd className={job.description ? '' : 'hidden'}>{job.description}</dd>
                                    <dt>Labour Value</dt>
                                    <dd>
                                        {NumberFormatting.formatCurrency(job.labourValueServiceProvider)}
                                        &nbsp;
                                        (
                                            { (job.labourValueMarkup >= 0 ? '+' : '') + NumberFormatting.format(job.labourValueMarkup, 2) }% &nbsp;
                                            = &nbsp;
                                            { NumberFormatting.formatCurrency(job.labourValueApproved)}
                                        )
                                    </dd>
                                    <dt>Material Value</dt>
                                    <dd>
                                        {NumberFormatting.formatCurrency(job.materialValueServiceProvider)}
                                        &nbsp;
                                        (
                                            { (job.materialValueMarkup >= 0 ? '+' : '') + NumberFormatting.format(job.materialValueMarkup, 2) }% &nbsp;
                                            = &nbsp;
                                            { NumberFormatting.formatCurrency(job.materialValueApproved)}
                                        )
                                    </dd>
                                    <dt>Invoice Description</dt>
                                    <dd>{job.approvalNote}</dd>
                                    <dt>Individual Invoice?</dt>
                                    <dd>{(job.isIndividualInvoice ? 'Yes' : 'No')}</dd>
                                    <dt>CIS?</dt>
                                    <dd>{[true, false].indexOf(job.isCis) !== -1 ? (job.isCis ? 'Yes' : 'No') : 'Unset'}</dd>
                                    <dt>CIS? (Original CIS by SP in Elogbooks)</dt>
                                    <dd>{[true, false].indexOf(job.isCisOriginal) !== -1 ? (job.isCisOriginal ? 'Yes' : 'No') : 'Unset'}</dd>
                                    {
                                        typeof job.alternateJobCode === 'undefined' ?
                                            null : (
                                                <>
                                                    <dt>Alternate Job Code</dt>
                                                    <dd>{ job.alternateJobCode.code }</dd>
                                                    <dt>Alternate Job Code Reason</dt>
                                                    <dd>{ job.alternateJobCodeReason }</dd>
                                                </>
                                            )
                                    }
                                </dl>
                            </Loading>
                        </div>
                    </div>

                    <div className="col col-5">
                        <div className="panel">
                            <Loading isLoading={this.props.isFetching} size="md">
                                <NoteFeed resource={Response.getLink(job, 'notes') || ''}
                                    createResource={Response.getLink(job, 'create-note') || ''} />
                            </Loading>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    handleDelete() {
        this.setState({
            showDeleteConfirmation: false,
        });

        this.props
            .deleteJob(this.props.jobResponse.id)
            .then((success) => {
                if (success) {
                    this.props.history.push('/jobs/');
                }
            });
    }

    handleHold = () => {
        let action;
        if (Response.getLink(this.props.jobResponse, 'hold')) {
            action = 'hold';
        } else {
            action = 'cancelHold';
        }

        this.setState({showHoldConfirmation: false});
        this.props[action](this.props.jobResponse.id);
    };

    componentDidMount() {
        this.load();
    }

    load() {
        const { id } = this.props.match.params;

        this.props.fetchJob(id);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobDetails);
