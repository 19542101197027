import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import elementResizeDetectorMaker from 'element-resize-detector';
import invariant from 'invariant';
import debounce from 'debounce';

class ContainerProperties extends Component
{
    static propTypes = {
        debounce: PropTypes.number,
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    };

    static defaultProps = {
        debounce: 150,
    };

    static getProperties(node) {
        const computedStyles = window.getComputedStyle(node);
        const { top, right, bottom, left } = node.getBoundingClientRect();
        const { paddingLeft, paddingRight, paddingTop, paddingBottom, width, height } = {
            paddingLeft: computedStyles.getPropertyValue('padding-left'),
            paddingRight: computedStyles.getPropertyValue('padding-right'),
            paddingTop: computedStyles.getPropertyValue('padding-top'),
            paddingBottom: computedStyles.getPropertyValue('padding-bottom'),

            width: computedStyles.getPropertyValue('width'),
            height: computedStyles.getPropertyValue('height'),
        };
        const properties = {
            top: top,
            right: right,
            bottom: bottom,
            left: left,
            width: width,
            height: height,
            paddingLeft: paddingLeft,
            paddingRight: paddingRight,
            paddingTop: paddingTop,
            paddingBottom: paddingBottom,

            usableWidth: (parseInt(width, 10) - parseInt(paddingLeft, 10) - parseInt(paddingRight, 10)),
            usableHeight: (parseInt(height, 10) - parseInt(paddingTop, 10) - parseInt(paddingBottom, 10)),
        }

        return properties;
    }

    constructor(props) {
        super(props);

        this.state = {
            initialised: false,
        };
        this.onResize = this.onResize.bind(this);
    }

    componentDidMount() {
        this.parentNode = ReactDOM.findDOMNode(this).parentNode;
        this.elementResizeDetector = elementResizeDetectorMaker({
            strategy: 'scroll',
            callOnAdd: false,
        });
        this.elementResizeDetector.listenTo(this.parentNode, debounce(this.onResize, this.props.debounce));
        this.componentIsMounted = true;
        this.onResize();
    }

    componentWillUnmount() {
        this.componentIsMounted = false;
        this.elementResizeDetector.uninstall(this.parentNode);
    }

    onResize() {
        const properties = ContainerProperties.getProperties(this.parentNode);
        if (this.componentIsMounted) {
            this.setState({
                initialised: true,
                ...properties
            });
        }
    }

    render() {
        invariant(this.props.children, 'Expected children to be one of function or React.Element');

        if (!this.state.initialised) {
            return <div />;
        }

        if (typeof this.props.children === 'function') {
            const renderedChildren = this.props.children(this.state);
            return renderedChildren && Children.only(renderedChildren);
        }

        return Children.only(React.cloneElement(this.props.children, this.state));
    }
}

export default ContainerProperties;
