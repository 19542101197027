import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, FormControl, FormCheck, FormLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { create, update, fetchUser } from 'actions/user';
import ColourPicker from 'components/elements/ColourPicker';

const mapStateToProps = (state, ownProps) => {
    return {
        userResponse: state.user.users[ownProps.match.params.id],
    };
};

const mapDispatchToProps = {
    create: create,
    update: update,
    fetchUser: fetchUser,
};

class UserAddEdit extends Component
{
    constructor(props) {
        super(props);

        const user = this.props.userResponse || {};

        this.state = {
            forename: user.forename || "",
            surname: user.surname || "",
            email: user.email || "",
            isPrimaryEmail: user.isPrimaryEmail || false,
            role: user.roles || ["ROLE_USER"],
            isActive: [true, false].indexOf(user.isActive) !== -1 ? (user.isActive ? 'true' : 'false') : 'false',
            highlightColour: user.highlightColour,
        };
    }

    validateForm() {
        let valid = (
            this.state.forename &&
            this.state.surname &&
            this.state.email &&
            this.state.role &&
            ["true", "false"].indexOf(this.state.isActive) !== -1 &&

            this.refs.forename &&
            this.refs.surname &&
            this.refs.email &&

            this.refs.forename.validity.valid &&
            this.refs.surname.validity.valid &&
            this.refs.email.validity.valid
        );

        return valid;
    }

    handleSubmit(event) {
        event.stopPropagation();
        event.preventDefault();

        const data = {
            forename: this.state.forename,
            surname: this.state.surname,
            email: this.state.email,
            isPrimaryEmail: this.state.isPrimaryEmail,
            roles: this.state.role,
            isActive: this.state.isActive,
            highlightColour: this.state.highlightColour,
        };

        if (this.props.userResponse &&
            this.props.userResponse.id) {
            return this.props
                .update(this.props.userResponse.id, data)
                .then((response) => {
                    this.props.history.push('/users/' + this.props.userResponse.id);
                }, (response) => {
                    // @todo show an error
                });
        }

        return this.props
            .create(data)
            .then((response) => {
                this.props.history.push('/users');
            }, (response) => {
                // @todo show an error
            });
    }

    render() {
        const { match, history } = this.props;
        const { id } = this.props.match.params;
        const cancelState = match.url.substring(0, match.url.length - (match.params.id ? 5 : 4));

        return (
            <div className="UserAddEdit">
                <h1 className="clearfix">
                    { id ? 'Edit User' :  'Add a New User' }
                </h1>

                <form ref="form"
                    className="clearfix"
                    onSubmit={e => this.handleSubmit(e)}>
                    <FormGroup controlId="forename" bssize="large">
                        <FormLabel>Forename</FormLabel>
                        <FormControl as="input"
                            ref="forename"
                            value={this.state.forename}
                            onChange={e => { this.setState({"forename": e.target.value}) }}
                            autoFocus />
                    </FormGroup>

                    <FormGroup controlId="surname" bssize="large">
                        <FormLabel>Surname</FormLabel>
                        <FormControl as="input"
                            ref="surname"
                            value={this.state.surname}
                            onChange={e => { this.setState({"surname": e.target.value}) }} />
                    </FormGroup>
                    <FormGroup controlId="email" bssize="large">
                        <FormLabel>Email Address</FormLabel>
                        <FormControl as="input"
                            type="email"
                            ref="email"
                            value={this.state.email}
                            onChange={e => { this.setState({"email": e.target.value}) }} />
                    </FormGroup>

                    <FormGroup controlId="isPrimaryEmail" bssize="large">
                        <FormLabel>Is Primary Email</FormLabel>
                        <FormCheck
                            ref="isPrimaryEmail"
                            defaultChecked={this.state.isPrimaryEmail}
                            value={this.state.isPrimaryEmail}
                            onChange={e => { this.setState({"isPrimaryEmail": e.target.checked}) }} />
                    </FormGroup>

                    <FormGroup controlId="roles" bssize="large">
                        <FormLabel>Role</FormLabel>
                        <FormControl as="select"
                            ref="roles"
                            value={this.state.role}
                            onChange={e => {
                                let selectedOptions = Array.from(e.target.selectedOptions);
                                let roles = selectedOptions.map((item) => item.value);

                                this.setState({"role": roles})
                            }}
                            multiple>
                            <option value="ROLE_USER">User</option>
                            <option value="ROLE_ADMINISTRATOR">Administrator</option>
                        </FormControl>
                    </FormGroup>
                    <FormGroup controlId="isActive" bssize="large">
                        <FormLabel>Status</FormLabel>
                        <FormControl as="select"
                            ref="isActive"
                            value={this.state.isActive}
                            onChange={e => { this.setState({"isActive": e.target.value}) }}>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                        </FormControl>
                    </FormGroup>

                    <FormGroup controlId="highlightColour">
                        <FormLabel>Highlight Colour</FormLabel>
                        <ColourPicker onChange={colour => { this.setState({"highlightColour": colour.hex}) }}
                            selected={this.state.highlightColour} />
                    </FormGroup>

                    <div className="float-right">
                        <Button onClick={() => history.push(cancelState)}
                            variant="default"
                            bssize="large"
                            to={cancelState}>
                            <FontAwesomeIcon icon={['fas', 'ban']} />
                            Cancel
                        </Button>

                        <Button type="submit"
                            className="margin-left"
                            bssize="large"
                            disabled={!this.validateForm()}>
                            <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                            { id ? 'Update' : 'Create' }
                        </Button>
                    </div>
                </form>
            </div>
        );
    }

    componentDidMount()  {
        const { id } = this.props.match.params;

        if (typeof id === 'string' &&
            typeof this.props.userResponse === 'undefined') {
            this.load(id);
        }
    }

    load(id) {
        this.props.fetchUser(id);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAddEdit);
